import React, { ReactElement, useEffect, useState } from "react";
import SelectForm from "../shared/forms/SelectForm";
import axios, { AxiosResponse } from "axios";
import LoadingImage from "../shared/images/SpinGray.svg";
import { CityResponse } from "../../models/City";
import { getRequest } from "../../utils/ApiClient";

interface Props {
  model: any;
  scope: string;
  prefectureCode: number;
  required?: boolean;
}

export default function CitySelectForm(props: Props): ReactElement {
  const { model, scope, prefectureCode, required = false } = props;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Array<{ key: string; value: string }>>(
    []
  );

  useEffect(() => {
    loadOptions();
  }, [prefectureCode]);

  const loadOptions = async () => {
    setLoading(true);
    try {
      const res = await getRequest<CityResponse[]>("/cities", {
        q: { prefecture_code_eq: prefectureCode },
      });
      const cities = res.data;
      setOptions(cities.map((c) => ({ key: String(c.id), value: c.name })));
      setLoading(false);
    } catch (e) {
      const error = e.response;
      console.log(error);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="relative h-10 w-full">
        <object
          type="image/svg+xml"
          data={LoadingImage}
          width="20"
          height="20"
          title="loading"
          style={{
            position: "absolute",
            top: "calc(50%)",
            transform: "translate(-50%)",
            left: "50%",
          }}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="text-sm -mb-2 mt-1 ml-2">※市区町村の選択は任意です</div>
      <SelectForm
        model={model}
        scope={scope}
        columnName="city_id"
        option={{ include_blank: "-- 市区町村を選択してください --" }}
        options={options}
        hideLabel
        required={required}
      />
    </div>
  );
}
