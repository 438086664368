import React, { ReactElement, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "qrcode.react";
import ja from "date-fns/locale/ja";
import BaseModal from "../shared/BaseModal";
import Button from "../shared/Button";
import { MeasurementPeriodResponse } from "../../models/MeasurementPeriod";
import { parseISO, isAfter, format, addDays, isBefore } from "date-fns";
import { CompanyResponse } from "../../models/Company";

interface Props {
  token: string;
}

export default function ApplicationPeriodUrl(props: Props): ReactElement {
  const { token } = props;

  const url = () => {
    if (window.location.host === "localhost:3000") {
      return encodeURI(
        `http://${window.location.host}/individual_application_periods/${token}`
      );
    } else {
      return encodeURI(
        `https://${window.location.host}/individual_application_periods/${token}`
      );
    }
  };

  return (
    <>
      <div>
        <input
          type="text"
          disabled={false}
          defaultValue={url()}
          className="w-full rounded border border-quaternary px-2 py-1"
        />
      </div>
      <div className="mt-3 flex justify-center">
        <CopyToClipboard
          text={url()}
          onCopy={() => {
            window.alert("コピーしました");
          }}
        >
          <button className="admin-button py-1 px-3">URLをコピー</button>
        </CopyToClipboard>
      </div>
    </>
  );
}
