import React, { ReactElement } from "react";
import SubHeader from "../../shared/SubHeader";
import { MeasurementPeriodSummaryResponse } from "../../../models/MeasurementPeriodSummary";
import { ManagerialPositionResponse } from "../../../models/ManagerialPosition";
import ManagerialPositionFactorScore from "./ItemSpecificFactorScores/ManagerialPositionFactorScore";
import AttributeFactorScore from "./ItemSpecificFactorScores/AttributeFactorScore";
import { QuestionnaireSummaryResponse } from "../../../models/QuestionnaireSummary";

interface Props {
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
  managerialPositions: ManagerialPositionResponse[];
  salariedStaffOptions: Array<{ key: string; value: string }>;
  averageActivityTimeOptions: Array<{ key: string; value: string }>;
  activityHistoryOptions: Array<{ key: string; value: string }>;
}

export default function ItemSpecificFactorScores(props: Props): ReactElement {
  const {
    measurementPeriodSummary,
    managerialPositions,
    salariedStaffOptions,
    averageActivityTimeOptions,
    activityHistoryOptions,
  } = props;

  return (
    <>
      <SubHeader title="項目別因子得点" />
      <div className="my-3">
        <ManagerialPositionFactorScore
          managerialPositions={managerialPositions}
          measurementPeriodSummary={measurementPeriodSummary}
        />
      </div>
      <div className="my-3">
        <AttributeFactorScore
          title="団体内での報酬による因子得点平均値の比較"
          measurementPeriodSummary={measurementPeriodSummary}
          options={salariedStaffOptions}
          summaries={measurementPeriodSummary.salaried_staff_summaries}
        />
      </div>
      <div className="my-3">
        <AttributeFactorScore
          title="団体内での平均活動時間による因子得点平均値の比較"
          measurementPeriodSummary={measurementPeriodSummary}
          options={averageActivityTimeOptions}
          summaries={measurementPeriodSummary.average_activity_time_summaries}
        />
      </div>
      <div className="my-3">
        <AttributeFactorScore
          title="団体内での活動歴による因子得点平均値の比較"
          measurementPeriodSummary={measurementPeriodSummary}
          options={activityHistoryOptions}
          summaries={measurementPeriodSummary.activity_history_summaries}
        />
      </div>
      <div className="text-center p-3 text-xs text-gray-600">
        ※貴団体平均を基準値とし、基準値と同じであれば白色、基準値よりも高い場合はオレンジ色、低い場合は青色で表されています。色が濃いほど基準値より離れた値であることを示しています。
      </div>
    </>
  );
}
