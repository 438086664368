import React, { ReactElement } from "react";
import { QuestionnaireSummaryResponse } from "../../models/QuestionnaireSummary";
import { MeasurementPeriodSummaryResponse } from "../../models/MeasurementPeriodSummary";
import { CategoryDistributionResponse } from "../../models/CategoryDistribution";
import MindStateReportFactorTable from "./shared/MindStateReportFactorTable";
import CategoryDistributionItem from "../category_distributions/CategoryDistributionItem";

interface Props {
  allQuestionnaireSummary: QuestionnaireSummaryResponse;
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
  categoryDistribution: CategoryDistributionResponse;
}

export default function MindsetReportFactorScore(props: Props): ReactElement {
  const {
    allQuestionnaireSummary,
    measurementPeriodSummary,
    categoryDistribution,
  } = props;

  return (
    <>
      <div className="mt-2 ml-2 p-2 font-bold text-lg">●マインドセット</div>
      <div className="flex flex-row justify-center my-3">
        <div className="w-1/2 px-3">
          <div className="p-3 border border-black">
            ・新質問ではコミュニティとの良好な関係を作れる人の特徴を明らかにするために、メンバーの下記の５つのマインドセットを測定しています。
            <br />
            多様性受容: 自分と異なる人を受け入れることができるか。
            <br />
            レジリエンス: 困難があってもくじけずに乗り越えることができるか。
            <br />
            社会的信頼:
            社会の中の他者を互いに助け合る人たちとして信頼しているかどうか。値がが高い人ほど他者を信頼しています。
            <br />
            ポジティブサム思考:
            ゼロサム思考（成功の陰には必ず誰かの犠牲が必要だとする思考）と対立する考え方。共存共栄が可能だと考えている度合い。
            <br />
            成長思考: 自分の努力によって能力や性格も変えられるという考え方 ⇄
            固定思考（能力も性格も生まれつき決まっているという考え方）
            <br />
            自己効力感:
            一般的な自己効力感ではなく、未来は自分の力で変えられるという考え方。
            <br />
            人間関係思考: 人間関係を重要と捉えているかどうか。
            <br />
          </div>

          <div className="flex justify-center mt-10">
            <MindStateReportFactorTable
              data={[
                "diversity_acceptance",
                "resilience",
                "social_trust",
                "positive_sum_thinking",
                "growth_mindset",
                "self_efficacy",
                "human_relations_thinking",
              ].map(
                (
                  k
                ): {
                  factorName: string;
                  point: number | null;
                  answeredCount: number;
                  averagePoint: number;
                  deviation: number | null;
                } => ({
                  factorName: window.I18n.t(
                    `enums.community_capital_question.category.${k}`
                  ),
                  point: measurementPeriodSummary[`${k}_point`],
                  answeredCount:
                    measurementPeriodSummary[`${k}_answered_count`],
                  averagePoint: allQuestionnaireSummary[`${k}_point`],
                  deviation: measurementPeriodSummary[`${k}_deviation_value`],
                })
              )}
            />
          </div>
          <div className="flex flex-row justify-center mt-10">
            <CategoryDistributionItem
              title="多様性受容"
              allDistribution={
                allQuestionnaireSummary.diversity_acceptance_distribution
              }
              distribution={
                categoryDistribution.diversity_acceptance_distribution
              }
            />
            <CategoryDistributionItem
              title="レジリエンス"
              allDistribution={allQuestionnaireSummary.resilience_distribution}
              distribution={categoryDistribution.resilience_distribution}
            />
          </div>
        </div>

        <div className="w-1/2 px-5">
          <div className="flex flex-row flex-wrap justify-center">
            <CategoryDistributionItem
              title="社会的信頼"
              allDistribution={
                allQuestionnaireSummary.social_trust_distribution
              }
              distribution={categoryDistribution.social_trust_distribution}
            />
            <CategoryDistributionItem
              title="ポジティブサム思考"
              allDistribution={
                allQuestionnaireSummary.positive_sum_thinking_distribution
              }
              distribution={
                categoryDistribution.positive_sum_thinking_distribution
              }
            />
            <CategoryDistributionItem
              title="成長思考"
              allDistribution={
                allQuestionnaireSummary.growth_mindset_distribution
              }
              distribution={categoryDistribution.growth_mindset_distribution}
            />
            <CategoryDistributionItem
              title="自己効力感"
              allDistribution={
                allQuestionnaireSummary.self_efficacy_distribution
              }
              distribution={categoryDistribution.self_efficacy_distribution}
            />
            <CategoryDistributionItem
              title="人間関係思考"
              allDistribution={
                allQuestionnaireSummary.human_relations_thinking_distribution
              }
              distribution={
                categoryDistribution.human_relations_thinking_distribution
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
