import React, { ReactElement } from "react";
import { format, parseISO } from "date-fns";
import ReportFactorScoreTrend from "./ReportFactorScoreTrend";
import { CompanyResponse } from "../../models/Company";
import { QuestionnaireSummaryResponse } from "../../models/QuestionnaireSummary";
import { MeasurementPeriodSummaryResponse } from "../../models/MeasurementPeriodSummary";

const companyInfoLabel = "border-black px-4 py-2 text-sm text-center";
const companyInfoValue = "border-black px-2 text-sm text-center";
const factorTableHeader =
  "border-black font-normal px-2 bg-gray-600 text-white font-bold";
const factorDescription = "border-black px-4 text-center text-xs p-1";
const factorPoint = "border-black p-2 text-center font-bold text-lg";
const subInfoLabel = "border-black px-2 text-sm text-center";
const subInfoValue = "border-black px-2 text-center";

interface Props {
  companyName: string;
  staffName?: string;
  allQuestionnaireSummary: QuestionnaireSummaryResponse;
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
  previousMeasurementPeriodSummary: MeasurementPeriodSummaryResponse | null;
  measurementPeriodTransitionSummaries: MeasurementPeriodSummaryResponse[];
}

export default function ReportCover(props: Props): ReactElement {
  const {
    companyName,
    staffName,
    allQuestionnaireSummary,
    measurementPeriodSummary,
    previousMeasurementPeriodSummary,
    measurementPeriodTransitionSummaries,
  } = props;

  return (
    <div className="flex flex-col justify-center h-full p-3">
      <div className="text-center text-xl font-bold">
        コミュニティキャピタル診断
        <br />
        診断結果レポート
      </div>
      <div className="text-center mt-2">
        {format(new Date(), "yyyy年MM月dd日")}
      </div>

      <div className="flex justify-center w-full mt-3">
        <table className="w-4/5">
          <tbody>
            <tr>
              <td className={`border ${companyInfoLabel}`}>団体名</td>
              <td className={`border-t border-r border-b ${companyInfoValue}`}>
                {companyName}
              </td>
            </tr>
            {staffName && (
              <tr>
                <td
                  className={`border-l border-r border-b ${companyInfoLabel}`}
                >
                  担当者名
                </td>
                <td className={`border-r border-b ${companyInfoValue}`}>
                  {staffName}様
                </td>
              </tr>
            )}
            <tr>
              <td className={`border-l border-r border-b ${companyInfoLabel}`}>
                回答者数
              </td>
              <td className={`border-r border-b ${companyInfoValue}`}>
                {measurementPeriodSummary.answered_count}名
              </td>
            </tr>
            <tr>
              <td className={`border-l border-r border-b ${companyInfoLabel}`}>
                実施期間
              </td>
              <td className={`border-r border-b ${companyInfoValue}`}>
                {format(
                  parseISO(
                    measurementPeriodSummary.measurement_period.start_date
                  ),
                  "yyyy年MM月dd日"
                )}
                〜
                {format(
                  parseISO(
                    measurementPeriodSummary.measurement_period.end_date
                  ),
                  "yyyy年MM月dd日"
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-5">
        <table>
          <thead>
            <tr>
              <th className={`border ${factorTableHeader}`} colSpan={2}>
                第1因子
                <br />
                「理念共感と貢献意欲」
              </th>
              <th
                className={`border-t border-r border-b ${factorTableHeader}`}
                colSpan={2}
              >
                第2因子
                <br />
                「自己有用感」
              </th>
              <th
                className={`border-t border-r border-b ${factorTableHeader}`}
                colSpan={2}
              >
                第3因子
                <br />
                「居心地の良さ」
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className={`border-l border-r border-b ${factorDescription}`}
                colSpan={2}
              >
                第1の因子は、団体の活動理念への共感とその活動への貢献意欲に関するものです。同じ集団に属する人間として、仲間と共通の目的や目標を共有し、共にそれを目指そうと思えること。「この団体を自分も一緒に担っていきたい」「とことんがんばりたい」と思える感覚が第1の因子です。
              </td>
              <td
                className={`border-r border-b ${factorDescription}`}
                colSpan={2}
              >
                第2の因子は、団体の中で自分が役に立っている、必要とされているという感覚です。ただ団体の活動に参加するだけでなく、活動・関わりを通して「役に立っている」「必要とされている」と感じられること。欠かせない存在であり、自分は重要であると思える感覚が第2の因子です。
              </td>
              <td
                className={`border-r border-b ${factorDescription}`}
                colSpan={2}
              >
                第3の因子は、居場所としての団体の状態です。それも物理的に快適であるということではなく、「人間関係が良好である」「メンバーと一緒に活動することが楽しい」「仲間といると落ち着く」と感じられること。これらによる「居心地の良さ」が第3の因子です。
              </td>
            </tr>
            <tr>
              <td
                className={`border-l border-r border-b ${factorPoint}`}
                colSpan={2}
              >
                {measurementPeriodSummary.empathy_and_willingness_point || "-"}
              </td>
              <td className={`border-r border-b ${factorPoint}`} colSpan={2}>
                {measurementPeriodSummary.self_importance_point || "-"}
              </td>
              <td className={`border-r border-b ${factorPoint}`} colSpan={2}>
                {measurementPeriodSummary.comfort_point || "-"}
              </td>
            </tr>
            <tr>
              <td className={`border-l border-r border-b ${subInfoLabel}`}>
                一般偏差値
              </td>
              <td className={`border-r border-b ${subInfoValue}`}>
                {measurementPeriodSummary.empathy_and_willingness_deviation_value ||
                  "-"}
              </td>
              <td className={`border-r border-b ${subInfoLabel}`}>
                一般偏差値
              </td>
              <td className={`border-r border-b ${subInfoValue}`}>
                {measurementPeriodSummary.self_importance_deviation_value ||
                  "-"}
              </td>
              <td className={`border-r border-b ${subInfoLabel}`}>
                一般偏差値
              </td>
              <td className={`border-r border-b ${subInfoValue}`}>
                {measurementPeriodSummary.comfort_deviation_value || "-"}
              </td>
            </tr>
            {previousMeasurementPeriodSummary &&
              previousMeasurementPeriodSummary.answered_count > 2 && (
                <>
                  <tr>
                    <td
                      className={`border-l border-r border-b ${subInfoLabel}`}
                    >
                      前回得点
                    </td>
                    <td className={`border-r border-b ${subInfoValue}`}>
                      {previousMeasurementPeriodSummary.empathy_and_willingness_point?.toFixed(
                        2
                      ) || "-"}
                    </td>
                    <td className={`border-r border-b ${subInfoLabel}`}>
                      前回得点
                    </td>
                    <td className={`border-r border-b ${subInfoValue}`}>
                      {previousMeasurementPeriodSummary.self_importance_point?.toFixed(
                        2
                      ) || "-"}
                    </td>
                    <td className={`border-r border-b ${subInfoLabel}`}>
                      前回得点
                    </td>
                    <td className={`border-r border-b ${subInfoValue}`}>
                      {previousMeasurementPeriodSummary.comfort_point?.toFixed(
                        2
                      ) || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td
                      className={`border-l border-r border-b ${subInfoLabel}`}
                    >
                      前回診断との差
                    </td>
                    <td className={`border-r border-b ${subInfoValue}`}>
                      {measurementPeriodSummary.empathy_and_willingness_point
                        ? (
                            measurementPeriodSummary.empathy_and_willingness_point -
                            (previousMeasurementPeriodSummary.empathy_and_willingness_point ||
                              0)
                          ).toFixed(2)
                        : "-"}
                    </td>
                    <td className={`border-r border-b ${subInfoLabel}`}>
                      前回診断との差
                    </td>
                    <td className={`border-r border-b ${subInfoValue}`}>
                      {measurementPeriodSummary.self_importance_point
                        ? (
                            measurementPeriodSummary.self_importance_point -
                            (previousMeasurementPeriodSummary.self_importance_point ||
                              0)
                          ).toFixed(2)
                        : "-"}
                    </td>
                    <td className={`border-r border-b ${subInfoLabel}`}>
                      前回診断との差
                    </td>
                    <td className={`border-r border-b ${subInfoValue}`}>
                      {measurementPeriodSummary.comfort_point
                        ? (
                            measurementPeriodSummary.comfort_point -
                            (previousMeasurementPeriodSummary.comfort_point ||
                              0)
                          ).toFixed(2)
                        : "-"}
                    </td>
                  </tr>
                </>
              )}
          </tbody>
        </table>
      </div>
      <div className="mt-2">
        <ReportFactorScoreTrend
          allQuestionnaireSummary={allQuestionnaireSummary}
          measurementPeriodTransitionSummaries={
            measurementPeriodTransitionSummaries
          }
        />
      </div>
    </div>
  );
}
