import React, { ReactElement } from "react";
import Button from "../../shared/Button";
import BaseModal from "../../shared/BaseModal";
import ManagerialPositionListItem from "./ManagerialPositionListItem";
import { ManagerialPositionEditType } from "../../../models/ManagerialPosition";
import {
  primaryButtonClassName,
  subButtonClassName,
} from "../../../utils/ClassNameUtils";

interface Props {
  managerialPositions: Array<ManagerialPositionEditType>;
  onClose: () => void;
  onSave: () => void;
  onChange: (index: number, mp: ManagerialPositionEditType) => void;
  onMoveUp: (index: number) => void;
  onMoveDown: (index: number) => void;
  onCreate: () => void;
  onDelete: (index: number) => void;
}

export default function ManagerialPositionList(props: Props): ReactElement {
  const {
    managerialPositions,
    onClose,
    onSave,
    onChange,
    onMoveUp,
    onMoveDown,
    onCreate,
    onDelete,
  } = props;

  const save = (): void => {
    if (
      confirm(
        "回答者が2名以下の役職・ポジションの診断結果は、個人の特定を避けるため結果が表示されません。このまま保存してよろしいですか？"
      )
    )
      onSave();
  };

  return (
    <>
      <BaseModal.Body>
        <div className="text-xs bg-quinary p-2 bordr-b">
          <p>・「役職・ポジション」について教えてください。</p>
          <p className="mt-2">◇役職・ポジション数：5種類ほど（目安）</p>
          <p className="mt-2">
            ◇役職・ポジション名：特に決まりはありません。
            <br />
            ※回答するメンバーが選択しやすい「役職・ポジション」名称にしてください。
          </p>
          <p className="mt-2">
            ＜役職・ポジションの例＞
            <br />
            代表、幹部、理事、有給フルタイムスタッフ、有給パートスタッフ、部門リーダー、インターン、ボランティア、社会人プロボノ、サポーター、寄付会員、など
          </p>
          <p className="mt-2">
            【設定例】 役職・ポジション名
            <br />
            ---------------------------------------------------------------
            <br />
            1. 代表・幹部
            <br />
            2. 部門リーダー
            <br />
            3. 社会人ボランティア
            <br />
            4.学生インターン
            <br />
            5. その他
            <br />
            ---------------------------------------------------------------
          </p>
          <p className="mt-2">
            ◇役職・ポジションごとの想定人数：3名以上
            <br />
            ※想定人数が2名以下の場合は、その方を特定できてしまうため設定ができません。
            <br />
            ※例えば「代表(1名)」の役職・ポジションは、設定することができません。「代表・幹部(５名）」と3名以上になるように設定してください。
          </p>
        </div>
        <div className="mt-4">
          {managerialPositions.map((mp, i, arr) => (
            <ManagerialPositionListItem
              key={mp.id || mp.tempId}
              managerialPosition={mp}
              isFirst={i === 0}
              isEnd={arr.length - 1 === i}
              moveUp={() => onMoveUp(i)}
              moveDown={() => onMoveDown(i)}
              onChange={(mp) => onChange(i, mp)}
              onDelete={() => onDelete(i)}
            />
          ))}
        </div>
        {managerialPositions.length > 0 && (
          <div className="flex justify-end my-2">
            <Button
              onClick={onCreate}
              title="追加"
              className={`${subButtonClassName()} px-4`}
            />
          </div>
        )}
        {managerialPositions.length === 0 && (
          <div className="flex justify-center mt-4">
            <Button
              onClick={onCreate}
              title="役職・ポジション追加"
              className={`${subButtonClassName()} w-2/3 py-2`}
            />
          </div>
        )}
      </BaseModal.Body>
      <BaseModal.Footer className="flex justify-end">
        <Button
          title="閉じる"
          className={`${subButtonClassName()} px-3 py-1`}
          onClick={onClose}
        />
        <Button
          title="保存"
          className={`${primaryButtonClassName()} ml-4 px-4 py-1`}
          onClick={save}
        />
      </BaseModal.Footer>
    </>
  );
}
