import React, { ChangeEvent, ReactElement } from "react";
import CheckboxForm from "./CheckboxForm";
import { fieldId, i18nKey } from "../../../utils/AttributeUtils";
import { isAdmin } from "../../../utils/NavigationUtils";
import HiddenForm from "./HiddenForm";

interface Props {
  scope: string;
  columnName: string;
  relatedModelName: string;
  options: Array<{
    relatedModelId?: number;
    activeKey: string;
    disableKey?: string;
    title: string;
    defaultChecked?: boolean;
  }>;
  containerClassName?: string;
  fieldWrapContainerClassName?: string;
  showBottomBorder?: boolean;
  hideLabel?: boolean;
  labelClassName?: string;
  required?: boolean;
  label?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export default function CheckboxesForm(props: Props): ReactElement {
  const {
    scope,
    relatedModelName,
    columnName,
    options,
    containerClassName = isAdmin
      ? "flex flex-wrap items-center"
      : "flex flex-wrap items-center mt-3",
    fieldWrapContainerClassName: propFieldWrapContainerClassName = isAdmin
      ? undefined
      : "w-full p-2",
    showBottomBorder,
    hideLabel,
    labelClassName = isAdmin
      ? "w-full md:w-1/4 pt-2 px-2 md:p-2 text-gray-600 text-sm flex items-center"
      : "w-full pt-2 px-2 md:p-2 text-sm flex items-center flex-wrap font-bold",
    required,
    label = "",
    onChange,
  } = props;

  let fieldWrapContainerClassName = "w-full md:w-3/4";
  if (propFieldWrapContainerClassName) {
    fieldWrapContainerClassName = propFieldWrapContainerClassName;
  } else if (hideLabel) {
    fieldWrapContainerClassName = "w-full";
  }

  const viewLabel = (): string => {
    if (label !== "") {
      return label;
    }
    return window.I18n.t(i18nKey(scope, relatedModelName, columnName));
  };

  return (
    <>
      <div
        className={`${containerClassName} ${
          showBottomBorder ? "border-b border-gray-300" : ""
        }`}
      >
        {!hideLabel && (
          <label className={labelClassName}>
            {viewLabel()}
            {required && (
              <div className="ml-1 rounded px-1 text-red-600 text-xs">必須</div>
            )}
          </label>
        )}
        <div className={fieldWrapContainerClassName}>
          {options.map((opt, i) => {
            const {
              relatedModelId,
              activeKey,
              disableKey,
              title,
              defaultChecked,
            } = opt;
            return (
              <React.Fragment key={i}>
                {relatedModelId && (
                  <HiddenForm
                    scope={scope}
                    relatedModelName={relatedModelName}
                    columnName="id"
                    index={i}
                    value={relatedModelId}
                  />
                )}
                <CheckboxForm
                  title={title}
                  scope={scope}
                  relatedModelName={relatedModelName}
                  columnName={columnName}
                  index={i}
                  defaultChecked={defaultChecked}
                  activeKey={activeKey}
                  disableKey={disableKey}
                  onChange={(e) => onChange && onChange(e)}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
}
