import React, { ReactElement, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Button from "../../shared/Button";
import BaseModal from "../../shared/BaseModal";
import ManagerialPositionList from "./ManagerialPositionList";
import { ManagerialPositionEditType } from "../../../models/ManagerialPosition";
import { subButtonClassName } from "../../../utils/ClassNameUtils";

interface Props {
  managerialPositions: Array<ManagerialPositionEditType>;
  onChange: (mp: Array<ManagerialPositionEditType>) => void;
  className?: string;
}

export default function ManagerialPositionListEditButton(
  props: Props
): ReactElement {
  const {
    managerialPositions: propManagerialPositions,
    onChange,
    className = `${subButtonClassName()} px-4 py-1 text-sm`,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [managerialPositions, setManagerialPositions] = useState<
    Array<ManagerialPositionEditType>
  >(propManagerialPositions);

  const moveUp = (position: number): void => {
    const arr = managerialPositions.concat();
    arr[position - 1] = managerialPositions[position];
    arr[position] = managerialPositions[position - 1];
    setManagerialPositions(arr);
  };

  const moveDown = (position: number): void => {
    const arr = managerialPositions.concat();
    arr[position + 1] = managerialPositions[position];
    arr[position] = managerialPositions[position + 1];
    setManagerialPositions(arr);
  };

  const update = (position: number, mp: ManagerialPositionEditType): void => {
    const arr = managerialPositions.concat();
    arr[position] = Object.assign({}, managerialPositions[position], mp);
    setManagerialPositions(arr);
  };

  const addItem = (): void => {
    const activeManagerialPositions = managerialPositions.filter(
      (mp) => mp._destroy === undefined
    );
    if (activeManagerialPositions.length >= 10) {
      window.alert("ポジションの登録上限は10個です");
      return;
    }
    setManagerialPositions(
      managerialPositions.concat({
        name: `ポジション${managerialPositions.length + 1}`,
        tempId: uuidv4(),
      })
    );
  };

  const deleteItem = (position: number): void => {
    const arr = managerialPositions.concat();
    arr.splice(
      position,
      1,
      Object.assign({}, managerialPositions[position], { _destroy: "1" })
    );
    setManagerialPositions(arr);
  };

  return (
    <>
      {showModal && (
        <BaseModal
          show={showModal}
          contentLabel="ManagerialPositionList"
          title="役職・ポジション編集(10個まで登録可)"
          onCloseModal={() => setShowModal(false)}
        >
          <ManagerialPositionList
            managerialPositions={managerialPositions.filter(
              (mp) => !mp._destroy
            )}
            onClose={() => {
              setShowModal(false);
              setManagerialPositions(propManagerialPositions);
            }}
            onSave={() => {
              setShowModal(false);
              onChange(managerialPositions);
              setManagerialPositions(propManagerialPositions);
            }}
            onChange={update}
            onMoveUp={moveUp}
            onMoveDown={moveDown}
            onCreate={addItem}
            onDelete={deleteItem}
          />
        </BaseModal>
      )}
      <Button
        title="編集"
        onClick={() => setShowModal(true)}
        className={className}
      />
    </>
  );
}
