import React, { ReactElement } from "react";
import { Line } from "react-chartjs-2";

interface Props {
  data: Array<{
    factorName: string;
    point: number | null;
    averagePoint: number;
    deviation: number | null;
  }>;
}

export default function MindStateReportFactorTable(props: Props): ReactElement {
  const { data } = props;
  return (
    <>
      <table className="table-auto">
        <thead>
          <tr>
            <th className="border font-normal px-3">因子名称</th>
            <th className="border font-normal px-3">貴団体</th>
            <th className="border font-normal px-3">サンプル全体</th>
            <th className="border font-normal px-3">偏差値</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d) => {
            return (
              <tr key={d.factorName}>
                <td className="border px-4 py-2 text-center">{d.factorName}</td>
                {d.point !== null && (
                  <td className="border px-4 py-2 text-center">
                    {d.point?.toFixed(2) || "-"}
                  </td>
                )}
                {d.point === null && (
                  <td className="border px-4 py-2 text-center text-xs">
                    回答なし
                  </td>
                )}
                <td className="border px-4 py-2 text-center">
                  {d.averagePoint?.toFixed(2) || "-"}
                </td>
                <td className="border px-4 py-2 text-center">
                  {d.deviation?.toFixed(1) || "-"}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
