import React, { ReactElement, useState } from "react";
import ManagerialPositionListEditButton from "../../managerial_positions/ManagerialPositionListForm/ManagerialPositionListEditButton";
import HiddenForm from "../../shared/forms/HiddenForm";
import { ManagerialPositionEditType } from "../../../models/ManagerialPosition";
import { MeasurementPeriodEditType } from "../../../models/MeasurementPeriod";

interface Props {
  measurementPeriod: MeasurementPeriodEditType;
}

export default function ManagerialPositionListForm(props: Props): ReactElement {
  const { measurementPeriod } = props;
  const [managerialPositions, setManagerialPositions] = useState<
    Array<ManagerialPositionEditType>
  >(measurementPeriod.managerial_positions);

  return (
    <>
      {managerialPositions.map((mp, i) => (
        <React.Fragment key={mp.id || mp.tempId}>
          {mp.id && (
            <HiddenForm
              scope="measurement_period"
              relatedModelName="managerial_positions"
              index={i}
              columnName="id"
              value={mp.id}
            />
          )}
          <HiddenForm
            scope="measurement_period"
            relatedModelName="managerial_positions"
            index={i}
            columnName="name"
            value={mp.name}
          />
          <HiddenForm
            scope="measurement_period"
            relatedModelName="managerial_positions"
            index={i}
            columnName="position"
            value={i}
          />
          {mp._destroy && (
            <HiddenForm
              scope="measurement_period"
              relatedModelName="managerial_positions"
              index={i}
              columnName="_destroy"
              value="1"
            />
          )}
        </React.Fragment>
      ))}
      <div className="flex flex-wrap items-center border-b border-gray-300">
        <label className="w-full md:w-1/4 pt-2 px-2 md:p-2 text-gray-600 text-sm">
          役職・ポジション
        </label>
        <div className="w-full md:w-3/4 p-2 flex justify-between">
          <div className="text-sm">
            {managerialPositions
              .filter((mp) => !mp._destroy)
              .map((mp) => (
                <div key={mp.id || mp.tempId}>{mp.name}</div>
              ))}
          </div>
          <div>
            <ManagerialPositionListEditButton
              managerialPositions={managerialPositions}
              onChange={(mps) => setManagerialPositions(mps)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
