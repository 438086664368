import React, { ReactElement, useState } from "react";
import Button from "../../shared/Button";
import TextForm from "../../shared/forms/TextForm";
import EmailForm from "../../shared/forms/EmailForm";
import BaseModal from "../../shared/BaseModal";
import SelectForm from "../../shared/forms/SelectForm";
import CheckboxesForm from "../../shared/forms/CheckboxesForm";
import CheckboxWithTextForm from "../../shared/forms/CheckboxWithTextForm";
import { CompanyOverviewResponse } from "../../../models/CompanyApi";
import { ActivityFieldResponse } from "../../../models/ActivityField";
import HiddenForm from "../../shared/forms/HiddenForm";
import { MeasurementPeriodResponse } from "../../../models/MeasurementPeriod";
import CitySelectForm from "../../cities/CitySelectForm";
import BaseForm, { ajaxSubmit } from "../../shared/BaseForm";
import { Props as FlushMessageProps } from "../../shared/FlushMessage";
import { getMilliseconds } from "date-fns";

interface Props {
  company: CompanyOverviewResponse;
  measurementPeriod: MeasurementPeriodResponse;
  annualIncomeOptions: Array<{ key: string; value: string }>;
  numberOfEmployeesOptions: Array<{ key: string; value: string }>;
  numberOfMembersOptions: Array<{ key: string; value: string }>;
  prefectureOptions: Array<{ key: string; value: string }>;
  establishmentYearOptions: Array<{ key: string; value: string }>;
  activityFields: ActivityFieldResponse[];
  flushMessage?: FlushMessageProps;
  beforeSave: () => void;
  onReturn: () => void;
  afterSave: (
    editedCompany: CompanyOverviewResponse,
    editedMeasurementPeriod: MeasurementPeriodResponse
  ) => void;
  onError: (error: { data: { messages: string[] } } | undefined) => void;
}

export default function CompanyInformationInputModalContent(
  props: Props
): ReactElement {
  const {
    company,
    measurementPeriod,
    annualIncomeOptions,
    numberOfEmployeesOptions,
    numberOfMembersOptions,
    prefectureOptions,
    establishmentYearOptions,
    activityFields,
    flushMessage,
    beforeSave,
    afterSave,
    onError,
    onReturn,
  } = props;
  const [prefectureCode, setPrefectureCode] = useState<
    number | undefined | null
  >(company.prefecture_code);

  const saveCompanyInformation = async (): Promise<void> => {
    const form = document.forms["CompanyInfoEditForm"];
    if (form.checkValidity()) {
      beforeSave();
      const { result, error } = await ajaxSubmit<{
        company: CompanyOverviewResponse;
        measurement_period: MeasurementPeriodResponse;
      }>(form);
      if (result && result.status === 200) {
        const {
          company: editedCompany,
          measurement_period: editedMeasurementPeriod,
        } = result.data;
        afterSave(editedCompany, editedMeasurementPeriod);
      } else {
        onError(error);
      }
    } else {
      const submit = document.getElementById("companyInfoEditFormSubmit");
      if (submit) {
        submit.click();
        window.alert("未入力項目が存在します");
      }
    }
  };

  return (
    <>
      <BaseForm
        action={`/user/companies/${company.id}/update_information`}
        name="CompanyInfoEditForm"
        method="put"
      >
        <BaseModal.Body flushMessage={flushMessage}>
          <div className="text-center">
            <span className="font-bold">団体情報をすべて入力してください</span>
          </div>
          <div>
            <input
              type="hidden"
              value={measurementPeriod.id}
              name="measurement_period_id"
              id="measurement_period_id"
            />
            <TextForm
              model={company}
              scope="company"
              columnName="representative_name"
              required
            />
            <EmailForm
              model={company}
              scope="company"
              columnName="email"
              required
              placeholder="xxx@xxx.xx"
            />
            <label className="w-full pt-2 px-2 md:p-2 text-sm flex items-center flex-wrap font-bold">
              <div>団体ホームページ</div>
              <div className="ml-1 px-1 text-red-600 text-xs leading-loose">
                必須
              </div>
            </label>
            <div className="text-sm p-2">
              ※ホームページが存在しない場合は「なし」と記入してください
            </div>
            <TextForm
              model={company}
              scope="company"
              columnName="home_page"
              required
              placeholder="https://〜"
              hideLabel
              containerClassName="flex flex-wrap items-center"
            />
            <SelectForm
              model={company}
              scope="company"
              columnName="establishment_year"
              option={{ include_blank: "-- 選択してください --" }}
              options={establishmentYearOptions}
              required
            />
            <CheckboxesForm
              scope="company"
              relatedModelName="activity_field_companies"
              columnName="activity_field_id"
              label="主な活動分野(複数選択可)"
              required
              options={activityFields.map((af) => ({
                relatedModelId: company.activity_field_companies.find(
                  (afc) => afc.activity_field_id === af.id
                )?.id,
                activeKey: String(af.id),
                title: af.name,
                disableKey: "",
                defaultChecked: company.activity_field_companies.some(
                  (afc) => afc.activity_field_id === af.id
                ),
              }))}
            />
            <CheckboxWithTextForm
              model={company}
              scope="company"
              columnName="activity_field_free_entry"
              title="その他"
              defaultChecked={!!company.activity_field_free_entry}
              containerClassName="-mt-4"
              placeholder="主な活動分野 自由記入欄"
            />
            <SelectForm
              model={company}
              scope="company"
              columnName="prefecture_code"
              option={{ include_blank: "-- 選択してください --" }}
              options={prefectureOptions}
              required
              onChange={(e) => {
                if (e.target.value === "") {
                  setPrefectureCode(null);
                } else {
                  setPrefectureCode(Number(e.target.value));
                }
              }}
            />
            {/* prefectureCode 99: その他 */}
            {prefectureCode !== undefined &&
              prefectureCode !== null &&
              prefectureCode !== 99 && (
                <CitySelectForm
                  model={company}
                  scope="company"
                  prefectureCode={prefectureCode}
                />
              )}
            <label className="w-full pt-2 px-2 md:p-2 text-sm flex items-center flex-wrap font-bold">
              <div>団体の年間総収入</div>
              <div className="ml-1 px-1 text-red-600 text-xs leading-loose">
                必須
              </div>
            </label>
            <div className="text-sm px-2">
              ※企業の場合は売上高、NPO法人の場合は経常収益、など
            </div>
            <SelectForm
              model={company}
              scope="company"
              columnName="annual_income"
              option={{ include_blank: "-- 選択してください --" }}
              options={annualIncomeOptions}
              required
              hideLabel
            />
            <SelectForm
              model={company}
              scope="company"
              columnName="number_of_members"
              option={{ include_blank: "-- 選択してください --" }}
              options={numberOfMembersOptions}
              required
            />
            <SelectForm
              model={company}
              scope="company"
              columnName="number_of_employees"
              option={{ include_blank: "-- 選択してください --" }}
              options={numberOfEmployeesOptions}
              required
            />
          </div>
          <button
            type="submit"
            id="companyInfoEditFormSubmit"
            className="p-0 border-none"
          />
        </BaseModal.Body>
        <BaseModal.Footer className="flex justify-between items-center">
          <div>
            <Button
              onClick={onReturn}
              title="戻る"
              className="opacity-75 tex-xs px-2 py-1"
            />
          </div>
          <div>
            <Button
              title="保存"
              className="primary-button px-5 py-1"
              onClick={saveCompanyInformation}
            />
          </div>
        </BaseModal.Footer>
      </BaseForm>
    </>
  );
}
