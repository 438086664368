import React, { ReactElement } from "react";
import SubHeader from "../shared/SubHeader";
import { QuestionnaireSummaryResponse } from "../../models/QuestionnaireSummary";
import CategoryDistributionItem from "../category_distributions/CategoryDistributionItem";
import { QuestionnaireOverviewResponse } from "../../models/QuestionnaireApi";
import { toPercent } from "../../models/CategoryDistribution";
import PersonalMindStateCategoryDistribution from "./PersonalMindStateFactorScoreDistribution/PersonalMindStateCategoryDistribution";

interface Props {
  allQuestionnaireSummary: QuestionnaireSummaryResponse;
  questionnaire: QuestionnaireOverviewResponse;
}

function createDistributionData(
  point: number | null,
  allQuestionnaireDistribution: number[]
): number[] {
  const maxVal = Math.max(...toPercent(allQuestionnaireDistribution));
  return Array(10)
    .fill(0)
    .map((v, i) => {
      if (point == null) {
        return v;
      }
      if (i === 0) {
        if (i <= point && point <= i + 1) {
          return maxVal;
        }
      } else if (i < point && point <= i + 1) {
        return maxVal;
      }
      return v;
    });
}

export default function PersonalMindStateFactorScoreDistribution(
  props: Props
): ReactElement {
  const { allQuestionnaireSummary, questionnaire } = props;

  return (
    <>
      <SubHeader title="マインドセット" />
      <div className="p-2 bg-quinary text-sm mt-2">
        マインドセットとは私たちの考え方や物事の見方を指すもので、私たちの行動や生き方に大きな影響を与えることが学術的に明らかにされています。また、私たちのマインドセットは様々な要因を受けて変化し、知らず知らずのうちにやや偏ったマインドセットを持ってしまうこともあります。コミュニティキャピタル診断では、団体とのかかわり方を考えるヒントとしてマインドセットの測定も行っています。何かの参考になれば幸いです。
      </div>
      <div className="my-3">
        <PersonalMindStateCategoryDistribution
          allDistribution={allQuestionnaireSummary.social_trust_distribution}
          distribution={createDistributionData(
            questionnaire.social_trust_point,
            allQuestionnaireSummary.social_trust_distribution
          )}
          title="社会的信頼"
          description={`社会の中の他者を互いに助けあえる人たちとして信頼しているか。\nそれとも自分の生活を脅かす恐れのある人として警戒しているか\n親しい人ではなく、一般的な他者に対する信頼感を測定しています。`}
          minAxisTitle="信頼していない"
          maxAxisTitle="信頼している"
        />
        <PersonalMindStateCategoryDistribution
          allDistribution={
            allQuestionnaireSummary.positive_sum_thinking_distribution
          }
          distribution={createDistributionData(
            questionnaire.positive_sum_thinking_point,
            allQuestionnaireSummary.positive_sum_thinking_distribution
          )}
          title="ポジティブサム思考"
          description={`ポジティブサム思考とは、世の中の人々は互いに助け合うことで共存共栄が可能であるという考え方。\nこれと対立するゼロサム思考は誰かの成功には必ず誰かの犠牲が必要だとする考え方。\n他の人と比べて自分の状態を評価する人はゼロサム志向になりやすい。`}
          minAxisTitle="ゼロサム思考"
          maxAxisTitle="ポジティブサム思考"
        />
        <PersonalMindStateCategoryDistribution
          allDistribution={allQuestionnaireSummary.growth_mindset_distribution}
          distribution={createDistributionData(
            questionnaire.growth_mindset_point,
            allQuestionnaireSummary.growth_mindset_distribution
          )}
          title="成長思考"
          description={`成長思考は自分の努力によって能力や性格も変えられるという考え方。\nこれに対して、固定思考は、能力も性格も生まれつき決まっているという考え方。\n学術的には遺伝的に決まる部分と努力で伸ばせる部分があることはわかっているが、その割合は個人差があると考えられ、かつ測定が難しいため、偏った考え方に陥りやすい。`}
          minAxisTitle="固定思考"
          maxAxisTitle="成長思考"
        />
        <PersonalMindStateCategoryDistribution
          allDistribution={allQuestionnaireSummary.self_efficacy_distribution}
          distribution={createDistributionData(
            questionnaire.self_efficacy_point,
            allQuestionnaireSummary.self_efficacy_distribution
          )}
          title="自己効力感"
          description={`一般的な自己効力感ではなく、未来は自分の力で変えられるという考え方。\nこれに対して、たとえば、すべて運命で決まっているから、あるいは他の人たちの行動を変えることはできないから、自分の力では未来を変えることができないという考え方もある。`}
          minAxisTitle="未来は変えられない"
          maxAxisTitle="未来は変えられる"
        />
        <PersonalMindStateCategoryDistribution
          allDistribution={
            allQuestionnaireSummary.human_relations_thinking_distribution
          }
          distribution={createDistributionData(
            questionnaire.human_relations_thinking_point,
            allQuestionnaireSummary.human_relations_thinking_distribution
          )}
          title="人間関係思考"
          description={`人間関係を重要と捉えているかどうか。\n人は一人では生きていけないという考え方もあれば、一人でも生きていけるという考え方もある。`}
          minAxisTitle="人間関係は重要ではない"
          maxAxisTitle="人間関係は重要だ"
        />
      </div>
      <div className="mt-3 text-sm text-gray-600 text-center">
        ※診断の質問を飛ばして回答した場合は、結果が表示されないことがあります。
      </div>
    </>
  );
}
