import React, { ReactElement } from "react";
import ReportFactorScore from "./ReportFactorScore";
import ReportFactorScoreDistribution from "./ReportFactorScoreDistribution";
import { QuestionnaireSummaryResponse } from "../../models/QuestionnaireSummary";
import { MeasurementPeriodSummaryResponse } from "../../models/MeasurementPeriodSummary";
import { CategoryDistributionResponse } from "../../models/CategoryDistribution";

interface Props {
  allQuestionnaireSummary: QuestionnaireSummaryResponse;
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
  categoryDistribution: CategoryDistributionResponse;
}

export default function ReportPage1(props: Props): ReactElement {
  const {
    allQuestionnaireSummary,
    measurementPeriodSummary,
    categoryDistribution,
  } = props;
  return (
    <>
      <ReportFactorScore
        allQuestionnaireSummary={allQuestionnaireSummary}
        measurementPeriodSummary={measurementPeriodSummary}
      />
      <div className="-mt-8">
        <ReportFactorScoreDistribution
          allQuestionnaireSummary={allQuestionnaireSummary}
          categoryDistribution={categoryDistribution}
        />
      </div>
    </>
  );
}
