import React, { ReactElement, useState } from "react";
import Button from "../shared/Button";
import SubHeader from "../shared/SubHeader";
import CompanyMemberAdminEditModal from "./CompanyMemberAdminEditModal";
import CompanyMemberAdminListItem from "./CompanyMemberAdminListItem";
import { CompanyMemberIndexResponse } from "../../models/CompanyMemberApi";
import { CompanyResponse } from "../../models/Company";
import { QuestionIndexResponse } from "../../models/QuestionApi";

interface Props {
  company: CompanyResponse;
  companyMembers: CompanyMemberIndexResponse[];
}

export default function CompanyMemberAdminList(props: Props): ReactElement {
  const { company, companyMembers: propCompanyMembers } = props;
  const [companyMembers, setCompanyMembers] = useState(propCompanyMembers);
  const [showModal, setShowModal] = useState(false);

  const addCompanyMember = (cm: CompanyMemberIndexResponse): void => {
    setCompanyMembers([cm].concat(companyMembers));
  };

  const updateCompanyMember = (
    index: number,
    cm: CompanyMemberIndexResponse
  ): void => {
    const arr = companyMembers.concat();
    arr[index] = Object.assign({}, companyMembers[index], cm);
    setCompanyMembers(arr);
  };

  const deleteCompanyMember = (index: number): void => {
    const arr = companyMembers.concat();
    arr.splice(index, 1);
    setCompanyMembers(arr);
  };

  return (
    <>
      {showModal && (
        <CompanyMemberAdminEditModal
          company={company}
          onClose={() => setShowModal(false)}
          onChange={(cm) => {
            addCompanyMember(cm);
            setShowModal(false);
            window.Alert.success("担当者を追加しました");
          }}
        />
      )}
      <div className="flex justify-end mt-4">
        <Button
          onClick={() => setShowModal(true)}
          title="担当者追加"
          className="primary-button px-6 py-1"
        />
      </div>
      <div className="mt-4">
        <SubHeader title="担当者一覧" />
        <div className="p-2">
          <table className="table-fixed w-full">
            <thead>
              <tr>
                <th className="text-left p-2 text-sm">名前</th>
                <th className="text-left p-2 text-sm">状態</th>
                <th className="w-10 py-2"></th>
              </tr>
            </thead>
            <tbody>
              {companyMembers.map((cm, i) => (
                <CompanyMemberAdminListItem
                  key={cm.id}
                  company={company}
                  companyMember={cm}
                  onChange={(editedCompanyMember) => {
                    updateCompanyMember(i, editedCompanyMember);
                    window.Alert.success("担当者の変更が完了しました");
                  }}
                  afterDeleted={() => {
                    deleteCompanyMember(i);
                    window.Alert.success(
                      `${cm.user.name}さんを担当者から削除しました`
                    );
                  }}
                  afterResendMail={(editedCompanyMember) => {
                    updateCompanyMember(i, editedCompanyMember);
                    window.Alert.success(
                      `${cm.user.name}さんに招待メールを送信しました`
                    );
                  }}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
