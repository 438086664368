import React, { ReactElement } from "react";
import { Bar } from "react-chartjs-2";
import { colors } from "../../../../utils/GraphColorUtils";
import { MeasurementPeriodAttributesSummaryResponse } from "../../../../models/MeasurementPeriodAttributesSummary";
import { QuestionnaireSummaryResponse } from "../../../../models/QuestionnaireSummary";
import AttributeScoreCell from "../../../shared/AttributeScoreCell";
import { MeasurementPeriodSummaryResponse } from "../../../../models/MeasurementPeriodSummary";

const chartOptions = {
  responsive: false,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          min: 2,
          max: 10,
          stepSize: 2,
        },
      },
    ],
  },
  fill: false,
};

interface Props {
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
  title: string;
  summaries: MeasurementPeriodAttributesSummaryResponse[];
  options: Array<{ key: string; value: string }>;
}

export default function AttributeFactorScore(props: Props): ReactElement {
  const { measurementPeriodSummary, title, summaries, options } = props;
  return (
    <>
      <h5 className="p-3 text-center">{title}</h5>
      <div className="flex flex-row justify-center items-center flex-wrap">
        <div className="p-2 w-full lg:w-1/3 flex justify-center">
          <div>
            <Bar
              height={320}
              width={320}
              data={{
                labels: ["", "", ""],
                datasets: options
                  .concat({ key: "null", value: "無回答" })
                  .map((o, i) => {
                    const summary = summaries.find((s) => s.key === o.key);
                    return {
                      label: o.value,
                      data: [
                        summary?.empathy_and_willingness_point || 0,
                        summary?.self_importance_point || 0,
                        summary?.comfort_point || 0,
                      ],
                      borderColor: colors[i],
                      backgroundColor: colors[i],
                    };
                  }),
              }}
              options={chartOptions}
            />
            <div className="flex justify-around text-xs">
              <div>理念共感と貢献意欲</div>
              <div>自己有用感</div>
              <div>居心地の良さ</div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-2/3 flex justify-center">
          <div className="px-3 w-full">
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="border font-normal text-sm px-2"></th>
                  <th className="border font-normal text-sm px-2">
                    理念共感と貢献意欲
                  </th>
                  <th className="border font-normal text-sm px-2">
                    自己有用感
                  </th>
                  <th className="border font-normal text-sm px-2">
                    居心地の良さ
                  </th>
                </tr>
              </thead>
              <tbody>
                {options
                  .concat({ key: "null", value: "無回答" })
                  .map((o, i) => {
                    const summary = summaries.find((s) => s.key === o.key);
                    return (
                      <tr key={o.key}>
                        <td className="border px-4 py-2 text-sm text-center">
                          <div className="flex items-center">
                            <div
                              className="rounded h-3 w-3"
                              style={{ backgroundColor: colors[i] }}
                            />
                            <div className="ml-2">{o.value}</div>
                          </div>
                        </td>
                        <AttributeScoreCell
                          className="border px-2 text-center text-sm"
                          answeredCount={
                            summary?.empathy_and_willingness_answered_count
                          }
                          point={summary?.empathy_and_willingness_point}
                          averagePoint={
                            measurementPeriodSummary.empathy_and_willingness_point
                          }
                        />
                        <AttributeScoreCell
                          className="border px-2 text-center text-sm"
                          answeredCount={
                            summary?.self_importance_answered_count
                          }
                          point={summary?.self_importance_point}
                          averagePoint={
                            measurementPeriodSummary.self_importance_point
                          }
                        />
                        <AttributeScoreCell
                          className="border px-2 text-center text-sm"
                          answeredCount={summary?.comfort_answered_count}
                          point={summary?.comfort_point}
                          averagePoint={measurementPeriodSummary.comfort_point}
                        />
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
