import React, { ReactElement } from "react";
import SubHeader from "../../shared/SubHeader";
import RespondentDistributionItem from "./RespondentDistributionItem";
import { MeasurementPeriodSummaryResponse } from "../../../models/MeasurementPeriodSummary";
import { ManagerialPositionResponse } from "../../../models/ManagerialPosition";
import { answerCountPercent as attributesAnswerCountPercent } from "../../../models/MeasurementPeriodAttributesSummary";
import { answerCountPercent as managerialPositionAnswerCountPercent } from "../../../models/MeasurementPeriodManagerialPositionSummary";
import { addNullElement } from "../../../utils/ArrayUtils";

interface Props {
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
  managerialPositions: ManagerialPositionResponse[];
  salariedStaffOptions: Array<{ key: string; value: string }>;
  averageActivityTimeOptions: Array<{ key: string; value: string }>;
  activityHistoryOptions: Array<{ key: string; value: string }>;
}

export default function RespondentDistribution(props: Props): ReactElement {
  const {
    measurementPeriodSummary,
    managerialPositions,
    salariedStaffOptions,
    averageActivityTimeOptions,
    activityHistoryOptions,
  } = props;
  return (
    <>
      <SubHeader title="回答者の分布" />
      <div className="flex flex-row justify-center flex-wrap my-3">
        <RespondentDistributionItem
          title="ポジション"
          data={addNullElement(managerialPositions).map((mp) => ({
            label: mp?.name || "無回答",
            rate: managerialPositionAnswerCountPercent(
              measurementPeriodSummary.managerial_position_summaries,
              mp
            ),
          }))}
        />
        <RespondentDistributionItem
          title="報酬"
          data={salariedStaffOptions
            .concat({ key: "null", value: "無回答" })
            .map((o) => ({
              label: o.value,
              rate: attributesAnswerCountPercent(
                measurementPeriodSummary.salaried_staff_summaries,
                o.key
              ),
            }))}
        />
      </div>
      <div className="flex flex-row justify-center flex-wrap my-3">
        <RespondentDistributionItem
          title="平均活動時間(一ヶ月)"
          data={averageActivityTimeOptions
            .concat({ key: "null", value: "無回答" })
            .map((o) => ({
              label: o.value,
              rate: attributesAnswerCountPercent(
                measurementPeriodSummary.average_activity_time_summaries,
                o.key
              ),
            }))}
        />
        <RespondentDistributionItem
          title="活動歴"
          data={activityHistoryOptions
            .concat({ key: "null", value: "無回答" })
            .map((o) => ({
              label: o.value,
              rate: attributesAnswerCountPercent(
                measurementPeriodSummary.activity_history_summaries,
                o.key
              ),
            }))}
        />
      </div>
    </>
  );
}
