import React, { ReactElement, useState } from "react";
import Button from "../../shared/Button";
import PaymentStatusEditModal from "./PaymentStatusEditModal";
import { PaymentStatusResponse } from "../../../models/MeasurementPeriodApi";

interface Props {
  measurementPeriod: PaymentStatusResponse;
}

export default function PaymentStatusForm(props: Props): ReactElement {
  const { measurementPeriod: propMeasurementPeriod } = props;
  const [showModal, setShowModal] = useState(false);
  const [measurementPeriod, setMeasurementPeriod] =
    useState<PaymentStatusResponse>(propMeasurementPeriod);

  const afterSaved = (editedMeasurementPeriod: PaymentStatusResponse): void => {
    setMeasurementPeriod(editedMeasurementPeriod);
    setShowModal(false);
    window.Alert.success("入金状態の変更に成功しました");
  };

  return (
    <>
      {showModal && (
        <PaymentStatusEditModal
          measurementPeriod={measurementPeriod}
          onClose={() => setShowModal(false)}
          afterSaved={afterSaved}
        />
      )}

      <div className="bg-gray-200 p-2 flex justify-between items-center">
        <div>入金状態</div>
        <div>
          {!["paid_by_credit_card", "paid_by_individual"].includes(
            measurementPeriod.payment_status
          ) && (
            <div>
              <Button
                title="編集"
                className="admin-button px-3 py-1"
                onClick={() => setShowModal(true)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="ml-2 text-sm text-gray-700">
        <div className="mt-2 py-2 border-b flex">
          <div className="ml-2">
            {window.I18n.t(
              `enums.measurement_period.payment_status.${measurementPeriod.payment_status}`
            )}
          </div>
        </div>
      </div>
    </>
  );
}
