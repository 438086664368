import React, { ReactElement, useState } from "react";
import { PaymentStatusResponse } from "../../../models/MeasurementPeriodApi";
import PaymentStatusListItem from "./PaymentStatusListItem";

interface Props {
  measurementPeriods: PaymentStatusResponse[];
}

export default function PaymentStatusList(props: Props): ReactElement {
  const { measurementPeriods: propMeasurementPeriods } = props;
  const [measurementPeriods, setMeasurementPeriods] = useState<
    PaymentStatusResponse[]
  >(propMeasurementPeriods);

  const updateMeasurementPeriod = (
    index: number,
    mp: PaymentStatusResponse
  ): void => {
    const arr = measurementPeriods.concat();
    arr[index] = Object.assign({}, measurementPeriods[index], mp);
    setMeasurementPeriods(arr);
    window.Alert.success("入金状態の変更に成功しました");
  };

  return (
    <div className="overflow-x-scroll">
      <table className="w-full" style={{ minWidth: 1000 }}>
        <thead>
          <tr>
            <th className="text-left px-4 py-2">診断期間</th>
            <th className="text-left px-4 py-2">名前</th>
            <th className="text-left px-4 py-2">請求金額</th>
            <th className="text-left px-4 py-2">状態</th>
            <th className="text-left px-4 py-2">決済担当者名</th>
            <th className="text-left px-4 py-2">決済担当者メールアドレス</th>
            <th className="w-24 py-2"></th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {measurementPeriods.map((mp, i) => (
            <PaymentStatusListItem
              measurementPeriod={mp}
              key={mp.id}
              afterSaved={(editedMeasurementPeriod) =>
                updateMeasurementPeriod(i, editedMeasurementPeriod)
              }
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
