import React, { ReactElement } from "react";
import { Line } from "react-chartjs-2";

const options = {
  elements: {
    line: {
      cubicInterpolationMode: "monotone",
    },
  },
  maintainAspectRatio: false,
  legend: {
    display: false,
    position: "top",
    onClick: () => {
      return false;
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          min: 0,
          max: 40,
          stepSize: 5,
          callback: (value, index, values) => {
            return `${value}%`;
          },
        },
      },
    ],
  },
};

interface Props {
  title: string;
}

export default function MindStateFactorDistributionItem(
  props: Props
): ReactElement {
  const { title } = props;
  return (
    <>
      <div className="p-2">
        <div className="text-center text-sm">{title}</div>
        <div className="flex justify-around mt-2">
          <div className="flex justify-center items-center">
            <div
              className="w-10"
              style={{ backgroundColor: "#F5A300", height: 2 }}
            />
            <div className="text-xs text-gray-600 ml-1">サンプル全体</div>
          </div>
          <div className="flex justify-center items-center">
            <div
              className="w-10 h-3"
              style={{ backgroundColor: "rgba(255, 99, 132, 1)" }}
            />
            <div className="text-xs text-gray-600 ml-1">貴団体</div>
          </div>
        </div>
        <div className="mt-2">
          <Line
            height={200}
            data={{
              labels: ["", "", "", "", "", "", "", "", "", "", "", ""],
              datasets: [
                {
                  type: "line",
                  label: "サンプル全体",
                  data: [null, 0, 0, 0, 0, 5, 13, 19, 30, 28, 17, null],
                  borderColor: "#F5A300",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderWidth: 2,
                  pointRadius: 0,
                },
                {
                  type: "bar",
                  label: "貴団体",
                  data: [null, 2, 0, 0, 0, 2, 8, 19, 30, 35, 31, null],
                  borderColor: "rgba(255, 99, 132, 1)",
                  backgroundColor: "rgba(255, 99, 132, 1)",
                  // borderWidth: 2,
                  // pointRadius: 0,
                },
              ],
            }}
            options={options}
          />
        </div>
        <div className="flex justify-between items-center text-xs ml-16 mr-5 -mt-3">
          <div>0</div>
          <div className="flex-grow">
            <div className="border-b border-gray-700 mx-3" />
          </div>
          <div>10</div>
        </div>
      </div>
    </>
  );
}
