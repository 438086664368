import React, { ReactElement } from "react";
import { HorizontalBar } from "react-chartjs-2";
import SubHeader from "../../shared/SubHeader";

const options = {
  indexAxis: "y",
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
          min: 0,
          max: 100,
          stepSize: 20,
          callback: (value, index, values) => {
            return `${value}%`;
          },
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
      },
    ],
  },
  fill: false,
};

export default function RespondentDistribution(): ReactElement {
  return (
    <>
      <SubHeader title="回答者の分布" />
      <div className="flex flex-row justify-center flex-wrap my-3">
        <div className="p-2">
          <div className="text-center text-sm">ポジション</div>
          <div className="">
            <HorizontalBar
              height={70}
              width={300}
              data={{
                labels: [""],
                datasets: [
                  {
                    // barThickness: 10,
                    label: "代表・副代表",
                    data: [12],
                    borderColor: "#FEFF02",
                    backgroundColor: "#FEFF02",
                  },
                  {
                    // barThickness: 10,
                    label: "理事・幹事",
                    data: [31],
                    borderColor: "#F5A300",
                    backgroundColor: "#F5A300",
                  },
                  {
                    // barThickness: 10,
                    label: "運営会議メンバー",
                    data: [57],
                    borderColor: "#92D050",
                    backgroundColor: "#92D050",
                  },
                ],
              }}
              options={options}
            />
          </div>
          <div className="flex justify-center mt-2">
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="border font-normal text-xs px-2"></th>
                  <th className="border font-normal text-xs px-2">
                    回答者の割合
                  </th>
                </tr>
              </thead>
              <tbody>
                {[
                  {
                    title: "代表・副代表",
                    rate: 12,
                    color: "#FEFF02",
                  },
                  { title: "理事・幹事", rate: 31, color: "#F5A300" },
                  { title: "運営会議メンバー", rate: 57, color: "#92D050" },
                ].map((d) => (
                  <tr key={d.title}>
                    <td className="border text-xs  px-2">
                      <div className="flex items-center">
                        <div
                          className="h-3 w-3 rounded"
                          style={{ backgroundColor: d.color }}
                        />
                        <div className="ml-2">{d.title}</div>
                      </div>
                    </td>
                    <td className="border text-xs text-center">{d.rate}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="p-2">
          <div className="text-center text-sm">報酬</div>
          <div className="">
            <HorizontalBar
              height={70}
              width={300}
              data={{
                labels: [""],
                datasets: [
                  {
                    label: "あり",
                    data: [23],
                    borderColor: "#FEFF02",
                    backgroundColor: "#FEFF02",
                  },
                  {
                    label: "なし",
                    data: [77],
                    borderColor: "#F5A300",
                    backgroundColor: "#F5A300",
                  },
                  {
                    label: "わからない",
                    data: [0],
                    borderColor: "#92D050",
                    backgroundColor: "#92D050",
                  },
                ],
              }}
              options={options}
            />
          </div>
          <div className="flex justify-center mt-2">
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="border font-normal text-xs px-2"></th>
                  <th className="border font-normal text-xs px-2">
                    回答者の割合
                  </th>
                </tr>
              </thead>
              <tbody>
                {[
                  { title: "あり", rate: 23, color: "#FEFF02" },
                  { title: "なし", rate: 77, color: "#F5A300" },
                  { title: "わからない", rate: 0, color: "#92D050" },
                ].map((d) => (
                  <tr key={d.title}>
                    <td className="border text-xs  px-2">
                      <div className="flex items-center">
                        <div
                          className="h-3 w-3 rounded"
                          style={{ backgroundColor: d.color }}
                        />
                        <div className="ml-2">{d.title}</div>
                      </div>
                    </td>
                    <td className="border text-xs text-center">{d.rate}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-center flex-wrap my-3">
        <div className="p-2">
          <div className="text-center text-sm">平均活動時間(一ヶ月)</div>
          <div className="">
            <HorizontalBar
              height={70}
              width={300}
              data={{
                labels: [""],
                datasets: [
                  {
                    label: "10時間未満",
                    data: [12],
                    borderColor: "#FEFF02",
                    backgroundColor: "#FEFF02",
                  },
                  {
                    label: "10時間以上〜30時間未満",
                    data: [35],
                    borderColor: "#F5A300",
                    backgroundColor: "#F5A300",
                  },
                  {
                    label: "30時間以上〜50時間未満",
                    data: [34],
                    borderColor: "#92D050",
                    backgroundColor: "#92D050",
                  },
                  {
                    label: "50時間以上〜100時間未満",
                    data: [7],
                    borderColor: "#2CB0F0",
                    backgroundColor: "#2CB0F0",
                  },
                  {
                    label: "100時間以上",
                    data: [12],
                    borderColor: "#B9CDE5",
                    backgroundColor: "#B9CDE5",
                  },
                ],
              }}
              options={options}
            />
          </div>
          <div className="flex justify-center mt-2">
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="border font-normal text-xs px-2"></th>
                  <th className="border font-normal text-xs px-2">
                    回答者の割合
                  </th>
                </tr>
              </thead>
              <tbody>
                {[
                  { title: "10時間未満", rate: 12, color: "#FEFF02" },
                  {
                    title: "10時間以上〜30時間未満",
                    rate: 35,
                    color: "#F5A300",
                  },
                  {
                    title: "30時間以上〜50時間未満",
                    rate: 34,
                    color: "#92D050",
                  },
                  {
                    title: "50時間以上〜100時間未満",
                    rate: 7,
                    color: "#2CB0F0",
                  },
                  { title: "100時間以上", rate: 12, color: "#B9CDE5" },
                ].map((d) => (
                  <tr key={d.title}>
                    <td className="border text-xs  px-2">
                      <div className="flex items-center">
                        <div
                          className="h-3 w-3 rounded"
                          style={{ backgroundColor: d.color }}
                        />
                        <div className="ml-2">{d.title}</div>
                      </div>
                    </td>
                    <td className="border text-xs text-center">{d.rate}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="p-2">
          <div className="text-center text-sm">活動歴</div>
          <div className="">
            <HorizontalBar
              height={70}
              width={300}
              data={{
                labels: [""],
                datasets: [
                  {
                    label: "3ヶ月未満",
                    data: [8],
                    borderColor: "#FEFF02",
                    backgroundColor: "#FEFF02",
                  },
                  {
                    label: "3ヶ月以上〜6ヶ月未満",
                    data: [15],
                    borderColor: "#F5A300",
                    backgroundColor: "#F5A300",
                  },
                  {
                    label: "6ヶ月以上〜1年未満",
                    data: [42],
                    borderColor: "#92D050",
                    backgroundColor: "#92D050",
                  },
                  {
                    label: "1年以上〜3年未満",
                    data: [12],
                    borderColor: "#2CB0F0",
                    backgroundColor: "#2CB0F0",
                  },
                  {
                    label: "3年以上",
                    data: [23],
                    borderColor: "#B9CDE5",
                    backgroundColor: "#B9CDE5",
                  },
                ],
              }}
              options={options}
            />
          </div>
          <div className="flex justify-center mt-2">
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="border font-normal text-xs px-2"></th>
                  <th className="border font-normal text-xs px-2">
                    回答者の割合
                  </th>
                </tr>
              </thead>
              <tbody>
                {[
                  { title: "3ヶ月未満", rate: 8, color: "#FEFF02" },
                  {
                    title: "3ヶ月以上〜6ヶ月未満",
                    rate: 15,
                    color: "#F5A300",
                  },
                  {
                    title: "6ヶ月以上〜1年未満",
                    rate: 42,
                    color: "#92D050",
                  },
                  {
                    title: "1年以上〜3年未満",
                    rate: 12,
                    color: "#2CB0F0",
                  },
                  { title: "3年以上", rate: 23, color: "#B9CDE5" },
                ].map((d) => (
                  <tr key={d.title}>
                    <td className="border text-xs  px-2">
                      <div className="flex items-center">
                        <div
                          className="h-3 w-3 rounded"
                          style={{ backgroundColor: d.color }}
                        />
                        <div className="ml-2">{d.title}</div>
                      </div>
                    </td>
                    <td className="border text-xs text-center">{d.rate}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
