import React, { ReactElement } from "react";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortEndHandler,
} from "react-sortable-hoc";
import { QuestionIndexResponse } from "../../models/QuestionApi";
import SortableQuestionItem from "./SortableQuestionItem";

interface Props {
  questions: QuestionIndexResponse[];
  onSortEnd: SortEndHandler;
}

export default function SortableQuestionList(props: Props): ReactElement {
  const { questions, onSortEnd } = props;

  const SortableItem = SortableElement<{
    question: QuestionIndexResponse;
    position: number;
  }>(({ question, position }) => (
    <SortableQuestionItem key={question.id} question={question} />
  ));

  const SortableList = SortableContainer<{ items: QuestionIndexResponse[] }>(
    ({ items }) => (
      <ul className="">
        {items.map((item, index) => (
          <SortableItem
            key={item.id}
            question={item}
            index={index}
            position={index}
            // disabled={!canSort}
          />
        ))}
      </ul>
    )
  );
  return (
    <SortableList
      items={questions}
      onSortEnd={onSortEnd}
      // helperClass="question-list__list-container"
      distance={1}
      lockAxis="y"
    />
  );
}
