import React, { ReactElement } from "react";
import MultiLineText from "../shared/MultiLineText";

interface Props {
  num: number;
  title: string | string[];
}

export default function QuestionnaireLabel(props: Props): ReactElement {
  const { num, title } = props;

  if (Array.isArray(title)) {
    return (
      <h4 className="px-3 py-2 -mx-3 bg-secondary text-over-secondary">
        <div className="flex">
          <div>{`Q${num}.`}</div>
          <div className="pl-2">
            {title.map((t) => (
              <div key={t}>{t}</div>
            ))}
          </div>
        </div>
      </h4>
    );
  }

  return (
    <h4 className="px-3 py-2 -mx-3 bg-secondary text-over-secondary">
      <div className="flex">
        <div>{`Q${num}.`}</div>
        <div className="pl-2">
          <MultiLineText text={title} />
        </div>
      </div>
    </h4>
  );
}
