import React, { ReactElement, useState } from "react";
import TextForm from "../../shared/forms/TextForm";
import EmailForm from "../../shared/forms/EmailForm";
import SelectForm from "../../shared/forms/SelectForm";
import CheckboxesForm from "../../shared/forms/CheckboxesForm";
import CheckboxWithTextForm from "../../shared/forms/CheckboxWithTextForm";
import BaseForm from "../../shared/BaseForm";
import CitySelectForm from "../../cities/CitySelectForm";
import { CompanyOverviewResponse } from "../../../models/CompanyApi";
import { ActivityFieldResponse } from "../../../models/ActivityField";

interface Props {
  company: CompanyOverviewResponse;
  annualIncomeOptions: Array<{ key: string; value: string }>;
  numberOfEmployeesOptions: Array<{ key: string; value: string }>;
  numberOfMembersOptions: Array<{ key: string; value: string }>;
  prefectureOptions: Array<{ key: string; value: string }>;
  establishmentYearOptions: Array<{ key: string; value: string }>;
  activityFields: ActivityFieldResponse[];
}

export default function CompanyInformationForm(props: Props): ReactElement {
  const {
    company,
    annualIncomeOptions,
    numberOfEmployeesOptions,
    numberOfMembersOptions,
    prefectureOptions,
    establishmentYearOptions,
    activityFields,
  } = props;
  const [prefectureCode, setPrefectureCode] = useState<
    number | undefined | null
  >(company.prefecture_code);

  return (
    <BaseForm
      name="CompanyInformationForm"
      action={`/admin/companies/${company.id}`}
      method="put"
      type="normal"
    >
      <div className="bg-gray-200 p-2 flex justify-between items-center">
        <div>団体基本情報</div>
        <div>
          <div>
            <button type="submit" className="admin-button px-3 py-1">
              更新
            </button>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <TextForm
          model={company}
          scope="company"
          columnName="representative_name"
        />
        <TextForm
          model={company}
          scope="company"
          columnName="location"
          placeholder="例: 東京都品川区"
        />
        <EmailForm
          model={company}
          scope="company"
          columnName="email"
          placeholder="xxx@xxx.xx"
        />
        <TextForm
          model={company}
          scope="company"
          columnName="home_page"
          placeholder="https://〜"
        />
        <SelectForm
          model={company}
          scope="company"
          columnName="establishment_year"
          option={{ include_blank: "-- 選択してください --" }}
          options={establishmentYearOptions}
        />
        <CheckboxesForm
          scope="company"
          relatedModelName="activity_field_companies"
          columnName="activity_field_id"
          label="主な活動分野(複数選択可)"
          options={activityFields.map((af) => ({
            relatedModelId: company.activity_field_companies.find(
              (afc) => afc.activity_field_id === af.id
            )?.id,
            activeKey: String(af.id),
            title: af.name,
            disableKey: "",
            defaultChecked: company.activity_field_companies.some(
              (afc) => afc.activity_field_id === af.id
            ),
          }))}
        />
        <CheckboxWithTextForm
          model={company}
          scope="company"
          columnName="activity_field_free_entry"
          title="その他"
          defaultChecked={!!company.activity_field_free_entry}
          placeholder="主な活動分野 自由記入欄"
        />
        <SelectForm
          model={company}
          scope="company"
          columnName="prefecture_code"
          option={{ include_blank: "-- 選択してください --" }}
          options={prefectureOptions}
          onChange={(e) => setPrefectureCode(Number(e.target.value))}
        />
        {prefectureCode && (
          <CitySelectForm
            model={company}
            scope="company"
            prefectureCode={prefectureCode}
          />
        )}
        <SelectForm
          model={company}
          scope="company"
          columnName="annual_income"
          option={{ include_blank: "-- 選択してください --" }}
          options={annualIncomeOptions}
        />
        <SelectForm
          model={company}
          scope="company"
          columnName="number_of_members"
          option={{ include_blank: "-- 選択してください --" }}
          options={numberOfMembersOptions}
        />
        <SelectForm
          model={company}
          scope="company"
          columnName="number_of_employees"
          option={{ include_blank: "-- 選択してください --" }}
          options={numberOfEmployeesOptions}
        />
        <SelectForm
          model={company}
          scope="company"
          columnName="phone_number_tracing_flg"
          options={[
            { key: "false", value: "なし" },
            { key: "true", value: "あり" },
          ]}
          required
        />
        <SelectForm
          model={company}
          scope="company"
          columnName="additional_question_flg"
          options={[
            { key: "false", value: "無効" },
            { key: "true", value: "有効" },
          ]}
          required
        />
      </div>
    </BaseForm>
  );
}
