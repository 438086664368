import React, { ReactElement } from "react";
import { Bar } from "react-chartjs-2";
import AttributeScoreCell from "../shared/AttributeScoreCell";
const tableCell = "border px-1 text-xs text-center whitespace-no-wrap";
interface Props {
  options: any;
}

export default function ReportItemSpecificFactorScore3(
  props: Props
): ReactElement {
  const { options } = props;
  return (
    <>
      <h5 className="text-center text-sm">
        団体内での平均活動時間による
        <br />
        因子得点平均値の比較
      </h5>
      <div>
        <div className="p-2">
          <Bar
            height={200}
            width={350}
            data={{
              labels: ["理念共感と貢献意欲", "自己有用感", "居心地の良さ"],
              datasets: [
                {
                  // barThickness: 10,
                  label: "10時間未満",
                  data: [8.89, 6.94, 7.78],
                  borderColor: "#FEFF02",
                  backgroundColor: "#FEFF02",
                },
                {
                  // barThickness: 10,
                  label: "10時間以上〜30時間未満",
                  data: [9.4, 5.97, 8.24],
                  borderColor: "#F5A300",
                  backgroundColor: "#F5A300",
                },
                {
                  // barThickness: 10,
                  label: "30時間以上〜50時間未満",
                  data: [9.58, 5.83, 8.75],
                  borderColor: "#92D050",
                  backgroundColor: "#92D050",
                },
                {
                  // barThickness: 10,
                  label: "50時間以上〜100時間未満",
                  data: [9.25, 5.17, 7.42],
                  borderColor: "#2CB0F0",
                  backgroundColor: "#2CB0F0",
                },
                {
                  // barThickness: 10,
                  label: "100時間以上",
                  data: [9.03, 4.31, 8.19],
                  borderColor: "#B9CDE5",
                  backgroundColor: "#B9CDE5",
                },
              ],
            }}
            options={options}
          />
        </div>
        <div>
          <div className="px-3">
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="border font-normal text-xs px-2"></th>
                  <th className="border font-normal text-xs px-2">
                    理念共感と貢献意欲
                  </th>
                  <th className="border font-normal text-xs px-2">
                    自己有用感
                  </th>
                  <th className="border font-normal text-xs px-2">
                    居心地の良さ
                  </th>
                </tr>
              </thead>
              <tbody>
                {[
                  {
                    title: "10時間未満",
                    color: "#FEFF02",
                    point1: 8.89,
                    point2: 6.94,
                    point3: 7.78,
                  },
                  {
                    title: "10時間以上〜30時間未満",
                    color: "#F5A300",
                    point1: 9.4,
                    point2: 5.97,
                    point3: 8.24,
                  },
                  {
                    title: "30時間以上〜50時間未満",
                    color: "#92D050",
                    point1: 9.58,
                    point2: 5.83,
                    point3: 8.75,
                  },
                  {
                    title: "50時間以上〜100時間未満",
                    color: "#2CB0F0",
                    point1: 9.25,
                    point2: 5.17,
                    point3: 7.42,
                  },
                  {
                    title: "100時間以上",
                    color: "#B9CDE5",
                    point1: 9.03,
                    point2: 4.31,
                    point3: 8.19,
                  },
                ].map((d) => (
                  <tr key={d.title}>
                    <td className="border px-1 text-xs">
                      <div className="flex items-center">
                        <div
                          className="rounded h-3 w-3"
                          style={{ backgroundColor: d.color }}
                        />
                        <div className="ml-2">{d.title}</div>
                      </div>
                    </td>
                    <AttributeScoreCell
                      answeredCount={10}
                      point={d.point1}
                      averagePoint={9.31}
                      className={tableCell}
                    />
                    <AttributeScoreCell
                      answeredCount={10}
                      point={d.point2}
                      averagePoint={5.71}
                      className={tableCell}
                    />
                    <AttributeScoreCell
                      answeredCount={10}
                      point={d.point3}
                      averagePoint={8.14}
                      className={tableCell}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
