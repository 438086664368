import React, { ReactElement, useState } from "react";
import Button from "../../shared/Button";
import AdminUserEditModal from "./AdminUserEditModal";
import { AdminUserResponse } from "../../../models/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

interface Props {
  user: AdminUserResponse;
  afterSaved: (user: AdminUserResponse) => void;
  afterDeleted: () => void;
  afterResendMail: (user: AdminUserResponse) => void;
}

export default function AdminListItem(props: Props): ReactElement {
  const { user, afterSaved, afterDeleted, afterResendMail } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <AdminUserEditModal
          user={user}
          onClose={() => setShowModal(false)}
          afterSaved={(u) => {
            setShowModal(false);
            afterSaved(u);
          }}
          afterDeleted={() => {
            setShowModal(false);
            afterDeleted();
          }}
          afterResendMail={(u) => {
            setShowModal(false);
            afterResendMail(u);
          }}
        />
      )}
      <tr className="border-t py-2">
        <td className="text-center py-3">{user.id}</td>
        <td className="text-left px-4 py-2">{user.name}</td>
        <td className="text-left px-4 py-2">
          {user.is_confirmed ? "有効" : "メールアドレス確認中"}
        </td>
        <td className="text-right">
          <Button
            onClick={() => setShowModal(true)}
            className="text-sm opacity-50 p-2"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </td>
      </tr>
    </>
  );
}
