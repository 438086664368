import React, { ReactElement } from "react";
import MindStateFactorTable from "../shared/MindStateFactorTable";
import SubHeader from "../../shared/SubHeader";
import { QuestionnaireSummaryResponse } from "../../../models/QuestionnaireSummary";
import { CategoryDistributionResponse } from "../../../models/CategoryDistribution";
import CategoryDistributionItem from "../../category_distributions/CategoryDistributionItem";
import { MeasurementPeriodSummaryResponse } from "../../../models/MeasurementPeriodSummary";

interface Props {
  allQuestionnaireSummary: QuestionnaireSummaryResponse;
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
  categoryDistribution: CategoryDistributionResponse;
}

export default function MindStateFactorDistribution(
  props: Props
): ReactElement {
  const {
    allQuestionnaireSummary,
    measurementPeriodSummary,
    categoryDistribution,
  } = props;

  return (
    <>
      <div>
        <SubHeader title="心の健康状態" />
        <div className="mt-3 p-3 bg-quinary text-sm text-tertiary">
          コミュニティキャピタル診断では３因子が高い人ほど主観的健康感、主観的幸福感がとても高くなる傾向が観察されています。
          <br />
          また心の健康状態を多面的にとらえるために、抑うつ傾向の低さ、孤独感の低さ、自己肯定感に関する簡易的な測定も行っています。
          <br />
          <br />
          いずれの指標も数字が大きくなるほど状態が良好であることを意味します。
          <br />
          <br />
          <div>
            {[
              {
                title: "抑うつ傾向の低さ",
                description:
                  "私たちの気分は様々な原因で落ち込むことがあります。点数が低い人ほど、気分が落ち込んでいる可能性があります。",
              },
              {
                title: "孤独感の低さ",
                description:
                  "他人との接触が少なかったり、他人とあっていても理解をしてもらえなかったりすれば寂しい、孤独であると感じることがあります。点数が低い人ほど、寂しい、孤独であるという感情が強い可能性があります。",
              },
              {
                title: "自己肯定感",
                description:
                  "自分自身を肯定する感覚です。自信や自分に対する満足感が高い人ほど点数が高くなります。",
              },
            ].map((o) => (
              <div key={o.title} className="flex flex-wrap mt-2">
                <div className="font-bold mr-2">{`${o.title}:`}</div>
                <div>{o.description}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-4">
          <div className="flex justify-center">
            <MindStateFactorTable
              data={[
                "health_perception",
                "well_being",
                "depressive_tendency",
                "loneliness",
                "self_affirmation",
              ].map(
                (
                  k
                ): {
                  factorName: string;
                  point: number | null;
                  answeredCount: number;
                  averagePoint: number;
                  deviation: number | null;
                } => ({
                  factorName: window.I18n.t(
                    `enums.community_capital_question.category.${k}`
                  ),
                  point: measurementPeriodSummary[`${k}_point`],
                  answeredCount:
                    measurementPeriodSummary[`${k}_answered_count`],
                  averagePoint: allQuestionnaireSummary[`${k}_point`],
                  deviation: measurementPeriodSummary[`${k}_deviation_value`],
                })
              )}
            />
          </div>
          <div className="flex flex-row justify-center items-center flex-wrap my-3">
            <CategoryDistributionItem
              title="主観的健康感"
              allDistribution={
                allQuestionnaireSummary.health_perception_distribution
              }
              distribution={categoryDistribution.health_perception_distribution}
            />
            <CategoryDistributionItem
              title="主観的幸福感"
              allDistribution={allQuestionnaireSummary.well_being_distribution}
              distribution={categoryDistribution.well_being_distribution}
            />
            <CategoryDistributionItem
              title="抑うつ傾向の低さ"
              allDistribution={
                allQuestionnaireSummary.depressive_tendency_distribution
              }
              distribution={
                categoryDistribution.depressive_tendency_distribution
              }
            />
            <CategoryDistributionItem
              title="孤独感の低さ"
              allDistribution={allQuestionnaireSummary.loneliness_distribution}
              distribution={categoryDistribution.loneliness_distribution}
            />
            <CategoryDistributionItem
              title="自己肯定感"
              allDistribution={
                allQuestionnaireSummary.self_affirmation_distribution
              }
              distribution={categoryDistribution.self_affirmation_distribution}
            />
          </div>
          <div className="text-center text-xs text-gray-600 my-3">
            ※各得点の範囲内にいる人数の割合分布図です。横軸は因子得点、縦軸は各得点の人数の割合です。
          </div>
        </div>
        <SubHeader title="マインドセット" />
        <div className="mt-3 p-3 bg-quinary text-sm text-tertiary">
          ・新質問ではコミュニティとの良好な関係を作れる人の特徴を明らかにするために、メンバーの下記の５つのマインドセットを測定しています。
          <div>
            {[
              {
                title: "多様性受容",
                description: "自分と異なる人を受け入れることができるか。",
              },
              {
                title: "レジリエンス",
                description:
                  "困難があってもくじけずに乗り越えることができるか。",
              },
              {
                title: "社会的信頼",
                description:
                  "社会の中の他者を互いに助け合る人たちとして信頼しているかどうか。値がが高い人ほど他者を信頼しています。",
              },
              {
                title: "ポジティブサム思考",
                description:
                  "ゼロサム思考（成功の陰には必ず誰かの犠牲が必要だとする思考）と対立する考え方。共存共栄が可能だと考えている度合い。",
              },
              {
                title: "成長思考",
                description:
                  "自分の努力によって能力や性格も変えられるという考え方 ⇄ 固定思考（能力も性格も生まれつき決まっているという考え方）",
              },
              {
                title: "自己効力感",
                description:
                  "一般的な自己効力感ではなく、未来は自分の力で変えられるという考え方。",
              },
              {
                title: "人間関係思考",
                description: "人間関係を重要と捉えているかどうか。",
              },
            ].map((o) => (
              <div key={o.title} className="flex flex-wrap mt-2">
                <div className="font-bold mr-2">{`${o.title}:`}</div>
                <div>{o.description}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-4">
          <div className="flex justify-center">
            <MindStateFactorTable
              data={[
                "diversity_acceptance",
                "resilience",
                "social_trust",
                "positive_sum_thinking",
                "growth_mindset",
                "self_efficacy",
                "human_relations_thinking",
              ].map(
                (
                  k
                ): {
                  factorName: string;
                  point: number | null;
                  answeredCount: number;
                  averagePoint: number;
                  deviation: number | null;
                } => ({
                  factorName: window.I18n.t(
                    `enums.community_capital_question.category.${k}`
                  ),
                  point: measurementPeriodSummary[`${k}_point`],
                  answeredCount:
                    measurementPeriodSummary[`${k}_answered_count`],
                  averagePoint: allQuestionnaireSummary[`${k}_point`],
                  deviation: measurementPeriodSummary[`${k}_deviation_value`],
                })
              )}
            />
          </div>
          <div className="flex flex-row justify-center items-center flex-wrap my-3">
            <CategoryDistributionItem
              title="多様性受容"
              allDistribution={
                allQuestionnaireSummary.diversity_acceptance_distribution
              }
              distribution={
                categoryDistribution.diversity_acceptance_distribution
              }
            />
            <CategoryDistributionItem
              title="レジリエンス"
              allDistribution={allQuestionnaireSummary.resilience_distribution}
              distribution={categoryDistribution.resilience_distribution}
            />
            <CategoryDistributionItem
              title="社会的信頼"
              allDistribution={
                allQuestionnaireSummary.social_trust_distribution
              }
              distribution={categoryDistribution.social_trust_distribution}
            />
          </div>
          <div className="flex flex-row justify-center items-center flex-wrap my-3">
            <CategoryDistributionItem
              title="ポジティブサム思考"
              allDistribution={
                allQuestionnaireSummary.positive_sum_thinking_distribution
              }
              distribution={
                categoryDistribution.positive_sum_thinking_distribution
              }
            />
            <CategoryDistributionItem
              title="成長思考"
              allDistribution={
                allQuestionnaireSummary.growth_mindset_distribution
              }
              distribution={categoryDistribution.growth_mindset_distribution}
            />
          </div>
          <div className="flex flex-row justify-center items-center flex-wrap my-3">
            <CategoryDistributionItem
              title="自己効力感"
              allDistribution={
                allQuestionnaireSummary.self_efficacy_distribution
              }
              distribution={categoryDistribution.self_efficacy_distribution}
            />
            <CategoryDistributionItem
              title="人間関係思考"
              allDistribution={
                allQuestionnaireSummary.human_relations_thinking_distribution
              }
              distribution={
                categoryDistribution.human_relations_thinking_distribution
              }
            />
          </div>
          <div className="text-center text-xs text-gray-600 my-3">
            ※各得点の範囲内にいる人数の割合分布図です。横軸は因子得点、縦軸は各得点の人数の割合です。
          </div>
        </div>
      </div>
    </>
  );
}
