import React, { ReactElement, useState } from "react";
import BaseMoal from "../../../../shared/BaseModal";
import Button from "../../../../shared/Button";
import { deleteRequest } from "../../../../../utils/ApiClient";
import { DepartmentNode } from "../../../../../models/Department";
import BaseModal from "../../../../shared/BaseModal";
import { CompanyResponse } from "../../../../../models/Company";
import { getMilliseconds } from "date-fns";
import { Props as FlushMessageProps } from "../../../../shared/FlushMessage";
import { MeasurementPeriodResponse } from "../../../../../models/MeasurementPeriod";

interface Props {
  company: CompanyResponse;
  measurementPeriod: MeasurementPeriodResponse;
  node: DepartmentNode;
  onCreationSelected: () => void;
  onEditSelected: () => void;
  onCloseButtonClicked: () => void;
  beforeSave: () => void;
  afterSave: (nodePath?: number[]) => void;
}

export default function MenuModalBody(props: Props): ReactElement {
  const {
    company,
    measurementPeriod,
    node,
    onCreationSelected,
    onEditSelected,
    onCloseButtonClicked,
    beforeSave,
    afterSave,
  } = props;
  const [flushMessage, setFlushMessage] = useState<FlushMessageProps>();

  const destroy = async (): Promise<void> => {
    if (node.children.length > 0) {
      if (
        !window.confirm(
          `当該診断期間で部署「${node.name}」とその配下の全ての部署を削除してよろしいですか？\n(他の診断期間の部署は削除されません)`
        )
      ) {
        return;
      }
    } else {
      if (
        !window.confirm(
          `当該診断期間で部署「${node.name}」を削除してよろしいですか？\n(他の診断期間の部署は削除されません)`
        )
      ) {
        return;
      }
    }
    beforeSave();
    try {
      const r = await deleteRequest<{ node_path: number[] }>(
        `/admin/companies/${company.id}/measurement_periods/${measurementPeriod.id}/departments/${node.id}`
      );
      const { node_path } = r.data;
      afterSave(node_path);
    } catch (error) {
      if (error) {
        setFlushMessage({
          title: "エラーが発生しました",
          messages: error.data.messages,
          milliseconds: getMilliseconds(new Date()),
        });
      }
      afterSave();
    }
  };
  return (
    <>
      <BaseMoal.Body flushMessage={flushMessage}>
        <div>
          <div className="flex justify-around flex-wrap">
            <div className="w-full md:w-1/2 p-1">
              <Button
                title="配下に新しい部署を作成"
                className="p-3 border rounded bg-gray-200"
                onClick={onCreationSelected}
              />
            </div>
            <div className="w-full md:w-1/2 p-1">
              <Button
                title="編集"
                className="p-3 border rounded bg-gray-200"
                onClick={onEditSelected}
              />
            </div>
          </div>
        </div>
      </BaseMoal.Body>
      <BaseMoal.Footer className="flex justify-between items-center">
        <Button
          title="削除"
          className="text-gray-600 p-2 text-sm"
          onClick={destroy}
        />
        <Button
          className="admin-button px-3 py-1"
          onClick={onCloseButtonClicked}
          title="閉じる"
        />
      </BaseMoal.Footer>
    </>
  );
}
