import React, { ReactElement, useState } from "react";
import { AdminUserResponse } from "../../../models/User";
import AdminListItem from "./AdminListItem";
import AdminUserAddButton from "./AdminUserAddButton";

interface Props {
  users: AdminUserResponse[];
}

export default function AdminList(props: Props): ReactElement {
  const { users: propUsers } = props;
  const [users, setUsers] = useState(propUsers);

  const addUser = (newUser: AdminUserResponse): void => {
    setUsers([newUser].concat(users.filter((u) => u.id !== newUser.id)));
    window.Alert.success(`${newUser.name}さんを運営メンバーに追加しました`);
  };

  const updateUser = (index: number, u: AdminUserResponse): void => {
    const arr = users.concat();
    arr[index] = Object.assign({}, users[index], u);
    setUsers(arr);
  };

  const deleteUser = (index: number, u: AdminUserResponse): void => {
    const arr = users.concat();
    arr.splice(index, 1);
    setUsers(arr);
    window.Alert.success(`${u.name}さんを運営メンバーから削除しました`);
  };

  return (
    <>
      <div className="flex justify-end">
        <AdminUserAddButton afterSaved={addUser} />
      </div>
      <table className="table-fixed w-full">
        <thead>
          <tr>
            <th className="text-center w-10 py-2">ID</th>
            <th className="text-left px-4 py-2">名前</th>
            <th className="text-left px-4 py-2">状態</th>
            <th className="w-24 py-2"></th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {users.map((u, i) => (
            <AdminListItem
              user={u}
              key={u.id}
              afterSaved={(u) => {
                updateUser(i, u);
                window.Alert.success(
                  `${u.name}さんのメンバー情報の変更に成功しました`
                );
              }}
              afterDeleted={() => deleteUser(i, u)}
              afterResendMail={(u) => {
                updateUser(i, u);
                window.Alert.success(`${u.name}さんに招待メールを送信しました`);
              }}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}
