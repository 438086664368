import React, { ChangeEvent, ReactElement } from "react";
import SelectField from "./fields/SelectField";
import { fieldId, fieldName, i18nKey } from "../../../utils/AttributeUtils";
import { errorMessages } from "./utils/MessageUtils";
import { isAdmin } from "../../../utils/NavigationUtils";

interface Props {
  model: any;
  scope: string;
  columnName: string;
  relatedModelName?: string;
  containerClassName?: string;
  options: Array<{ key: string; value: string }>;
  option?: { include_blank: string };
  label?: string;
  labelClassName?: string;
  requiredClassName?: string;
  index?: number;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  selectedKey?: string;
  defaultKey?: string;
  disabled?: boolean;
  hideLabel?: boolean;
  required?: boolean;
  message?: string;
  showBottomBorder?: boolean;
  fieldClassName?: string;
  fieldContainerClassName?: string;
  fieldWrapContainerClassName?: string;
}

export default function SelectForm(props: Props): ReactElement {
  const {
    model,
    scope,
    relatedModelName = "",
    columnName,
    options,
    option,
    containerClassName = isAdmin
      ? "flex flex-wrap items-center py-1"
      : "flex flex-wrap items-center mt-3",
    labelClassName = isAdmin
      ? "w-full md:w-1/4 pt-2 px-2 md:p-2 text-gray-600 text-sm flex items-center"
      : "w-full md:w-1/4 pt-2 px-2 md:p-2 text-sm flex items-center flex-wrap font-bold",
    requiredClassName = isAdmin
      ? "ml-1 rounded px-1 text-red-600 text-xs"
      : "ml-1 px-1 text-red-600 text-xs leading-loose",
    index,
    onChange,
    defaultKey = "",
    disabled,
    hideLabel = false,
    selectedKey,
    required = false,
    message,
    label = "",
    showBottomBorder = false,
    fieldContainerClassName,
    fieldClassName = isAdmin ? "w-full border p-2 rounded bg-white" : undefined,
    fieldWrapContainerClassName: propFieldWrapContainerClassName = isAdmin
      ? undefined
      : "w-full",
  } = props;

  let fieldWrapContainerClassName = "w-full md:w-3/4";
  if (propFieldWrapContainerClassName) {
    fieldWrapContainerClassName = propFieldWrapContainerClassName;
  } else if (hideLabel) {
    fieldWrapContainerClassName = "w-full";
  }

  const viewLabel = (): string => {
    if (label !== "") {
      return label;
    }
    return window.I18n.t(i18nKey(scope, relatedModelName, columnName));
  };

  const fieldKey = (): string => {
    if (selectedKey !== undefined) {
      return selectedKey;
    } else if (model[columnName] !== undefined) {
      return model[columnName];
    }
    return defaultKey;
  };

  return (
    <div>
      <div
        className={`${containerClassName} ${
          showBottomBorder ? "border-b border-gray-300" : ""
        }`}
      >
        {!hideLabel && (
          <label
            className={labelClassName}
            htmlFor={fieldId(scope, relatedModelName, columnName, index)}
          >
            {viewLabel()}
            {required && <div className={requiredClassName}>必須</div>}
          </label>
        )}
        <div className={fieldWrapContainerClassName}>
          <SelectField
            id={fieldId(scope, relatedModelName, columnName, index)}
            name={fieldName(scope, relatedModelName, columnName, index)}
            containerClass={fieldContainerClassName}
            className={fieldClassName}
            options={options}
            selectedKey={fieldKey()}
            option={option}
            onChange={(e) => {
              if (onChange !== undefined) {
                onChange(e);
              }
            }}
            disabled={disabled}
            required={required}
          />
          {message !== undefined && (
            <div className="color__gray font__size-13">{message}</div>
          )}
        </div>
      </div>
    </div>
  );
}
