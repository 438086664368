export const colors = [
  "#FEFF02",
  "#F5A300",
  "#92D050",
  "#2CB0F0",
  "#B9CDE5",
  "#9013FE",
  "#8B572A",
  "#FF4259",
  "#417505",
  "#0063D6",
  "#F4BCFF",
];
