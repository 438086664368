import React, { ReactElement, useState } from "react";
import SelectForm from "../../shared/forms/SelectForm";
import HiddenForm from "../../shared/forms/HiddenForm";
import { QuestionnaireEditType } from "../../../models/Questionnaire";
import { CommunityCapitalQuestionResponse } from "../../../models/CommunityCapitalQuestion";

interface Props {
  questionnaire: QuestionnaireEditType;
  communityCapitalQuestion: CommunityCapitalQuestionResponse;
  questionIndex: number;
  showForm: boolean;
}

export default function HealthConditionSelectForm(props: Props): ReactElement {
  const { showForm, questionnaire, communityCapitalQuestion, questionIndex } =
    props;
  const communityCapitalAnswer = questionnaire.community_capital_answers.find(
    (a) => a.community_capital_question_id === communityCapitalQuestion.id
  );
  const [value, setValue] = useState(
    String(communityCapitalAnswer?.point || "")
  );
  return (
    <>
      {!showForm && value && (
        <>
          <HiddenForm
            scope="questionnaire"
            relatedModelName="community_capital_answers"
            index={questionIndex}
            columnName="community_capital_question_id"
            value={communityCapitalQuestion.id}
          />
          <HiddenForm
            scope="questionnaire"
            relatedModelName="community_capital_answers"
            index={questionIndex}
            columnName="point"
            value={value}
          />
        </>
      )}
      {showForm && (
        <>
          <HiddenForm
            scope="questionnaire"
            relatedModelName="community_capital_answers"
            index={questionIndex}
            columnName="community_capital_question_id"
            value={communityCapitalQuestion.id}
          />
          <SelectForm
            model={questionnaire}
            scope="questionnaire"
            relatedModelName="community_capital_answers"
            index={questionIndex}
            columnName="point"
            option={{ include_blank: "-- 選択してください --" }}
            options={[
              { key: "4", value: "よい" },
              { key: "3", value: "まあよい" },
              { key: "2", value: "ふつう" },
              { key: "1", value: "あまりよくない" },
              { key: "0", value: "よくない" },
            ]}
            hideLabel
            defaultKey=""
            selectedKey={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </>
      )}
    </>
  );
}
