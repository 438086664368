import React, { ReactElement, useState } from "react";
import { animateScroll } from "react-scroll";
import MultiLineText from "../../shared/MultiLineText";
import HiddenForm from "../../shared/forms/HiddenForm";
import { QuestionnaireEditType } from "../../../models/Questionnaire";
import { CommunityCapitalQuestionResponse } from "../../../models/CommunityCapitalQuestion";
import { CommunityCapitalAnswerEditType } from "../../../models/CommunityCapitalAnswer";

interface Props {
  showForm: boolean;
  questionnaire: QuestionnaireEditType;
  communityCapitalQuestion: CommunityCapitalQuestionResponse;
  questionIndex: number;
  onInputComplete?: () => void;
}

const options = [
  { key: "1", value: "よく\nあてはまる" },
  { key: "2", value: "あてはまる" },
  { key: "3", value: "やや\nあてはまる" },
  { key: "4", value: "あまり\nあてはまらない" },
  { key: "5", value: "あてはまらない" },
  { key: "6", value: "まったく\nあてはまらない" },
];

function scrollMore() {
  if (window.innerWidth < 753) {
    animateScroll.scrollMore(150, { duration: 500 });
  } else {
    animateScroll.scrollMore(142, { duration: 500 });
  }
}

export default function SixPointScaleQuestionItem(props: Props): ReactElement {
  const {
    showForm,
    questionnaire,
    communityCapitalQuestion,
    questionIndex,
    onInputComplete,
  } = props;
  let initialKey = "";
  const communityCapitalAnswer: CommunityCapitalAnswerEditType | undefined =
    questionnaire.community_capital_answers.filter(
      (a) => a.community_capital_question_id === communityCapitalQuestion.id
    )[0];
  if (communityCapitalAnswer) {
    initialKey = String(communityCapitalAnswer.point);
  }
  const [selectedKey, setSelectedKey] = useState<string>(initialKey);

  return (
    <>
      {selectedKey && (
        <>
          <HiddenForm
            scope="questionnaire"
            relatedModelName="community_capital_answers"
            index={questionIndex}
            columnName="point"
            value={selectedKey}
          />
          <HiddenForm
            scope="questionnaire"
            relatedModelName="community_capital_answers"
            index={questionIndex}
            columnName="community_capital_question_id"
            value={communityCapitalQuestion.id}
          />
        </>
      )}
      {showForm && (
        <div className="mt-4">
          <div className="font-bold mt-2">{communityCapitalQuestion.body}</div>
          <div className="flex justify-center mt-2">
            <div className="w-full md:w-4/5 flex justify-around">
              {options.map((o) => (
                <div
                  key={o.key}
                  className="w-1/6 py-1"
                  style={{ paddingLeft: 1, paddingRight: 1 }}
                >
                  <div
                    className={`border rounded cursor-pointer ${
                      selectedKey && selectedKey === o.key
                        ? "bg-secondary border-yellow-300"
                        : "bg-white"
                    }`}
                    onClick={() => {
                      setSelectedKey(o.key);
                      onInputComplete && onInputComplete();
                      scrollMore();
                    }}
                  >
                    <div className="flex justify-center items-center mt-2">
                      <div
                        className={`border-4 rounded-full h-8 w-8 ${
                          selectedKey && selectedKey === o.key
                            ? "border-yellow-300"
                            : ""
                        }`}
                      />
                    </div>
                    <div
                      className={`flex justify-center items-center text-center mt-1 h-12 md:h-10 text-tiny md:text-xs ${
                        selectedKey && selectedKey === o.key ? "text-white" : ""
                      }`}
                    >
                      <MultiLineText text={o.value} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
