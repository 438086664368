import React, { useState, ReactElement } from "react";
import BaseModal from "./BaseModal";
import EmailRegistModalContent from "./EmailRegistModal/EmailRegistModalContent";
import IntegrateModalContent from "./EmailRegistModal/IntegrateModalContent";
import { TempEmailRegistUserResponse } from "../../models/User";
import Button from "./Button";
import MultiLineText from "./MultiLineText";

interface Props {
  title: string;
  description: string;
}

export default function PopupDescriptionButton(props: Props): ReactElement {
  const { title, description } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <BaseModal
          show={true}
          title={title}
          contentLabel="CompanyAdminEdit"
          onCloseModal={() => setShowModal(false)}
        >
          <BaseModal.Body>
            <div>
              <MultiLineText text={description} />
            </div>
          </BaseModal.Body>
        </BaseModal>
      )}
      <Button
        className="sub-button px-3 py-1"
        onClick={() => setShowModal(true)}
        title={title}
      />
    </>
  );
}
