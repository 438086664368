import React, { CSSProperties, ReactElement, ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
}

export default function BaseModalFooter(props: Props): ReactElement {
  const { children, className = "" } = props;
  return (
    <div className={`p-2 border-t bg-white rounded-b ${className}`}>
      {children}
    </div>
  );
}
