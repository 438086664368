import React, { ReactElement, useState } from "react";
import { format, parseISO } from "date-fns";
import Button from "../../shared/Button";
import PaymentStatusEditModal from "./PaymentStatusEditModal";
import { PaymentStatusResponse } from "../../../models/MeasurementPeriodApi";

interface Props {
  measurementPeriod: PaymentStatusResponse;
  afterSaved: (editedMeasurementPeriod: PaymentStatusResponse) => void;
}

export default function PaymentStatusListItem(props: Props): ReactElement {
  const { measurementPeriod, afterSaved } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <PaymentStatusEditModal
          measurementPeriod={measurementPeriod}
          onClose={() => setShowModal(false)}
          afterSaved={(mp) => {
            setShowModal(false);
            afterSaved(mp);
          }}
        />
      )}
      <tr className="border-t py-2">
        <td className="text-left px-4 py-2">
          {format(parseISO(measurementPeriod.start_date), "yyyy年MM月dd日")}〜
          {format(parseISO(measurementPeriod.end_date), "yyyy年MM月dd日")}
        </td>
        <td className="text-left px-4 py-2">
          {measurementPeriod.company.name}
        </td>
        <td className="text-left px-4 py-2">
          {measurementPeriod.billing_amount}
        </td>
        <td className="text-left px-4 py-2">
          {window.I18n.t(
            `enums.measurement_period.payment_status.${measurementPeriod.payment_status}`
          )}
        </td>
        <td className="text-left px-4 py-2">
          {measurementPeriod.payment_staff_name}
        </td>
        <td className="text-left px-4 py-2 break-all">
          {measurementPeriod.payment_staff_email}
        </td>
        <td className="text-right">
          {!["paid_by_credit_card", "paid_by_individual"].includes(
            measurementPeriod.payment_status
          ) && (
            <Button
              title="編集"
              className="admin-button px-3 py-1"
              onClick={() => setShowModal(true)}
            />
          )}
        </td>
      </tr>
    </>
  );
}
