import React, { ReactElement } from "react";
import { Line } from "react-chartjs-2";
import { toPercent } from "../../models/CategoryDistribution";
import MultiLineText from "../shared/MultiLineText";

const options = {
  elements: {
    line: {
      cubicInterpolationMode: "monotone",
    },
  },
  maintainAspectRatio: false,
  legend: {
    display: false,
    position: "top",
    onClick: () => {
      return false;
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          min: 0,
          stepSize: 5,
          callback: (value, index, values) => {
            return `${value}%`;
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      title: function (tooltipItem, data) {
        return "";
      },
      label: function (tooltipItem, data) {
        console.log(tooltipItem);
        let label = data.datasets[tooltipItem.datasetIndex].label || "";

        if (label) {
          label += ": ";
        }
        console.log(label);
        label += Math.round(tooltipItem.yLabel * 100) / 100;
        return `${label}%`;
      },
    },
  },
};

interface Props {
  title: string;
  allDistribution: number[];
  distribution: number[];
  height?: number;
  width?: number;
  legendTitle?: string;
  minAxisTitle?: string;
  maxAxisTitle?: string;
}

const firstElement: Array<{ x: string; y: number | null }> = [
  { x: "first", y: null },
];
const endElement: Array<{ x: string; y: number | null }> = [
  { x: "end", y: null },
];

export default function CategoryDistributionItem(props: Props): ReactElement {
  const {
    title,
    allDistribution,
    distribution,
    height = 200,
    width,
    legendTitle = "貴団体",
    minAxisTitle = "0",
    maxAxisTitle = "10",
  } = props;

  return (
    <>
      <div className="p-2">
        <div className="text-center text-sm">
          <MultiLineText text={title} />
        </div>
        <div className="flex justify-around mt-2">
          <div className="flex justify-center items-center">
            <div
              className="w-10"
              style={{ backgroundColor: "#F5A300", height: 2 }}
            />
            <div className="text-xs text-gray-600 ml-1">サンプル全体</div>
          </div>
          <div className="flex justify-center items-center">
            <div
              className="w-10 h-3"
              style={{ backgroundColor: "rgba(255, 99, 132, 1)" }}
            />
            <div className="text-xs text-gray-600 ml-1">{legendTitle}</div>
          </div>
        </div>
        <div className="mt-2">
          <Line
            height={height}
            width={width}
            data={{
              labels: [
                "first",
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "end",
              ],
              datasets: [
                {
                  type: "line",
                  label: "サンプル全体",
                  data: firstElement
                    .concat(
                      toPercent(allDistribution)
                        .map((v, i) => ({
                          x: String(i),
                          y: v,
                        }))
                        .filter((o) => o.y > 0)
                    )
                    .concat(endElement),
                  borderColor: "#F5A300",
                  backgroundColor: "rgba(0, 0, 0, 0)",
                  borderWidth: 2,
                  pointRadius: 0,
                },
                {
                  type: "bar",
                  label: legendTitle,
                  data: firstElement
                    .concat(
                      toPercent(distribution)
                        .map((v, i) => ({
                          x: String(i),
                          y: v,
                        }))
                        .filter((o) => o.y > 0)
                    )
                    .concat(endElement),
                  borderColor: "rgba(255, 99, 132, 1)",
                  backgroundColor: "rgba(255, 99, 132, 1)",
                  // borderWidth: 2,
                  // pointRadius: 0,
                },
              ],
            }}
            options={options}
          />
        </div>
        <div className="flex justify-between items-center text-xs ml-16 mr-5">
          <div>{minAxisTitle}</div>
          <div className="flex-grow">
            <div className="border-b border-gray-700 mx-3" />
          </div>
          <div>{maxAxisTitle}</div>
        </div>
      </div>
    </>
  );
}
