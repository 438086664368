import React, { ReactElement, useState } from "react";
import TreePointIcon from "./images/TreePointIcon.png";
import Button from "./Button";

interface Props {
  data: Array<{
    title: string;
    path: string;
  }>;
}

export default function MenuLink(props: Props): ReactElement {
  const { data } = props;
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <div className="flex relative">
        <Button
          className="cursor-pointer"
          onClick={() => setShowMenu(!showMenu)}
        >
          <img src={TreePointIcon} alt="menu icon" className="h-5" />
        </Button>
        {showMenu && (
          <>
            <div className="fixed inset-0" onClick={() => setShowMenu(false)} />
            <div
              className="bg-white px-4 py-1 absolute right-0 border whitespace-no-wrap"
              style={{ top: 30 }}
            >
              {data.map((d, i) => (
                <Button
                  key={d.title}
                  className={`p-2 text-sm ${
                    i < data.length - 1 ? "border-b" : ""
                  }`}
                  title={d.title}
                  onClick={() => (window.location.href = d.path)}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}
