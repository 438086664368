import React, { ReactElement } from "react";
import SelectForm from "../../shared/forms/SelectForm";
import ManagerialPositionListForm from "../managerial_positions/ManagerialPositionListForm";
import BaseForm from "../../shared/BaseForm";
import { MeasurementPeriodEditType } from "../../../models/MeasurementPeriod";
import { CompanyResponse } from "../../../models/Company";
import MeasurementCountForm from "./MeasurementCountForm";

interface Props {
  company: CompanyResponse;
  measurementPeriod: MeasurementPeriodEditType;
}

export default function MeasurementPeriodBaseInfoEditForm(
  props: Props
): ReactElement {
  const { company, measurementPeriod } = props;
  return (
    <BaseForm
      action={`/admin/companies/${company.id}/measurement_periods/${measurementPeriod.id}`}
      name="MeasurementInfoEditForm"
      method="put"
      type="normal"
    >
      <div className="bg-gray-200 p-2 flex justify-between items-center">
        <div>診断基本情報</div>
        <div>
          <input
            type="submit"
            value="更新"
            className="admin-button px-3 py-1 cursor-pointer"
            data-disable-with="更新中.."
            // disabled
          />
        </div>
      </div>
      <div className="ml-2">
        <MeasurementCountForm measurementPeriod={measurementPeriod} />
        <ManagerialPositionListForm measurementPeriod={measurementPeriod} />
        <SelectForm
          model={measurementPeriod}
          scope="measurement_period"
          columnName="mindset_question_flg"
          options={[
            { key: "true", value: "利用する" },
            { key: "false", value: "利用しない" },
          ]}
          label="マインドセット質問利用有無"
          showBottomBorder
        />
      </div>
    </BaseForm>
  );
}
