import React, { ChangeEvent, ReactElement } from "react";
import EmailField from "./fields/EmailField";
import { fieldId, fieldName, i18nKey } from "../../../utils/AttributeUtils";
import { errorMessages } from "./utils/MessageUtils";
import { isAdmin } from "../../../utils/NavigationUtils";

interface Props {
  model: any;
  scope: string;
  relatedModelName?: string;
  columnName: string;
  placeholder?: string;
  containerClassName?: string;
  fieldWrapContainerClassName?: string;
  className?: string;
  index?: number;
  disabled?: boolean;
  hideLabel?: boolean;
  required?: boolean;
  value?: string;
  readOnly?: boolean;
  label?: string;
  labelClassName?: string;
  showBottomBorder?: boolean;
  onChange?: (text: string) => void;
  fucus?: boolean;
}

export default function EmailForm(props: Props): ReactElement {
  const {
    model,
    scope,
    relatedModelName,
    columnName,
    placeholder,
    index,
    containerClassName = isAdmin
      ? "flex flex-wrap items-center"
      : "flex flex-wrap items-center mt-3",
    fieldWrapContainerClassName: propFieldWrapContainerClassName = isAdmin
      ? undefined
      : "w-full",
    className = isAdmin
      ? "w-full border rounded p-2"
      : "w-full border-2 border-quaternary placeholder-quaternary rounded-full px-4 py-2",
    required = false,
    disabled = false,
    value,
    readOnly = false,
    hideLabel = false,
    label = "",
    labelClassName = isAdmin
      ? "w-full md:w-1/4 pt-2 px-2 md:p-2 text-gray-600 text-sm flex items-center"
      : "w-full md:w-1/4 pt-2 px-2 md:p-2 text-sm flex items-center flex-wrap font-bold",
    showBottomBorder = false,
    onChange,
    fucus = false,
  } = props;

  let fieldWrapContainerClassName = "w-full md:w-3/4";
  if (propFieldWrapContainerClassName) {
    fieldWrapContainerClassName = propFieldWrapContainerClassName;
  } else if (hideLabel) {
    fieldWrapContainerClassName = "w-full";
  }

  const viewLabel = (): string => {
    if (label !== "") {
      return label;
    }
    return window.I18n.t(i18nKey(scope, relatedModelName, columnName));
  };

  return (
    <div
      className={`${containerClassName} ${
        showBottomBorder ? "border-b border-gray-300" : ""
      }`}
    >
      {!hideLabel && (
        <label
          className={labelClassName}
          htmlFor={fieldId(scope, relatedModelName, columnName, index)}
        >
          {viewLabel()}
          {required && (
            <div className="ml-1 rounded px-1 text-red-600 text-xs">必須</div>
          )}
        </label>
      )}
      <div className={fieldWrapContainerClassName}>
        <EmailField
          id={fieldId(scope, relatedModelName, columnName, index)}
          name={fieldName(scope, relatedModelName, columnName, index)}
          value={value || (model[columnName] as string)}
          className={className}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          onChange={(t) => onChange && onChange(t)}
          fucus={fucus}
        />
        {errorMessages(model, columnName, viewLabel()).map((m, i) => (
          <div key={m} className="invalid-feedback d-block">
            <span>{m}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
