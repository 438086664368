import React, { ReactElement } from "react";
import { Radar } from "react-chartjs-2";
import SubHeader from "../shared/SubHeader";
import { QuestionnaireSummaryResponse } from "../../models/QuestionnaireSummary";
import { MeasurementPeriodSummaryResponse } from "../../models/MeasurementPeriodSummary";
import { CategoryDistributionResponse } from "../../models/CategoryDistribution";
import { ManagerialPositionResponse } from "../../models/ManagerialPosition";
import { QuestionnaireResponse } from "../../models/Questionnaire";
import { QuestionnaireOverviewResponse } from "../../models/QuestionnaireApi";
import { filterNotEmpty } from "../../utils/ArrayUtils";

const options = (min: number, max: number) => {
  return {
    plugins: {
      legend: {
        position: "top",
        onClick: () => {
          return false;
        },
      },
    },
    scale: {
      ticks: {
        min: min < 40 ? min - 10 : 40,
        max: max > 60 ? max + 10 : 60,
        stepSize: 5,
      },
    },
    fill: false,
  };
};

interface Props {
  questionnaire: QuestionnaireOverviewResponse;
}

export default function PersonalMindStateFactorScore(
  props: Props
): ReactElement {
  const { questionnaire } = props;

  const healthStateDeviationValues = [
    questionnaire.depressive_tendency_deviation_value,
    questionnaire.loneliness_deviation_value,
    questionnaire.self_affirmation_deviation_value,
    questionnaire.health_perception_deviation_value,
    questionnaire.well_being_deviation_value,
  ];

  return (
    <>
      <SubHeader title="こころの健康状態" />
      <div className="p-2 bg-quinary mt-2">
        <p className="text-sm">
          団体活動はこころの健康状態と相互に関連しています。
          <br />
          こころが健康でなければ、団体活動をする意欲もわいてこないでしょう。
          <br />
          そして、自分の望むような団体活動ができていれば心の健康には良い影響があります。
          <br />
          団体とのかかわり方を異なる角度からみるために、コミュニティキャピタル診断ではこころの健康についても測定しています。
        </p>
      </div>
      <div className="flex flex-row justify-center items-center my-3">
        <div>
          <Radar
            height={350}
            width={350}
            data={{
              labels: [
                "抑うつ傾向の低さ",
                "孤独感の低さ",
                "自己肯定感",
                "主観的健康感",
                "主観的幸福感",
              ],
              datasets: [
                {
                  label: "あなたの結果",
                  data: healthStateDeviationValues,
                  fill: false,
                  borderColor: "rgba(255, 99, 132, 1)",
                  backgroundColor: "rgba(255, 99, 132, 1)",
                  borderWidth: 2,
                },
                {
                  label: "サンプル全体",
                  data: [50, 50, 50, 50, 50],
                  fill: false,
                  borderColor: "#F5A300",
                  backgroundColor: "#F5A300",
                  borderWidth: 2,
                },
              ],
            }}
            options={options(
              Math.min(...filterNotEmpty(healthStateDeviationValues)),
              Math.max(...filterNotEmpty(healthStateDeviationValues))
            )}
          />
        </div>
      </div>
      <div className="mt-3 text-sm text-gray-600 text-center">
        ※全回答者の平均値を50として正規化した値をレーダーチャートで表示しています。
      </div>
      <div className="mt-3 text-sm text-gray-600 text-center">
        ※診断の質問を飛ばして回答した場合は、結果が表示されないことがあります。
      </div>
    </>
  );
}
