import React, { ReactElement, useState } from "react";
import CheckboxForm from "./CheckboxForm";
import { fieldId, i18nKey } from "../../../utils/AttributeUtils";
import { isAdmin } from "../../../utils/NavigationUtils";
import TextForm from "./TextForm";

interface Props {
  model: any;
  scope: string;
  columnName: string;
  relatedModelName?: string;
  index?: number;
  title: string;
  defaultChecked?: boolean;
  containerClassName?: string;
  fieldWrapContainerClassName?: string;
  hideLabel?: boolean;
  placeholder?: string;
}

export default function CheckboxWithTextForm(props: Props): ReactElement {
  const {
    model,
    scope,
    relatedModelName,
    index,
    columnName,
    title,
    defaultChecked = false,
    containerClassName = isAdmin
      ? "flex flex-wrap items-center"
      : "flex flex-wrap items-center mt-3",
    fieldWrapContainerClassName: propFieldWrapContainerClassName = isAdmin
      ? undefined
      : "w-full p-2",
    hideLabel,
    placeholder,
  } = props;

  const [showTextField, setShowTextField] = useState(defaultChecked);

  let fieldWrapContainerClassName = "w-full md:w-3/4";
  if (propFieldWrapContainerClassName) {
    fieldWrapContainerClassName = propFieldWrapContainerClassName;
  } else if (hideLabel) {
    fieldWrapContainerClassName = "w-full";
  }

  return (
    <>
      <div className={containerClassName}>
        <div className={fieldWrapContainerClassName}>
          <CheckboxForm
            title={title}
            scope={scope}
            relatedModelName={relatedModelName}
            columnName={`${columnName}_show_or_not`}
            index={index}
            defaultChecked={defaultChecked}
            onChange={() => setShowTextField(!showTextField)}
          />
          {showTextField && (
            <TextForm
              model={model}
              scope={scope}
              relatedModelName={relatedModelName}
              columnName={columnName}
              index={index}
              hideLabel
              placeholder={placeholder}
            />
          )}
        </div>
      </div>
    </>
  );
}
