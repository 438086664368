export interface MeasurementPeriodAttributesSummaryResponse {
  key: string;
  answered_count: number;
  // 因子ポイント
  empathy_and_willingness_point: number | null;
  self_importance_point: number | null;
  comfort_point: number | null;
  // 因子回答数
  empathy_and_willingness_answered_count: number;
  self_importance_answered_count: number;
  comfort_answered_count: number;
}

export const answerCountPercent = (
  summaries: MeasurementPeriodAttributesSummaryResponse[],
  key: string
): number => {
  if (summaries.length === 0) {
    return 0;
  }

  const s = summaries.find((s) => s.key === key);
  let val = 0;
  if (s) {
    val = (s.answered_count * 100) / totalAnswerCount(summaries);
  }
  return Math.round(val);
};

const totalAnswerCount = (
  summaries: MeasurementPeriodAttributesSummaryResponse[]
) => {
  return summaries.reduce((sum, mps) => sum + mps.answered_count, 0);
};
