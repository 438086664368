import React, { ReactElement } from "react";
import { Bar } from "react-chartjs-2";
import AttributeScoreCell from "../../shared/AttributeScoreCell";

const options = {
  responsive: false,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          min: 2,
          max: 10,
          stepSize: 2,
        },
      },
    ],
  },
};

export default function ItemSpecificFactorScore1(): ReactElement {
  return (
    <>
      <h5 className="p-3 text-center">
        団体内でのポジションによる因子得点平均値の比較
      </h5>
      <div className="flex flex-row justify-center items-center flex-wrap">
        <div className="p-2 w-full lg:w-1/3 flex justify-center">
          <div>
            <Bar
              height={320}
              width={320}
              data={{
                // labels: ["理念共感と貢献意欲", "自己有用感", "居心地の良さ"],
                labels: ["", "", ""],
                datasets: [
                  {
                    // barThickness: 10,
                    label: "代表・副代表",
                    data: [8.89, 6.53, 9.03],
                    borderColor: "#FEFF02",
                    backgroundColor: "#FEFF02",
                  },
                  {
                    // barThickness: 10,
                    label: "理事・幹事",
                    data: [8.23, 5.68, 8.54],
                    borderColor: "#F5A300",
                    backgroundColor: "#F5A300",
                  },
                  {
                    // barThickness: 10,
                    label: "運営会議メンバー",
                    data: [8.42, 5.56, 7.75],
                    borderColor: "#92D050",
                    backgroundColor: "#92D050",
                  },
                ],
              }}
              options={options}
            />
            <div className="flex justify-around text-xs">
              <div>理念共感と貢献意欲</div>
              <div>自己有用感</div>
              <div>居心地の良さ</div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-2/3 flex justify-center">
          <div className="px-3 w-full">
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="border font-normal text-sm px-2"></th>
                  <th className="border font-normal text-sm px-2">
                    理念共感と貢献意欲
                  </th>
                  <th className="border font-normal text-sm px-2">
                    自己有用感
                  </th>
                  <th className="border font-normal text-sm px-2">
                    居心地の良さ
                  </th>
                </tr>
              </thead>
              <tbody>
                {[
                  {
                    title: "代表・副代表",
                    color: "#FEFF02",
                    point1: 8.89,
                    point2: 6.53,
                    point3: 9.03,
                  },
                  {
                    title: "理事・幹事",
                    color: "#F5A300",
                    point1: 8.23,
                    point2: 5.68,
                    point3: 8.54,
                  },
                  {
                    title: "運営会議メンバー",
                    color: "#92D050",
                    point1: 8.42,
                    point2: 5.56,
                    point3: 7.75,
                  },
                ].map((d) => (
                  <tr key={d.title}>
                    <td className="border px-4 py-2 text-sm">
                      <div className="flex items-center">
                        <div
                          className="rounded h-3 w-3"
                          style={{ backgroundColor: d.color }}
                        />
                        <div className="ml-2">{d.title}</div>
                      </div>
                    </td>
                    <AttributeScoreCell
                      answeredCount={10}
                      point={d.point1}
                      averagePoint={9.31}
                      className="border px-2 text-center"
                    />
                    <AttributeScoreCell
                      answeredCount={10}
                      point={d.point2}
                      averagePoint={5.71}
                      className="border px-2 text-center"
                    />
                    <AttributeScoreCell
                      answeredCount={10}
                      point={d.point3}
                      averagePoint={8.14}
                      className="border px-2 text-center"
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
