import React, { ReactElement } from "react";
import ItemSpecificFactorScore1 from "./ItemSpecificFactorScore1";
import ItemSpecificFactorScore2 from "./ItemSpecificFactorScore2";
import ItemSpecificFactorScore3 from "./ItemSpecificFactorScore3";
import ItemSpecificFactorScore4 from "./ItemSpecificFactorScore4";
import SubHeader from "../../shared/SubHeader";

export default function ItemSpecificFactorScores(): ReactElement {
  return (
    <>
      <SubHeader title="項目別因子得点" />
      <div className="my-3">
        <ItemSpecificFactorScore1 />
      </div>
      <div className="my-3">
        <ItemSpecificFactorScore2 />
      </div>
      <div className="my-3">
        <ItemSpecificFactorScore3 />
      </div>
      <div className="my-3">
        <ItemSpecificFactorScore4 />
      </div>
      <div className="text-center p-3 text-xs text-gray-600">
        {/*{ #4BACC6〜#F79646 平均-3 〜 平均 〜 平均+3}*/}
        ※貴団体平均を基準値とし、基準値と同じであれば白色、基準値よりも高い場合はオレンジ色、低い場合は青色で表されています。色が濃いほど基準値より離れた値であることを示しています。（縦に比較してご覧ください）
      </div>
    </>
  );
}
