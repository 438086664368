import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import LoadingImage from "../../shared/images/SpinGray.svg";
import { MeasurementPeriodResponse } from "../../../models/MeasurementPeriod";

interface Props {
  measurementPeriod: MeasurementPeriodResponse;
}

export default function CheckoutForm(props: Props) {
  const { measurementPeriod } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isFormPreparing, setIsFormPreparing] = useState(true);

  const returnUrl = (): string => {
    if (window.ClientSideEnv.HOST == "localhost:3000") {
      return `http://localhost:3000/user/measurement_periods/${measurementPeriod.id}/check_payment_intent`;
    } else {
      return `https://${window.ClientSideEnv.HOST}/user/measurement_periods/${measurementPeriod.id}/check_payment_intent`;
    }
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (!paymentIntent) {
        setMessage("Something went wrong.");
        return;
      }
      switch (paymentIntent.status) {
        case "succeeded":
          // setMessage("Payment succeeded!");
          setMessage("支払いが成功しました!");
          break;
        case "processing":
          // setMessage("Your payment is processing.");
          setMessage("支払いを処理中です");
          break;
        case "requires_payment_method":
          // setMessage("Your payment was not successful, please try again.");
          setMessage(
            "支払いが失敗しました。申し訳ありませんが、もう一度実行してください。"
          );
          break;
        default:
          // setMessage("Something went wrong.");
          setMessage(
            `予期しないエラーが発生しました。\nしばらく時間をおいて再実行するか、「${window.ClientSideEnv.SUPPORT_MAIL_ADDRESS}」までお問い合わせください。`
          );
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl(),
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement
        id="payment-element"
        onReady={() => setIsFormPreparing(false)}
      />
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message" className="text-red-600 font-bold">
          {message}
        </div>
      )}
      {!isFormPreparing && (
        <button
          disabled={isLoading || !stripe || !elements}
          id="submit"
          className={`primary-button w-full py-2 mt-4 ${
            isLoading || !stripe || !elements ? "opacity-75" : ""
          }`}
        >
          <span>支払い</span>
        </button>
      )}
      {(isLoading || !stripe || !elements || isFormPreparing) && (
        <object
          type="image/svg+xml"
          data={LoadingImage}
          width="80"
          height="80"
          title="loading"
          style={{
            position: "absolute",
            top: "calc(50% - 65px)",
            transform: "translate(-50%)",
            left: "50%",
          }}
        />
      )}
    </form>
  );
}
