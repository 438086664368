import React, { ReactElement, ReactNode, useState } from "react";
import QuestionnaireLabel from "./QuestionnaireLabel";
import ManagerialPositionSelectForm from "./QuestionnaireForm/ManagerialPositionSelectForm";
import CommunityCapitalForm from "./QuestionnaireForm/CommunityCapitalForm";
import QuestionnairePropertySelectForm from "./QuestionnaireForm/QuestionnairePropertySelectForm";
import { QuestionnaireEditType } from "../../models/Questionnaire";
import { CommunityCapitalQuestionResponse } from "../../models/CommunityCapitalQuestion";

interface Props {
  questionnaire: QuestionnaireEditType;
  communityCapitalMainQuestions: CommunityCapitalQuestionResponse[];
  averageActivityTimeOptions: Array<{ key: string; value: string }>;
  activityHistoryOptions: Array<{ key: string; value: string }>;
  occupationOptions: Array<{ key: string; value: string }>;
  birthYearOptions: Array<{ key: string; value: string }>;
  prefectureOptions: Array<{ key: string; value: string }>;
  managerialPositionOptions: Array<{ key: string; value: string }>;
}

export default function QuestionnaireDemoForm(props: Props): ReactElement {
  const {
    questionnaire,
    communityCapitalMainQuestions,
    averageActivityTimeOptions,
    activityHistoryOptions,
    occupationOptions,
    birthYearOptions,
    prefectureOptions,
    managerialPositionOptions,
  } = props;

  const formArray: Array<{
    key: string;
    title: string | string[];
    bellowDescription?: string;
    node: ReactNode;
  }> = [];
  formArray.push({
    key: "ManagerialPosition",
    title: "この団体におけるポジション",
    node: (
      <ManagerialPositionSelectForm
        showForm
        questionnaire={questionnaire}
        options={managerialPositionOptions}
      />
    ),
  });
  formArray.push({
    key: "AverageActivityTime",
    title: "この団体での1ヶ月の平均活動時間はどれくらいですか？",
    node: (
      <QuestionnairePropertySelectForm
        showForm
        questionnaire={questionnaire}
        options={averageActivityTimeOptions}
        columnName="average_activity_time"
      />
    ),
  });
  formArray.push({
    key: "ActivityHistory",
    title: "この団体での活動歴はどれくらいですか？",
    bellowDescription:
      "・・・これらの質問の他に、「報酬」や「熱心さ」に関する質問などがあります。",
    node: (
      <QuestionnairePropertySelectForm
        showForm
        questionnaire={questionnaire}
        options={activityHistoryOptions}
        columnName="activity_history"
      />
    ),
  });
  formArray.push({
    key: "CommunityCapital",
    title: [
      "上記の団体に対するあなたの考えや気持ちについてお尋ねします。",
      "各項目について最も当てはまると思うものを一つ選び、該当する欄をチェックして下さい。※必須",
    ],
    bellowDescription: "・・・このような質問が全部で約30個あります。",
    node: (
      <CommunityCapitalForm
        showForm
        mainQuestions={communityCapitalMainQuestions}
        questionnaire={questionnaire}
        onInputComplete={() => console.log("")}
      />
    ),
  });

  formArray.push({
    key: "BirthYear",
    title: "あなたの生まれた年をお知らせください。",
    node: (
      <QuestionnairePropertySelectForm
        showForm
        questionnaire={questionnaire}
        options={birthYearOptions}
        columnName="birth_year"
      />
    ),
  });
  formArray.push({
    key: "Occupation",
    title: "職業",
    node: (
      <QuestionnairePropertySelectForm
        showForm
        questionnaire={questionnaire}
        options={occupationOptions}
        columnName="occupation"
      />
    ),
  });
  formArray.push({
    key: "Prefecture",
    title: "お住まいの都道府県名",
    bellowDescription:
      "・・・この他に、「性別」「学歴」「年収」「婚姻状況」などの質問があります。",
    node: (
      <QuestionnairePropertySelectForm
        showForm
        questionnaire={questionnaire}
        options={prefectureOptions}
        columnName="prefecture_code"
      />
    ),
  });

  return (
    <>
      <div className="flex justify-center mt-4">
        <div className="max-w-2xl text-sm bg-quinary">
          <p className="p-4">
            このアンケートは、コミュニティ・組織に対するメンバーの意識を様々な角度から調査することによってその団体・組織の状態を数値化し、より良い組織づくりに役立てることを目的としています。
          </p>
          <p className="p-4">
            無記名で実施し、結果は統計的に処理されますので、個人のプライバシーがもれることは決してありません。
          </p>
          <p className="p-4">
            【注意事項】
            <br />
            ・深く考え込まず、“直感で”お答えください。
            <br />
            ・同じような質問が出てきますが、気にせず全てにお答えください。
            <br />
            ・答えにくい質問については、飛ばして構いません。
          </p>
        </div>
      </div>
      {formArray.map((f, i) => {
        return (
          <React.Fragment key={f.key}>
            <div className="mt-6">
              <QuestionnaireLabel num={i + 1} title={f.title} />
            </div>
            <div className="mt-4">{f.node}</div>
            {f.bellowDescription !== undefined && (
              <div className="text-sm mt-4">{f.bellowDescription}</div>
            )}
          </React.Fragment>
        );
      })}
      <div className="mt-16">
        <div className="max-w-xs mx-auto">
          <button
            type="submit"
            id="submit"
            className="shine-btn cursor-pointer w-full"
          >
            <span>送信</span>
          </button>
        </div>
      </div>
    </>
  );
}
