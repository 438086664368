import React, { ReactElement, useState } from "react";
import "@nosferatu500/react-sortable-tree/style.css";
import SortableTree, {
  toggleExpandedForAll,
  map,
  addNodeUnderParent,
  removeNodeAtPath,
  insertNode,
} from "@nosferatu500/react-sortable-tree";
import { CompanyResponse } from "../../../models/Company";
import { DepartmentResponse, DepartmentNode } from "../../../models/Department";
import MenuButton from "./DepartmentEditList/MenuButton";
import CreateButton from "./DepartmentEditList/CreateButton";
import { MeasurementPeriodResponse } from "../../../models/MeasurementPeriod";
import Button from "../../shared/Button";

interface TreeDataType {
  id: number;
  title: ReactElement;
  children?: TreeDataType[];
}

interface DepartmentHierarchyPropType {
  id: number;
  name: string;
  expanded: boolean;
  children?: DepartmentHierarchyPropType[];
}

interface Props {
  company: CompanyResponse;
  measurementPeriod: MeasurementPeriodResponse;
  departmentHierarchies: DepartmentHierarchyPropType[];
}

function setLinkTitle(treeData: DepartmentHierarchyPropType[]): TreeDataType[] {
  return map({
    treeData,
    callback: ({ node }) => ({
      ...node,
      title: node.name,
    }),
    getNodeKey: ({ node }) => String(node.id),
    ignoreCollapsed: false,
  });
}

function editNodeInTree(
  treeData: TreeDataType[],
  department: DepartmentResponse
) {
  return map({
    treeData,
    callback: ({ node }) => {
      if (node.id === department.id) {
        return {
          ...node,
          name: department.name,
          title: department.name,
        };
      }
      return node;
    },
    getNodeKey: ({ node }) => String(node.id),
    ignoreCollapsed: false,
  });
}

function addNodeInTree(
  treeData: TreeDataType[],
  department: DepartmentResponse,
  isRoot: boolean
) {
  if (isRoot) {
    const { treeData: newTreeData } = insertNode({
      treeData,
      depth: 0,
      minimumTreeIndex: 0,
      newNode: {
        children: [],
        expanded: true,
        id: department.id,
        name: department.name,
        title: department.name,
      },
      getNodeKey: ({ node }) => String(node.id),
      ignoreCollapsed: false,
    });
    return newTreeData;
  }
  const { treeData: newTreeData } = addNodeUnderParent({
    treeData,
    newNode: {
      children: [],
      expanded: true,
      id: department.id,
      name: department.name,
      title: department.name,
    },
    parentKey: String(department.parent_id),
    getNodeKey: ({ node }) => String(node.id),
    ignoreCollapsed: false,
    addAsFirstChild: true,
  });
  return newTreeData;
}

function deleteNodeInTree(treeData: TreeDataType[], nodePath: number[]) {
  return removeNodeAtPath({
    treeData,
    path: nodePath.map((id) => String(id)),
    getNodeKey: ({ node }) => String(node.id),
    ignoreCollapsed: false,
  });
}

export default function DepartmentEditList(props: Props): ReactElement {
  const {
    company,
    measurementPeriod,
    departmentHierarchies: propDepartmentHierarchies,
  } = props;

  const [treeData, setTreeData] = useState<TreeDataType[]>(
    setLinkTitle(propDepartmentHierarchies)
  );

  const expandAll = () => {
    const newData = toggleExpandedForAll({
      treeData: treeData,
      expanded: true,
    });
    setTreeData(newData);
  };

  const closeAll = () => {
    const newData = toggleExpandedForAll({
      treeData: treeData,
      expanded: false,
    });
    setTreeData(newData);
  };

  const registNode = (department: DepartmentResponse, isRoot = false) => {
    const newData = addNodeInTree(treeData, department, isRoot);
    setTreeData(newData);
  };

  const editNode = (department: DepartmentResponse) => {
    const newData = editNodeInTree(treeData, department);
    setTreeData(newData);
  };

  const deleteNode = (nodePath: number[]) => {
    const newData = deleteNodeInTree(treeData, nodePath);
    setTreeData(newData);
  };

  if (treeData.length === 0) {
    return (
      <div>
        <div className="flex justify-end p-2 border-b">
          <CreateButton
            company={company}
            measurementPeriod={measurementPeriod}
            afterCreated={(d) => {
              registNode(d, true);
            }}
          />
        </div>
        <div className="text-center p-2">部署は存在しません</div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between p-2 border-b">
        <div className="flex">
          <Button
            onClick={expandAll}
            title="全て展開"
            className="admin-sub-button px-2 py-1 text-sm"
          />
          <Button
            onClick={closeAll}
            title="全て折りたたむ"
            className="admin-sub-button px-2 py-1 text-sm ml-2"
          />
        </div>
        <CreateButton
          company={company}
          measurementPeriod={measurementPeriod}
          afterCreated={(d) => {
            registNode(d, true);
          }}
        />
      </div>
      <div className="department-sortable-tree__container">
        <SortableTree
          getNodeKey={({ node }) => String(node.id)}
          canDrag={false}
          treeData={treeData}
          onChange={(d) => setTreeData(d)}
          generateNodeProps={({ node }: { node: DepartmentNode }) => {
            return {
              buttons: [
                <MenuButton
                  company={company}
                  measurementPeriod={measurementPeriod}
                  node={node}
                  afterCreated={registNode}
                  afterEdited={editNode}
                  afterDeleted={deleteNode}
                />,
              ],
            };
          }}
        />
      </div>
    </div>
  );
}
