import React, { CSSProperties, ReactElement, ReactNode } from "react";

interface Props {
  onClick: () => void;
  className?: string;
  title?: string;
  children?: ReactNode;
  disabled?: boolean;
  style?: CSSProperties;
}

export default function Button(props: Props): ReactElement {
  const {
    onClick,
    className,
    title,
    children,
    disabled = false,
    style = {},
  } = props;
  return (
    <div
      role={disabled ? undefined : "button"}
      tabIndex={0}
      className={`${
        disabled ? "" : "cursor-pointer"
      } flex justify-center items-center ${className}`}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      style={style}
    >
      {title !== undefined && <div>{title}</div>}
      {children !== undefined && <>{children}</>}
    </div>
  );
}
