import React, { ReactElement } from "react";
import { Line } from "react-chartjs-2";

const options = {
  elements: {
    line: {
      cubicInterpolationMode: "monotone",
    },
  },
  maintainAspectRatio: false,
  legend: {
    display: false,
    position: "top",
    onClick: () => {
      return false;
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          min: 0,
          max: 40,
          stepSize: 5,
          callback: (value, index, values) => {
            return `${value}%`;
          },
        },
      },
    ],
  },
};

export default function ReportFactorScoreDistribution(): ReactElement {
  return (
    <div>
      <div className="mt-2 ml-2 p-2 font-bold text-lg">●因子得点の分布</div>
      <div className="flex flex-row justify-around items-center flex-wrap my-3">
        <div className="p-2">
          <div className="text-center text-lg font-bold">
            第１因子
            <br />
            理念共感と貢献意欲
          </div>
          <div className="flex justify-around mt-2">
            <div className="flex justify-center items-center">
              <div
                className="w-10"
                style={{ backgroundColor: "#F5A300", height: 2 }}
              />
              <div className="text-xs text-gray-600 ml-1">サンプル全体</div>
            </div>
            <div className="flex justify-center items-center">
              <div
                className="w-10 h-3"
                style={{ backgroundColor: "rgba(255, 99, 132, 1)" }}
              />
              <div className="text-xs text-gray-600 ml-1">貴団体</div>
            </div>
          </div>
          <div>
            <Line
              height={330}
              width={400}
              data={{
                labels: [
                  "",
                  "0",
                  "",
                  "2",
                  "",
                  "4",
                  "",
                  "6",
                  "",
                  "8",
                  "",
                  "10",
                  "",
                ],
                datasets: [
                  {
                    label: "サンプル全体",
                    data: [null, 0, 0, 2, 3, 5, 14, 15, 23, 17, 21, null],
                    borderColor: "#F5A300",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderWidth: 2,
                    pointRadius: 0,
                  },
                  {
                    type: "bar",
                    label: "貴団体",
                    data: [null, 0, 0, 0, 0, 0, 0, 4, 30, 35, 31, null],
                    borderColor: "rgba(255, 99, 132, 1)",
                    backgroundColor: "rgba(255, 99, 132, 1)",
                    borderWidth: 2,
                    pointRadius: 0,
                  },
                ],
              }}
              options={options}
            />
          </div>
        </div>
        <div className="px-2">
          <div className="text-center text-lg font-bold">
            第２因子
            <br />
            自己有用感
          </div>
          <div className="flex justify-around mt-2">
            <div className="flex justify-center items-center">
              <div
                className="w-10"
                style={{ backgroundColor: "#F5A300", height: 2 }}
              />
              <div className="text-xs text-gray-600 ml-1">サンプル全体</div>
            </div>
            <div className="flex justify-center items-center">
              <div
                className="w-10 h-3"
                style={{ backgroundColor: "rgba(255, 99, 132, 1)" }}
              />
              <div className="text-xs text-gray-600 ml-1">貴団体</div>
            </div>
          </div>
          <div>
            <Line
              height={330}
              width={400}
              data={{
                labels: [
                  "",
                  "0",
                  "",
                  "2",
                  "",
                  "4",
                  "",
                  "6",
                  "",
                  "8",
                  "",
                  "10",
                  "",
                ],
                datasets: [
                  {
                    label: "サンプル全体",
                    data: [null, 3, 5, 7, 13, 15, 18, 15, 10, 7, 6, null],
                    borderColor: "#F5A300",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderWidth: 2,
                    pointRadius: 0,
                  },
                  {
                    type: "bar",
                    label: "貴団体",
                    data: [null, 4, 23, 7, 12, 12, 27, 7, 7, 0, 0, null],
                    borderColor: "rgba(255, 99, 132, 1)",
                    backgroundColor: "rgba(255, 99, 132, 1)",
                    borderWidth: 2,
                    pointRadius: 0,
                  },
                ],
              }}
              options={options}
            />
          </div>
        </div>
        <div className="px-2">
          <div className="text-center text-lg font-bold">
            第３因子
            <br />
            居心地の良さ
          </div>
          <div className="flex justify-around mt-2">
            <div className="flex justify-center items-center">
              <div
                className="w-10"
                style={{ backgroundColor: "#F5A300", height: 2 }}
              />
              <div className="text-xs text-gray-600 ml-1">サンプル全体</div>
            </div>
            <div className="flex justify-center items-center">
              <div
                className="w-10 h-3"
                style={{ backgroundColor: "rgba(255, 99, 132, 1)" }}
              />
              <div className="text-xs text-gray-600 ml-1">貴団体</div>
            </div>
          </div>
          <div>
            <Line
              height={330}
              width={400}
              data={{
                labels: [
                  "",
                  "0",
                  "",
                  "2",
                  "",
                  "4",
                  "",
                  "6",
                  "",
                  "8",
                  "",
                  "10",
                  "",
                ],
                datasets: [
                  {
                    label: "サンプル全体",
                    data: [null, 0, 1, 2, 3, 7, 15, 16, 25, 15, 17, null],
                    borderColor: "#F5A300",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderWidth: 2,
                    pointRadius: 0,
                  },
                  {
                    type: "bar",
                    label: "貴団体",
                    data: [null, 0, 7, 0, 0, 4, 4, 23, 19, 31, 12, null],
                    borderColor: "rgba(255, 99, 132, 1)",
                    backgroundColor: "rgba(255, 99, 132, 1)",
                    borderWidth: 2,
                    pointRadius: 0,
                  },
                ],
              }}
              options={options}
            />
          </div>
        </div>
      </div>
      <div className="text-center text-gray-600 mt-3">
        ※各得点の範囲内にいる人数の割合分布図です。横軸は因子得点、縦軸は各得点の人数の割合です。
      </div>
    </div>
  );
}
