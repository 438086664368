import React, { ReactElement } from "react";
import { Radar } from "react-chartjs-2";
import SubHeader from "../../shared/SubHeader";
import { MeasurementPeriodSummaryResponse } from "../../../models/MeasurementPeriodSummary";
import { QuestionnaireSummaryResponse } from "../../../models/QuestionnaireSummary";

const options = {
  legend: {
    position: "top",
    onClick: () => {
      return false;
    },
  },
  scale: {
    ticks: { min: 35, max: 65, stepSize: 10 },
  },
  fill: false,
};

interface Props {
  allQuestionnaireSummary: QuestionnaireSummaryResponse;
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
}

function graphSize(): number {
  if (window.screen.width < 450) {
    return 300;
  }
  return 400;
}

export default function FactorScore(props: Props): ReactElement {
  const { allQuestionnaireSummary, measurementPeriodSummary } = props;
  return (
    <>
      <SubHeader title="因子得点" />
      <div className="flex flex-row justify-center items-center flex-wrap my-3">
        <div>
          <Radar
            height={graphSize()}
            width={graphSize()}
            data={{
              labels: ["理念共感と貢献意欲", "自己有用感", "居心地の良さ"],
              datasets: [
                {
                  label: "貴団体",
                  data: [
                    measurementPeriodSummary.empathy_and_willingness_deviation_value?.toFixed(
                      1
                    ) || 0.0,
                    measurementPeriodSummary.self_importance_deviation_value?.toFixed(
                      1
                    ) || 0.0,
                    measurementPeriodSummary.comfort_deviation_value?.toFixed(
                      1
                    ) || 0.0,
                  ],
                  fill: false,
                  borderColor: "rgba(255, 99, 132, 1)",
                  backgroundColor: "rgba(255, 99, 132, 1)",
                  borderWidth: 2,
                },
                {
                  label: "サンプル全体",
                  data: [50, 50, 50],
                  fill: false,
                  borderColor: "#F5A300",
                  backgroundColor: "#F5A300",
                  borderWidth: 2,
                },
              ],
            }}
            options={options}
          />
        </div>
        <div>
          <div className="px-3">
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="border font-normal text-sm px-2">因子名称</th>
                  <th className="border font-normal text-sm px-2">貴団体</th>
                  <th className="border font-normal text-sm px-2">
                    サンプル全体
                  </th>
                  <th className="border font-normal text-sm px-2">偏差値</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-4 py-2 text-sm text-center">
                    第１因子
                    <br />
                    理念共感と貢献意欲
                  </td>
                  <td className="border px-2 text-center">
                    {measurementPeriodSummary.empathy_and_willingness_point?.toFixed(
                      2
                    ) || "-"}
                  </td>
                  <td className="border px-2 text-center">
                    {allQuestionnaireSummary.empathy_and_willingness_point.toFixed(
                      2
                    )}
                  </td>
                  <td className="border px-2 text-center">
                    {measurementPeriodSummary.empathy_and_willingness_deviation_value?.toFixed(
                      1
                    ) || "-"}
                  </td>
                </tr>
                <tr className="bg-gray-100">
                  <td className="border px-4 py-2 text-sm text-center">
                    第２因子
                    <br />
                    自己有用感
                  </td>
                  <td className="border px-2 text-center">
                    {measurementPeriodSummary.self_importance_point?.toFixed(
                      2
                    ) || "-"}
                  </td>
                  <td className="border px-2 text-center">
                    {allQuestionnaireSummary.self_importance_point.toFixed(2)}
                  </td>
                  <td className="border px-2 text-center">
                    {measurementPeriodSummary.self_importance_deviation_value?.toFixed(
                      1
                    ) || "-"}
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2 text-sm text-center">
                    第３因子
                    <br />
                    居心地の良さ
                  </td>
                  <td className="border px-2 text-center">
                    {measurementPeriodSummary.comfort_point?.toFixed(2) || "-"}
                  </td>
                  <td className="border px-2 text-center">
                    {allQuestionnaireSummary.comfort_point.toFixed(2)}
                  </td>
                  <td className="border px-2 text-center">
                    {measurementPeriodSummary.comfort_deviation_value?.toFixed(
                      1
                    ) || "-"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="text-xs p-2 bg-quinary text-tertiary">
          （※１）全サンプル平均は、2018年前期から2020年度前期までにコミュニティキャピタル診断を受けた123団体の回答者4784人の平均値です(重複計算を避けるため診断2回目以上の人は除いた回答者数)。
          <br />
          <br />
          （※２）偏差値は、全サンプルの分布（※1参照）に対する偏差値で、次式に従って計算されています。
          <br />
          偏差値＝50＋10×（貴団体平均ー全サンプル平均）／全サンプル標準偏差
        </div>
      </div>
    </>
  );
}
