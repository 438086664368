import React, { ReactElement, useState } from "react";
import QuestionnaireLabel from "../QuestionnaireLabel";
import { QuestionIndexResponse } from "../../../models/QuestionApi";
import AdditionalQuestionSelectForm from "./AdditionalQuestionsForm/AdditionalQuestionSelectForm";
import AdditionalQuestionTextForm from "./AdditionalQuestionsForm/AdditionalQuestionTextForm";
import AdditionalQuestionCheckboxForm from "./AdditionalQuestionsForm/AdditionalQuestionCheckboxForm";
import MultiLineText from "../../shared/MultiLineText";
import { QuestionnaireEditType } from "../../../models/Questionnaire";

interface Props {
  showForm: boolean;
  questionnaire: QuestionnaireEditType;
  question: QuestionIndexResponse;
  index: number;
  onInputComplete?: () => void;
  onInputDelete?: () => void;
}

export default function AdditionalQuestionForm(
  props: Props
): ReactElement | null {
  const {
    showForm,
    question,
    questionnaire,
    index,
    onInputComplete,
    onInputDelete,
  } = props;
  switch (question.answer_format) {
    case "title_format":
      if (!showForm) {
        return null;
      }
      return (
        <div
          key={question.id}
          className="mt-10 px-3 py-2 -mx-3 bg-septenary text-white"
        >
          {question.body}
        </div>
      );
    case "description_format":
      if (!showForm) {
        return null;
      }
      return (
        <div key={question.id} className="mt-6 p-2 text-sm bg-quinary">
          <MultiLineText text={question.body} />
        </div>
      );
    case "text_format":
      return (
        <AdditionalQuestionTextForm
          key={question.id}
          showForm={showForm}
          question={question}
          questionnaire={questionnaire}
          index={index}
          onChange={(v) => {
            if (v) {
              onInputComplete && onInputComplete();
            } else {
              onInputDelete && onInputDelete();
            }
          }}
        />
      );
    case "select_format":
      return (
        <AdditionalQuestionSelectForm
          key={question.id}
          showForm={showForm}
          question={question}
          questionnaire={questionnaire}
          index={index}
          onChange={(v) => {
            if (v) {
              onInputComplete && onInputComplete();
            } else {
              onInputDelete && onInputDelete();
            }
          }}
        />
      );
    case "checkbox_format":
      return (
        <AdditionalQuestionCheckboxForm
          key={question.id}
          showForm={showForm}
          question={question}
          questionnaire={questionnaire}
          index={index}
          onChange={(values) => {
            if (values.length > 0) {
              onInputComplete && onInputComplete();
            } else {
              onInputDelete && onInputDelete();
            }
          }}
        />
      );
    default:
      return null;
  }
}
