import React, { ReactElement, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../shared/Button";
import BaseModal from "../../shared/BaseModal";
import ManagerialPositionEditForm from "./ManagerialPositionEditForm";
import { ManagerialPositionEditType } from "../../../models/ManagerialPosition";

interface Props {
  managerialPosition: ManagerialPositionEditType;
  isFirst: boolean;
  isEnd: boolean;
  moveUp: () => void;
  moveDown: () => void;
  onChange: (mp: ManagerialPositionEditType) => void;
  onDelete: () => void;
}

export default function ManagerialPositionListItem(props: Props): ReactElement {
  const {
    managerialPosition,
    moveUp,
    moveDown,
    isFirst,
    isEnd,
    onChange,
    onDelete,
  } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <BaseModal
          show={showModal}
          contentLabel="ManagerialPositionEditForm"
          title="役職・ポジション名"
          onCloseModal={() => setShowModal(false)}
        >
          <ManagerialPositionEditForm
            managerialPosition={managerialPosition}
            onChange={(n) => {
              setShowModal(false);
              onChange(Object.assign({}, managerialPosition, { name: n }));
            }}
            onClose={() => setShowModal(false)}
            onDelete={() => {
              setShowModal(false);
              onDelete();
            }}
          />
        </BaseModal>
      )}
      <div className="p-2 border-b flex justify-between items-center">
        <div>{managerialPosition.name}</div>
        <div className="flex justify-end">
          {!isFirst && (
            <Button className="px-2" onClick={moveUp}>
              <FontAwesomeIcon icon={faArrowUp} className="opacity-50" />
            </Button>
          )}
          <Button className="px-2" onClick={moveDown} disabled={isEnd}>
            <FontAwesomeIcon
              icon={faArrowDown}
              className={`opacity-50 ${isEnd ? "opacity-0" : ""}`}
            />
          </Button>
          <Button
            onClick={() => setShowModal(true)}
            className="text-sm ml-2 opacity-50"
            title="編集"
          />
        </div>
      </div>
    </>
  );
}
