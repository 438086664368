import React, { ReactElement } from "react";
import { MeasurementPeriodSummaryResponse } from "../../models/MeasurementPeriodSummary";
import { ManagerialPositionResponse } from "../../models/ManagerialPosition";
import ReportManagerialPositionFactorScore from "./ReportItemSpecificFactorScores/ReportManagerialPositionFactorScore";
import ReportAttributeFactorScore from "./ReportItemSpecificFactorScores/ReportAttributeFactorScore";
import { QuestionnaireSummaryResponse } from "../../models/QuestionnaireSummary";

interface Props {
  allQuestionnaireSummary: QuestionnaireSummaryResponse;
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
  managerialPositions: ManagerialPositionResponse[];
  salariedStaffOptions: Array<{ key: string; value: string }>;
  averageActivityTimeOptions: Array<{ key: string; value: string }>;
  activityHistoryOptions: Array<{ key: string; value: string }>;
}

export default function ReportItemSpecificFactorScores(
  props: Props
): ReactElement {
  const {
    allQuestionnaireSummary,
    measurementPeriodSummary,
    managerialPositions,
    salariedStaffOptions,
    averageActivityTimeOptions,
    activityHistoryOptions,
  } = props;
  return (
    <div>
      <div className="mt-2 ml-2 p-2 font-bold text-lg">●項目別因子得点</div>
      <div className="flex justify-around">
        <div className="w-1/4">
          <ReportManagerialPositionFactorScore
            measurementPeriodSummary={measurementPeriodSummary}
            managerialPositions={managerialPositions}
          />
        </div>
        <div className="w-1/4">
          <ReportAttributeFactorScore
            measurementPeriodSummary={measurementPeriodSummary}
            title={`団体内での報酬による\n因子得点平均値の比較`}
            summaries={measurementPeriodSummary.salaried_staff_summaries}
            options={salariedStaffOptions}
          />
        </div>
        <div className="w-1/4">
          <ReportAttributeFactorScore
            measurementPeriodSummary={measurementPeriodSummary}
            title={`団体内での平均活動時間による\n因子得点平均値の比較`}
            summaries={measurementPeriodSummary.average_activity_time_summaries}
            options={averageActivityTimeOptions}
          />
        </div>
        <div className="w-1/4">
          <ReportAttributeFactorScore
            measurementPeriodSummary={measurementPeriodSummary}
            title={`団体内での活動歴による\n因子得点平均値の比較`}
            summaries={measurementPeriodSummary.activity_history_summaries}
            options={activityHistoryOptions}
          />
        </div>
      </div>
      <div className="text-center p-3 text-gray-600">
        ※貴団体平均を基準値とし、基準値と同じであれば白色、基準値よりも高い場合はオレンジ色、低い場合は青色で表されています。色が濃いほど基準値より離れた値であることを示しています。
      </div>
    </div>
  );
}
