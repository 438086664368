import React, { ReactElement, useState } from "react";
import BaseModal from "../shared/BaseModal";
import Button from "../shared/Button";
import BaseForm, { ajaxSubmit } from "../shared/BaseForm";
import TextForm from "../shared/forms/TextForm";
import EmailForm from "../shared/forms/EmailForm";
import { CompanyResponse } from "../../models/Company";
import { CompanyMemberIndexResponse } from "../../models/CompanyMemberApi";
import { Props as FlushMessageProps } from "../shared/FlushMessage";
import { getMilliseconds } from "date-fns";
import HiddenForm from "../shared/forms/HiddenForm";
import { deleteRequest, postRequest } from "../../utils/ApiClient";

interface Props {
  company: CompanyResponse;
  companyMember?: CompanyMemberIndexResponse;
  afterDeleted?: () => void;
  onClose: () => void;
  onChange: (editedCompanyMember: CompanyMemberIndexResponse) => void;
  afterResendMail?: (editedCompanyMember: CompanyMemberIndexResponse) => void;
}

export default function CompanyMemberAdminEditModal(
  props: Props
): ReactElement {
  const {
    company,
    companyMember,
    afterDeleted,
    onClose,
    onChange,
    afterResendMail,
  } = props;
  const [loading, setLoading] = useState(false);
  const [flushMessage, setFlushMessage] = useState<FlushMessageProps>();
  const isEdit = companyMember !== undefined;

  const save = async (): Promise<void> => {
    const form = document.forms["CompanyAdminEditForm"];
    if (form.checkValidity()) {
      setLoading(true);
      const { result, error } = await ajaxSubmit<CompanyMemberIndexResponse>(
        form
      );
      if (result && result.status === 200) {
        const editedCompanyMember = result.data;
        setLoading(false);
        onChange(editedCompanyMember);
      } else {
        if (error) {
          setFlushMessage({
            title: "エラーが発生しました",
            messages: error.data.messages,
            milliseconds: getMilliseconds(new Date()),
          });
        }
        setLoading(false);
      }
    } else {
      const submit = document.getElementById("companyAdminEditFormSubmit");
      if (submit) {
        submit.click();
      }
    }
  };

  const destroy = async (): Promise<void> => {
    if (afterDeleted === undefined) {
      return;
    }
    if (companyMember === undefined) {
      return;
    }
    if (
      confirm(
        `本当に${companyMember.user.name}さんを担当者から削除してよろしいですか？`
      )
    ) {
      setLoading(true);
      try {
        await deleteRequest(
          `/user/companies/${company.id}/company_members/${companyMember.id}/admin_destroy`
        );
        setLoading(false);
        afterDeleted();
      } catch (e) {
        const error = e.response;
        setFlushMessage({
          title: "エラーが発生しました",
          messages: error.data.messages,
          milliseconds: getMilliseconds(new Date()),
        });
        setLoading(false);
      }
    }
  };

  const sendInviteMail = async (): Promise<void> => {
    if (
      companyMember &&
      afterResendMail &&
      confirm("招待メールを再送信します。よろしいですか？")
    ) {
      setLoading(true);
      try {
        const r = await postRequest<CompanyMemberIndexResponse>(
          `/user/companies/${company.id}/company_members/${companyMember.id}/resend_invite_mail`
        );
        setLoading(false);
        afterResendMail(r.data);
      } catch (e) {
        const error = e.response;
        setFlushMessage({
          title: "エラーが発生しました",
          messages: [],
          milliseconds: getMilliseconds(new Date()),
        });
        setLoading(false);
      }
    }
  };

  return (
    <BaseModal
      show={true}
      title={`担当者${isEdit ? "編集" : "追加"}`}
      contentLabel="CompanyAdminEdit"
      onCloseModal={() => onClose()}
      loading={loading}
    >
      <BaseForm
        action={
          isEdit
            ? `/user/companies/${company.id}/company_members/${companyMember.id}`
            : `/user/companies/${company.id}/company_members`
        }
        name="CompanyAdminEditForm"
        method={isEdit ? "put" : "post"}
      >
        <BaseModal.Body flushMessage={flushMessage}>
          <div className="bg-quinary p-3 text-sm">
            <p>
              登録したメールアドレスに
              <br />
              件名「XX団体コミュニティキャピタル診断の担当者として招待されました」
              <br />
              で招待メールが届きます。
            </p>
            <p className="mt-2">
              メール内のリンクをクリックすると、担当者として団体の診断管理画面が参照できるようになります。
            </p>
          </div>
          <TextForm
            model={companyMember?.user || {}}
            scope="company_member"
            columnName="name"
            placeholder="名前"
            required
          />
          <EmailForm
            model={companyMember?.user || {}}
            scope="company_member"
            columnName="email"
            label={isEdit ? "メールアドレス(編集不可)" : "メールアドレス"}
            placeholder="メールアドレス"
            required
            disabled={isEdit}
          />
          <HiddenForm scope="company_member" columnName="role" value="admin" />
          {isEdit && (
            <div className="flex justify-end items-center text-sm my-2">
              {companyMember.user.is_confirmed && (
                <div className="opacity-75">状態: メールアドレス確認済</div>
              )}
              {!companyMember.user.is_confirmed && (
                <>
                  <div className="opacity-75">状態: メールアドレス確認中</div>
                  <div className="ml-3">
                    <Button
                      onClick={sendInviteMail}
                      title="招待メール再送信"
                      className="sub-button px-2 py-1"
                    />
                  </div>
                </>
              )}
            </div>
          )}
          <button
            type="submit"
            id="companyAdminEditFormSubmit"
            className="p-0 border-none"
          />
        </BaseModal.Body>
        <BaseModal.Footer className="flex justify-between items-center">
          <div>
            {afterDeleted !== undefined && (
              <Button
                onClick={destroy}
                title="削除"
                className="text-gray-700 tex-xs px-2 py-1"
              />
            )}
          </div>
          <div className="flex justify-end">
            <Button
              title="閉じる"
              className="sub-button px-3 py-1"
              onClick={onClose}
            />
            <Button
              title="保存"
              className="primary-button ml-4 px-4 py-1"
              onClick={save}
            />
          </div>
        </BaseModal.Footer>
      </BaseForm>
    </BaseModal>
  );
}
