import React, { ReactElement, useEffect, createRef } from "react";

interface Props {
  id: string;
  name: string;
  containerClass?: string;
  containerStyle?: React.CSSProperties;
  value?: string;
  className?: string;
  required?: boolean;
  placeholder?: string;
  innerRef?: React.LegacyRef<HTMLTextAreaElement>;
  inputStyle?: React.CSSProperties;
  readOnly?: boolean;
  disabled?: boolean;
  dataValue?: string;
  onChange?: (text: string) => void;
  fucus?: boolean;
}

export default function EmailField(props: Props): ReactElement {
  const {
    containerClass = "w-full",
    containerStyle,
    id,
    name,
    value,
    className,
    required,
    placeholder,
    inputStyle,
    readOnly = false,
    disabled = false,
    dataValue = "",
    onChange,
    fucus = false,
  } = props;
  const ref = createRef<HTMLInputElement>();

  useEffect(() => {
    if (fucus && ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <div className={containerClass} style={containerStyle}>
      <input
        ref={ref}
        className={`${className} ${disabled ? "bg-gray-300" : ""}`}
        type="email"
        autoComplete="email"
        defaultValue={value}
        name={name}
        id={id}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        style={inputStyle}
        data-value={dataValue}
        readOnly={readOnly}
        onChange={(e) => onChange && onChange(e.target.value)}
      />
    </div>
  );
}
