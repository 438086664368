import axios, { AxiosPromise } from "axios";
import { serialize } from "object-to-formdata";

export function getRequest<T>(
  url: string,
  params: any = {},
  format = "json"
): AxiosPromise<T> {
  return axios({
    method: "get",
    url: `${url}${format ? `.${format}` : ""}`,
    params: Object.assign(params),
    paramsSerializer: (p) => window.jQuery.param(p),
  });
}

export function putRequest<T>(
  url: string,
  data: any = {},
  format = "json"
): AxiosPromise<T> {
  return axios({
    method: "put",
    url: `${url}${format ? `.${format}` : ""}`,
    data: data,
  });
}

export function postRequest<T>(
  url: string,
  data: any = {},
  format = "json"
): AxiosPromise<T> {
  return axios({
    method: "post",
    url: `${url}${format ? `.${format}` : ""}`,
    data: data,
  });
}

export function deleteRequest<T>(
  url: string,
  data: any = {},
  format = "json"
): AxiosPromise<T> {
  return axios({
    method: "delete",
    url: `${url}${format ? `.${format}` : ""}`,
    data: data,
  });
}

export default getRequest;
