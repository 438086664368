import React, { ReactElement, useState } from "react";
import BaseModal from "../BaseModal";
import EmailForm from "../forms/EmailForm";
import TextForm from "../forms/TextForm";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import BaseForm, { ajaxSubmit } from "../BaseForm";
import { Props as FlushMessageProps } from "../FlushMessage";
import {
  TempEmailRegistUserResponse,
  UserResponse,
} from "../../../models/User";
import { getMilliseconds } from "date-fns";

interface Props {
  onChangeMode: (mode: "regist" | "integrate") => void;
  onClose: () => void;
  user: TempEmailRegistUserResponse;
  errorMessages: string[];
}

export default function EmailRegistModalContent(props: Props): ReactElement {
  const { onChangeMode, onClose, user, errorMessages } = props;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [flushMessage, setFlushMessage] = useState<
    FlushMessageProps | undefined
  >(
    errorMessages.length > 0
      ? {
          title: "エラーが発生しました",
          messages: errorMessages,
          milliseconds: getMilliseconds(new Date()),
        }
      : undefined
  );

  return (
    <BaseForm
      action="/user/temp_email_update"
      name="TempEmailEditForm"
      method="put"
      type="normal"
    >
      <BaseModal.Body flushMessage={flushMessage}>
        <div className="bg-quinary p-3">
          <p className="font-bold text-sm">
            アカウント登録をすると、
            <br />
            次回診断時に状態の移り変わりを見ることができます。
          </p>
          <p className="mt-2 text-xs">
            以下のメールアドレス・件名でアカウント確認メールが届きますので、
            <br />
            メール内のリンクからアカウントを有効化してください。
            <br />
            メールアドレス: {window.ClientSideEnv.MAIL_ADDRESS}
            <br />
            件名: コミュニティキャピタル診断 アカウントの有効化
          </p>
          <div className="mt-2 flex text-xs">
            <div>※</div>
            <div>
              すでにアカウントをお持ちの方
              <br />
              登録済のメールアドレス ・パスワードを入力してください。
              <br />
              今回の診断結果が統合されます。
            </div>
          </div>
        </div>
        <EmailForm
          model={user}
          scope="user"
          columnName="temp_email"
          label="メールアドレス"
          placeholder="メールアドレス"
          required
        />
        <div className="flex items-end">
          <div className="flex-grow">
            <TextForm
              model={user}
              type={passwordVisible ? "text" : "password"}
              scope="user"
              columnName="temp_password"
              label="ログイン用パスワード"
              placeholder="ログイン用パスワード"
              required
              autoComplete="current-password"
            />
          </div>
          <div className="p-3">
            <Button onClick={() => setPasswordVisible(!passwordVisible)}>
              <FontAwesomeIcon
                icon={passwordVisible ? faEye : faEyeSlash}
                size="lg"
                className="opacity-50"
              />
            </Button>
          </div>
        </div>
        <div className="flex justify-end mt-2">
          <Button
            onClick={() => onChangeMode("integrate")}
            className="text-blue-600 text-xs"
            title="アカウント登録済の方で、パスワードを忘れた方はこちら"
          />
        </div>
      </BaseModal.Body>
      <BaseModal.Footer className="flex justify-between items-center">
        <Button
          title="登録せず結果を見る"
          className="font-bold px-3 py-1"
          onClick={onClose}
        />
        <input
          type="submit"
          value="登録"
          className="primary-button ml-4 px-6 py-1 cursor-pointer"
          data-disable-with="登録中.."
          // disabled
        />
      </BaseModal.Footer>
    </BaseForm>
  );
}
