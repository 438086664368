import React, { ReactElement, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ManagerialPositionListEditButton from "./ManagerialPositionListForm/ManagerialPositionListEditButton";
import HiddenForm from "../shared/forms/HiddenForm";
import { ManagerialPositionEditType } from "../../models/ManagerialPosition";
import SubHeader from "../shared/SubHeader";
import { MeasurementPeriodEditType } from "../../models/MeasurementPeriod";
import { subButtonClassName } from "../../utils/ClassNameUtils";

interface Props {
  measurementPeriod: MeasurementPeriodEditType;
  answeredCount?: number;
}

export default function ManagerialPositionListForm(props: Props): ReactElement {
  const { measurementPeriod, answeredCount = 0 } = props;
  let initialManagerialPositions: ManagerialPositionEditType[] = [];
  if (measurementPeriod.managerial_positions.length > 0) {
    initialManagerialPositions = measurementPeriod.managerial_positions.map(
      (mp) => ({
        id: mp.id,
        name: mp.name,
        tempId: mp.id ? undefined : uuidv4(),
      })
    );
  }
  const [managerialPositions, setManagerialPositions] = useState<
    Array<ManagerialPositionEditType>
  >(initialManagerialPositions);

  return (
    <>
      {managerialPositions.map((mp, i) => (
        <React.Fragment key={mp.id || mp.tempId}>
          {mp.id && (
            <HiddenForm
              scope="measurement_period"
              relatedModelName="managerial_positions"
              index={i}
              columnName="id"
              value={mp.id}
            />
          )}
          <HiddenForm
            scope="measurement_period"
            relatedModelName="managerial_positions"
            index={i}
            columnName="name"
            value={mp.name}
          />
          <HiddenForm
            scope="measurement_period"
            relatedModelName="managerial_positions"
            index={i}
            columnName="position"
            value={i}
          />
          {mp._destroy && (
            <HiddenForm
              scope="measurement_period"
              relatedModelName="managerial_positions"
              index={i}
              columnName="_destroy"
              value="1"
            />
          )}
        </React.Fragment>
      ))}
      <div>
        <SubHeader title="役職・ポジション（役職・ポジションは自由に変更できます）" />
        <div className="text-sm mt-4">
          役職・ポジションを設定してください。ポジション別に因子得点が測定できます。
          <br />
          編集ボタンから自由に設定することができます。
        </div>
        {managerialPositions.length > 0 && (
          <div className="w-full p-2 flex justify-between mt-3">
            <div className="text-sm">
              {managerialPositions
                .filter((mp) => !mp._destroy)
                .map((mp) => (
                  <div key={mp.id || mp.tempId}>{mp.name}</div>
                ))}
            </div>
            <div>
              {answeredCount === 0 && (
                <ManagerialPositionListEditButton
                  managerialPositions={managerialPositions}
                  onChange={(mps) => setManagerialPositions(mps)}
                />
              )}
            </div>
          </div>
        )}
        {managerialPositions.length === 0 && (
          <div className="w-full p-2 flex justify-center mt-4">
            <ManagerialPositionListEditButton
              managerialPositions={managerialPositions}
              onChange={(mps) => setManagerialPositions(mps)}
              className={`${subButtonClassName()} w-1/2 py-2`}
            />
          </div>
        )}
      </div>
    </>
  );
}
