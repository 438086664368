import React, { ReactElement } from "react";
import randomColor from "randomcolor";
import { Pie } from "react-chartjs-2";
import SubHeader from "../shared/SubHeader";
import { AnswerChoiceSummaryResponse } from "../../models/AnswerChoiceSummary";
import { ChoiceResponse } from "../../models/Choice";

interface Props {
  choices: ChoiceResponse[];
  answerChoiceSummaries: AnswerChoiceSummaryResponse[];
}

export default function SelectFormatAnswer(props: Props): ReactElement {
  const { choices, answerChoiceSummaries } = props;

  const colors = randomColor({
    luminosity: "light",
    format: "rgba",
    alpha: 0.5,
    count: choices.length,
    seed: "randomColorSeed",
  });

  const textRoleSummaries = answerChoiceSummaries.filter((acs) => {
    const c = choices.find((c) => c.id === acs.choice_id);
    if (c) {
      return c.role === "with_text_role";
    } else {
      return false;
    }
  });

  return (
    <>
      <div className="flex flex-row justify-center items-center my-3">
        <div>
          <Pie
            height={300}
            width={300}
            data={{
              labels: choices.map((c) => c.body),
              datasets: [
                {
                  backgroundColor: colors,
                  data: choices.map((c) => {
                    const acs = answerChoiceSummaries.find(
                      (s) => s.choice_id === c.id
                    );
                    if (acs) {
                      return acs.count;
                    } else {
                      return 0;
                    }
                  }),
                },
              ],
            }}
            options={{
              legend: {
                display: false,
              },
            }}
          />
        </div>
      </div>
      <div>
        <div className="flex justify-between p-2">
          <div />
          <div className="text-xs opacity-50">回答数</div>
        </div>
        {choices.map((c, i) => (
          <div key={c.id} className="flex justify-between p-2 border-t text-sm">
            <div className="flex items-center">
              <div
                className="w-4 h-4 rounded"
                style={{ backgroundColor: colors[i] }}
              />
              <div className="ml-2">{c.body}</div>
            </div>
            <div className="px-2">
              {answerChoiceSummaries
                .filter((s) => s.choice_id === c.id)
                .reduce((prevVal, acs) => {
                  return prevVal + acs.count;
                }, 0)}
            </div>
          </div>
        ))}
        {textRoleSummaries.map((acs) => (
          <div key={acs.body} className="flex justify-between p-2 text-sm">
            <div className="flex items-center">
              <div className="ml-16">{acs.body}</div>
            </div>
            <div className="px-2">{acs.count}</div>
          </div>
        ))}
      </div>
    </>
  );
}
