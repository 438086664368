import React, { ReactElement, useState } from "react";
import Button from "../../shared/Button";
import PaymentMethodStripeJpy from "../../shared/images/PaymentMethodStripeJpy.png";
import { CompanyOverviewResponse } from "../../../models/CompanyApi";
import { MeasurementPeriodResponse } from "../../../models/MeasurementPeriod";
import BaseModal from "../../shared/BaseModal";
import { Props as FlushMessageProps } from "../../shared/FlushMessage";

interface Props {
  company: CompanyOverviewResponse;
  measurementPeriod: MeasurementPeriodResponse;
  onClickCreditCard: () => void;
  onClickBankTransfier: () => void;
  onClickCompanyInformationInput: () => void;
  flushMessage?: FlushMessageProps;
}

export default function PaymentMethodSelectModalContent(
  props: Props
): ReactElement {
  const {
    company,
    measurementPeriod,
    onClickCreditCard,
    onClickBankTransfier,
    onClickCompanyInformationInput,
    flushMessage,
  } = props;
  const isCompanyInfoEnterd = !!company.company_information_entered_at;

  return (
    <BaseModal.Body flushMessage={flushMessage}>
      <div className="font-bold text-center text-lg">
        金額
        <br />
        {`${measurementPeriod.billing_amount}円(税込)`}
      </div>
      {!isCompanyInfoEnterd && (
        <>
          <div className="mt-3 text-center text-sm">
            団体について詳しい情報の入力をお願いしています。
            <br />
            ご回答いただくと500円割引させていただきます。
            <br />
            決済を行う前にぜひご回答ください。
          </div>
          <div className="flex justify-center mt-2">
            <Button
              onClick={onClickCompanyInformationInput}
              title="団体情報入力"
              className="sub-button px-8 py-1 text-tertiary"
            />
          </div>
        </>
      )}
      <div className="mt-5 text-center text-sm opacity-75">
        決済方法を選択してください
      </div>
      <div className="flex justify-around flex-wrap mt-2 mb-4">
        <Button
          className="w-4/5 md:w-2/5 h-24 border border-quaternary rounded text-center"
          onClick={onClickCreditCard}
        >
          <div>
            <div className="p-2">クレジットカード</div>
            <div className="p-2">
              <img
                src={PaymentMethodStripeJpy}
                alt="payment method"
                className="h-4"
              />
            </div>
          </div>
        </Button>
        <Button
          className="w-4/5 md:w-2/5 h-24 border border-quaternary rounded flex justify-center items-center mt-4 md:mt-0"
          onClick={onClickBankTransfier}
        >
          <div className="p-2">銀行振込</div>
        </Button>
      </div>
    </BaseModal.Body>
  );
}
