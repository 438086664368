import React, { ReactElement, useState } from "react";
import { format, getMilliseconds, parseISO } from "date-fns";
import Button from "../../shared/Button";
import BaseModal from "../../shared/BaseModal";
import SelectForm from "../../shared/forms/SelectForm";
import TextForm from "../../shared/forms/TextForm";
import EmailForm from "../../shared/forms/EmailForm";
import { AdminUserResponse } from "../../../models/User";
import BaseForm, { ajaxSubmit } from "../../shared/BaseForm";
import { deleteRequest, postRequest } from "../../../utils/ApiClient";
import { CompanyMemberIndexResponse } from "../../../models/CompanyMemberApi";
import { Props as FlushMessageProps } from "../../shared/FlushMessage";
import HiddenForm from "../../shared/forms/HiddenForm";

interface Props {
  user?: AdminUserResponse;
  onClose: () => void;
  afterSaved: (user: AdminUserResponse) => void;
  afterDeleted?: () => void;
  afterResendMail?: (user: AdminUserResponse) => void;
}

export default function AdminUserEditModal(props: Props): ReactElement {
  const { user, onClose, afterSaved, afterDeleted, afterResendMail } = props;
  const [loading, setLoading] = useState(false);
  const [flushMessage, setFlushMessage] = useState<FlushMessageProps>();
  const isEdit = user !== undefined;

  const save = async (): Promise<void> => {
    const form = document.forms["AdminUserEditForm"];
    if (form.checkValidity()) {
      setLoading(true);
      const { result, error } = await ajaxSubmit<AdminUserResponse>(form);
      if (result && result.status === 200) {
        const a = result.data;
        afterSaved(a);
      } else {
        if (error) {
          setFlushMessage({
            title: "エラーが発生しました",
            messages: error.data.messages,
            milliseconds: getMilliseconds(new Date()),
          });
        }
        setLoading(false);
      }
    } else {
      const submit = document.getElementById("submit");
      if (submit) {
        submit.click();
      }
    }
  };

  const destroy = async (): Promise<void> => {
    if (afterDeleted === undefined) {
      return;
    }
    if (user === undefined) {
      return;
    }
    if (confirm(`${user.name}さんを運営メンバーから削除してよろしいですか？`)) {
      setLoading(true);
      try {
        await deleteRequest(`/admin/users/${user.id}/admin_destroy`);
        afterDeleted();
      } catch (e) {
        const error = e.response;
        setFlushMessage({
          title: "エラーが発生しました",
          messages: error.data.messages,
          milliseconds: getMilliseconds(new Date()),
        });
        setLoading(false);
      }
    }
  };

  const sendInviteMail = async (): Promise<void> => {
    if (
      user &&
      afterResendMail &&
      confirm("招待メールを再送信します。よろしいですか？")
    ) {
      setLoading(true);
      try {
        const r = await postRequest<AdminUserResponse>(
          `/admin/users/${user.id}/resend_invite_mail`
        );
        setLoading(false);
        afterResendMail(r.data);
      } catch (e) {
        const error = e.response;
        setFlushMessage({
          title: "エラーが発生しました",
          messages: [],
          milliseconds: getMilliseconds(new Date()),
        });
        setLoading(false);
      }
    }
  };

  return (
    <BaseModal
      show={true}
      contentLabel="AdminUserEdit"
      title={`運営メンバー${isEdit ? "編集" : "追加"}`}
      loading={loading}
      onCloseModal={onClose}
    >
      <BaseForm
        action={isEdit ? `/admin/users/${user.id}` : "/admin/users"}
        name="AdminUserEditForm"
        method={isEdit ? "put" : "post"}
      >
        <BaseModal.Body flushMessage={flushMessage}>
          <TextForm
            model={user || {}}
            scope="user"
            className="w-full border rounded p-2 mb-1"
            columnName="name"
            placeholder="名前"
            required
          />
          <EmailForm
            model={user || {}}
            scope="user"
            columnName="email"
            placeholder="メールアドレス"
            disabled={isEdit}
            required
          />
          {isEdit && (
            <div className="flex justify-end items-center text-sm my-2">
              {user.is_confirmed && (
                <div className="text-gray-600">状態: メールアドレス確認済</div>
              )}
              {!user.is_confirmed && (
                <>
                  <div className="text-gray-600">
                    状態: メールアドレス確認中
                  </div>
                  <div className="ml-3">
                    <Button
                      onClick={sendInviteMail}
                      title="招待メール再送信"
                      className="admin-sub-button px-2 py-1"
                    />
                  </div>
                </>
              )}
            </div>
          )}
          <HiddenForm scope="user" columnName="role" value="admin" />
        </BaseModal.Body>
        <BaseModal.Footer className="flex justify-between items-center">
          <div>
            {afterDeleted !== undefined && (
              <Button
                onClick={destroy}
                title="削除"
                className="text-gray-700 tex-xs px-2 py-1"
              />
            )}
          </div>
          <div className="flex justify-end">
            <button type="submit" id="submit" className="p-0 border-none" />
            <Button
              title="閉じる"
              className="admin-sub-button px-3 py-1"
              onClick={onClose}
            />
            <Button
              title="保存"
              className="admin-button ml-4 px-4 py-1"
              onClick={save}
            />
          </div>
        </BaseModal.Footer>
      </BaseForm>
    </BaseModal>
  );
}
