import React, { ReactElement } from "react";
import ReportItemSpecificFactorScore1 from "./ReportItemSpecificFactorScore1";
import ReportItemSpecificFactorScore2 from "./ReportItemSpecificFactorScore2";
import ReportItemSpecificFactorScore3 from "./ReportItemSpecificFactorScore3";
import ReportItemSpecificFactorScore4 from "./ReportItemSpecificFactorScore4";

const options = {
  responsive: false,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          min: 2,
          max: 10,
          stepSize: 2,
        },
      },
    ],
  },
};
export default function ReportItemSpecificFactorScores(): ReactElement {
  return (
    <div>
      <div className="mt-2 ml-2 p-2 font-bold text-lg">●項目別因子得点</div>
      <div className="flex justify-around">
        <div>
          <ReportItemSpecificFactorScore1 options={options} />
        </div>
        <div>
          <ReportItemSpecificFactorScore2 options={options} />
        </div>
        <div>
          <ReportItemSpecificFactorScore3 options={options} />
        </div>
        <div>
          <ReportItemSpecificFactorScore4 options={options} />
        </div>
      </div>
      <div className="text-center p-3 text-gray-600">
        ※貴団体平均を基準値とし、基準値と同じであれば白色、基準値よりも高い場合はオレンジ色、低い場合は青色で表されています。色が濃いほど基準値より離れた値であることを示しています。
      </div>
    </div>
  );
}
