import React, { CSSProperties, ReactElement, ReactNode } from "react";
import Modal from "react-modal";
import BaseModalBody from "./BaseModalBody";
import BaseModalFooter from "./BaseModalFooter";
import LoadingImage from "./images/SpinGray.svg";
import { isAdmin } from "../../utils/NavigationUtils";

interface Props {
  show: boolean;
  title?: string;
  contentLabel: string;
  onCloseModal: () => void;
  children: ReactNode;
  contentStyle?: CSSProperties;
  loading?: boolean;
}

interface defaultStyleType {
  overlay: { backgroundColor: string; zIndex: number };
  content: {
    top: string;
    left: string;
    right: string;
    bottom: string;
    marginRight: string;
    transform: string;
    padding: number;
    border: string;
    overflow: string;
  };
}

const defaultStyles: defaultStyleType = {
  overlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 2000,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -55%)",
    padding: 0,
    border: "none",
    // width: "80%", // modal.scssで指定
    // maxWidth: 1024,
    overflow: "unset",
  },
};

function BaseModal(props: Props): ReactElement {
  const {
    show,
    title,
    onCloseModal,
    children,
    contentLabel,
    loading = false,
    contentStyle,
  } = props;

  const costomStyles = (): defaultStyleType | CSSProperties => {
    return Object.assign(defaultStyles, {
      content: Object.assign(defaultStyles.content, contentStyle),
    });
  };

  return (
    <Modal
      isOpen={show}
      contentLabel={contentLabel}
      ariaHideApp={false}
      style={costomStyles()}
    >
      <div className={`modal-content ${isAdmin ? "" : "default-text-color"}`}>
        {title !== undefined && (
          <div
            className={`px-2 py-1 flex justify-between items-center border-b rounded-t ${
              isAdmin ? "" : "bg-quinary"
            }`}
          >
            <h5 className="font-bold">{title}</h5>
            {onCloseModal && (
              <button
                type="button"
                className="px-4 py-2 text-lg"
                data-dismiss="modal"
                aria-label="Close"
                disabled={loading}
                onClick={() => onCloseModal()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            )}
          </div>
        )}
        <div style={{ position: "relative" }}>
          {children}
          {loading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 1000,
              }}
            >
              <object
                type="image/svg+xml"
                data={LoadingImage}
                width="80"
                height="80"
                title="loading"
                style={{
                  position: "absolute",
                  top: "calc(50% - 65px)",
                  transform: "translate(-50%)",
                  left: "50%",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
BaseModal.Body = BaseModalBody;
BaseModal.Footer = BaseModalFooter;
export default BaseModal;
