import React, { ReactElement, useState } from "react";
import { Radar } from "react-chartjs-2";
import SelectForm from "../shared/forms/SelectForm";
import TextForm from "../shared/forms/TextForm";
import SubHeader from "../shared/SubHeader";
import { MeasurementPeriodEditType } from "../../models/MeasurementPeriod";

interface Props {
  measurementPeriod: MeasurementPeriodEditType;
}

const options = [
  { key: "10", value: "10人" },
  { key: "15", value: "15人" },
  { key: "20", value: "20人" },
  { key: "25", value: "25人" },
  { key: "30", value: "30人" },
  { key: "35", value: "35人" },
  { key: "40", value: "40人" },
  { key: "45", value: "45人" },
  { key: "50", value: "50人" },
  { key: "60", value: "60人" },
  { key: "70", value: "70人" },
  { key: "80", value: "80人" },
  { key: "90", value: "90人" },
  { key: "100", value: "100人" },
  { key: "text_field", value: "その他（自由記入）" },
];

export default function MeasurementCountForm(props: Props): ReactElement {
  const { measurementPeriod } = props;
  let initialShowTextField = false;
  if (
    measurementPeriod.approximate_respondents_count &&
    !options.some(
      (o) => o.key === String(measurementPeriod.approximate_respondents_count)
    )
  ) {
    initialShowTextField = true;
  }
  const [showTextField, setShowTextField] = useState(initialShowTextField);
  return (
    <>
      <SubHeader title="調査対象人数" />
      <div className="text-sm mt-4">
        大まかな調査人数を設定してください
        <br />
        調査対象人数は10人以上を推奨しています
      </div>
      <div className="mt-3">
        <SelectForm
          model={measurementPeriod}
          scope="measurement_period"
          columnName={`approximate_respondents_count${
            showTextField ? "_dummy" : ""
          }`}
          option={{ include_blank: "-- 選択してください --" }}
          options={options}
          hideLabel
          required
          onChange={(e) => {
            if (e.target.value === "text_field") {
              setShowTextField(true);
            } else {
              setShowTextField(false);
            }
          }}
        />
        {showTextField && (
          <div className="mt-2">
            <TextForm
              model={measurementPeriod}
              scope="measurement_period"
              columnName="approximate_respondents_count"
              placeholder="調査人数を入力してください"
              type="number"
              hideLabel
              required
            />
          </div>
        )}
      </div>
    </>
  );
}
