import React, { useState, ReactElement } from "react";
import BaseModal from "../shared/BaseModal";
import Button from "../shared/Button";
import MultiLineText from "../shared/MultiLineText";

export default function MindsetDescriptionButton(): ReactElement {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <BaseModal
          show={true}
          title="マインドセット診断とは？"
          contentLabel="CompanyAdminEdit"
          onCloseModal={() => setShowModal(false)}
        >
          <BaseModal.Body>
            <div className="text-sm">
              私たちの行動や選択は私たちの考え方や価値観に強く影響されています。
              <br />
              コミュニティキャピタル診断では、団体コミュニティとの良好な関係を作れる人の特徴を明らかにするために、考え方や価値観に関する以下の７つの項目も測定しています。
              <br />
              <br />
              <span className="font-bold">多様性受容</span>
              ：自分と異なる人を受け入れることができるか。
              <br />
              <span className="font-bold">レジリエンス</span>
              ：困難があってもくじけずに乗り越えることができるか。
              <br />
              <span className="font-bold">社会的信頼</span>
              ：社会の中の他者を互いに助けあえる存在として信頼しているかどうか。
              <br />
              <span className="font-bold">ポジティブサム思考</span>
              ：ゼロサム思考（成功の陰には必ず誰かの犠牲が必要だとする考え方）と対立する考え方。
              <br />
              <span className="font-bold">成長思考</span>
              ：自分の努力によって能力や性格も変えられるという考え方
              <br />
              <span className="font-bold">未来に関する自己効力感</span>
              ：一般的な自己効力感ではなく、未来は自分の力で変えられるという考え方。
              <br />
              <span className="font-bold">人間関係思考</span>
              ：人間関係を重要と考えているかどうか。
              <br />
              <br />
              これまでの研究でこれらの項目は全てコミュニティキャピタルと強い関係があることがわかっています。
            </div>
          </BaseModal.Body>
        </BaseModal>
      )}
      <Button
        className="sub-button px-3 py-1"
        onClick={() => setShowModal(true)}
        title="マインドセット診断とは？"
      />
    </>
  );
}
