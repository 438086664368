import React, { ReactElement } from "react";
import { Radar } from "react-chartjs-2";
import SubHeader from "../../shared/SubHeader";
import { MeasurementPeriodSummaryResponse } from "../../../models/MeasurementPeriodSummary";
import { filterNotEmpty } from "../../../utils/ArrayUtils";

interface Props {
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
}

const options = (min: number, max: number) => {
  return {
    legend: {
      position: "top",
      onClick: () => {
        return false;
      },
    },
    scale: {
      ticks: {
        min: min < 40 ? min - 10 : 40,
        max: max > 60 ? max + 10 : 60,
        stepSize: 10,
      },
    },
    fill: false,
  };
};

export default function MindStateFactorScore(props: Props): ReactElement {
  const { measurementPeriodSummary } = props;

  const healthStateDeviationValues = [
    measurementPeriodSummary.depressive_tendency_deviation_value,
    measurementPeriodSummary.loneliness_deviation_value,
    measurementPeriodSummary.self_affirmation_deviation_value,
    measurementPeriodSummary.health_perception_deviation_value,
    measurementPeriodSummary.well_being_deviation_value,
  ];

  const mindsetDeviationValues = [
    measurementPeriodSummary.diversity_acceptance_deviation_value,
    measurementPeriodSummary.resilience_deviation_value,
    measurementPeriodSummary.social_trust_deviation_value,
    measurementPeriodSummary.positive_sum_thinking_deviation_value,
    measurementPeriodSummary.growth_mindset_deviation_value,
    measurementPeriodSummary.self_efficacy_deviation_value,
    measurementPeriodSummary.human_relations_thinking_deviation_value,
  ];

  return (
    <>
      <SubHeader title="チャート" />
      <div className="mt-3 p-3 bg-quinary text-sm text-tertiary">
        ・全回答者の平均値を50とした団体の偏差値を計算して円グラフで表示しています。
        <br />
        ・これまでの調査ではコミュニティキャピタルが高い団体ほどレーダーチャートの円が大きくなる傾向があります。
      </div>
      <div className="flex flex-row justify-center items-center flex-wrap my-3">
        <div className="p-8">
          <div className="ml-3 p-2 font-bold text-center">心の健康状態</div>
          <Radar
            height={350}
            width={350}
            data={{
              labels: [
                "抑うつ傾向の低さ",
                "孤独感の低さ",
                "自己肯定感",
                "主観的健康感",
                "主観的幸福感",
              ],
              datasets: [
                {
                  label: "貴団体",
                  data: healthStateDeviationValues,
                  fill: false,
                  borderColor: "rgba(255, 99, 132, 1)",
                  backgroundColor: "rgba(255, 99, 132, 1)",
                  borderWidth: 2,
                },
                {
                  label: "サンプル全体",
                  data: [50, 50, 50, 50, 50],
                  fill: false,
                  borderColor: "#F5A300",
                  backgroundColor: "#F5A300",
                  borderWidth: 2,
                },
              ],
            }}
            options={options(
              Math.min(...filterNotEmpty(healthStateDeviationValues)),
              Math.max(...filterNotEmpty(healthStateDeviationValues))
            )}
          />
        </div>
        <div className="p-8">
          <div className="p-2 font-bold text-center">マインドセット</div>
          <Radar
            height={350}
            width={350}
            data={{
              labels: [
                "多様性受容",
                "レジリエンス",
                "社会的信頼",
                "ポジティブサム思考",
                "成長思考",
                "自己効力感",
                "人間関係思考",
              ],
              datasets: [
                {
                  label: "貴団体",
                  data: mindsetDeviationValues,
                  fill: false,
                  borderColor: "rgba(255, 99, 132, 1)",
                  backgroundColor: "rgba(255, 99, 132, 1)",
                  borderWidth: 2,
                },
                {
                  label: "サンプル全体",
                  data: [50, 50, 50, 50, 50, 50, 50],
                  fill: false,
                  borderColor: "#F5A300",
                  backgroundColor: "#F5A300",
                  borderWidth: 2,
                },
              ],
            }}
            options={options(
              Math.min(...filterNotEmpty(mindsetDeviationValues)),
              Math.max(...filterNotEmpty(mindsetDeviationValues))
            )}
          />
        </div>
      </div>
    </>
  );
}
