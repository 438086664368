import React, { ReactElement } from "react";
import { Bar } from "react-chartjs-2";
import AttributeScoreCell from "../../shared/AttributeScoreCell";

const options = {
  responsive: false,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          min: 2,
          max: 10,
          stepSize: 2,
        },
      },
    ],
  },
  fill: false,
};

export default function ItemSpecificFactorScore4(): ReactElement {
  return (
    <>
      <h5 className="p-3 text-center">
        団体内での活動歴による因子得点平均値の比較
      </h5>
      <div className="flex flex-row justify-center items-center flex-wrap">
        <div className="p-2 w-full lg:w-1/3 flex justify-center">
          <div>
            <Bar
              height={320}
              width={320}
              data={{
                labels: ["", "", ""],
                datasets: [
                  {
                    // barThickness: 10,
                    label: "3ヶ月未満",
                    data: [9.79, 7.5, 9.17],
                    borderColor: "#FEFF02",
                    backgroundColor: "#FEFF02",
                  },
                  {
                    // barThickness: 10,
                    label: "3ヶ月以上〜6ヶ月未満",
                    data: [9.48, 5.83, 8.96],
                    borderColor: "#F5A300",
                    backgroundColor: "#F5A300",
                  },
                  {
                    // barThickness: 10,
                    label: "6ヶ月以上〜1年未満",
                    data: [9.17, 5.76, 7.5],
                    borderColor: "#92D050",
                    backgroundColor: "#92D050",
                  },
                  {
                    // barThickness: 10,
                    label: "1年以上〜3年未満",
                    data: [9.58, 4.31, 8.61],
                    borderColor: "#2CB0F0",
                    backgroundColor: "#2CB0F0",
                  },
                  {
                    // barThickness: 10,
                    label: "3年以上",
                    data: [9.17, 5.63, 8.19],
                    borderColor: "#B9CDE5",
                    backgroundColor: "#B9CDE5",
                  },
                ],
              }}
              options={options}
            />
            <div className="flex justify-around text-xs">
              <div>理念共感と貢献意欲</div>
              <div>自己有用感</div>
              <div>居心地の良さ</div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-2/3 flex justify-center">
          <div className="px-3 w-full">
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="border font-normal text-sm px-2"></th>
                  <th className="border font-normal text-sm px-2">
                    理念共感と貢献意欲
                  </th>
                  <th className="border font-normal text-sm px-2">
                    自己有用感
                  </th>
                  <th className="border font-normal text-sm px-2">
                    居心地の良さ
                  </th>
                </tr>
              </thead>
              <tbody>
                {[
                  {
                    title: "3ヶ月未満",
                    color: "#FEFF02",
                    point1: 9.79,
                    point2: 7.5,
                    point3: 9.17,
                  },
                  {
                    title: "3ヶ月以上〜6ヶ月未満",
                    color: "#F5A300",
                    point1: 9.48,
                    point2: 5.83,
                    point3: 8.96,
                  },
                  {
                    title: "6ヶ月以上〜1年未満",
                    color: "#92D050",
                    point1: 9.17,
                    point2: 5.76,
                    point3: 7.5,
                  },
                  {
                    title: "1年以上〜3年未満",
                    color: "#2CB0F0",
                    point1: 9.58,
                    point2: 4.31,
                    point3: 8.61,
                  },
                  {
                    title: "3年以上",
                    color: "#B9CDE5",
                    point1: 9.17,
                    point2: 5.63,
                    point3: 8.19,
                  },
                ].map((d) => (
                  <tr key={d.title}>
                    <td className="border px-4 py-2 text-sm text-center">
                      <div className="flex items-center">
                        <div
                          className="rounded h-3 w-3"
                          style={{ backgroundColor: d.color }}
                        />
                        <div className="ml-2">{d.title}</div>
                      </div>
                    </td>
                    <AttributeScoreCell
                      answeredCount={10}
                      point={d.point1}
                      averagePoint={9.31}
                      className="border px-2 text-center"
                    />
                    <AttributeScoreCell
                      answeredCount={10}
                      point={d.point2}
                      averagePoint={5.71}
                      className="border px-2 text-center"
                    />
                    <AttributeScoreCell
                      answeredCount={10}
                      point={d.point3}
                      averagePoint={8.14}
                      className="border px-2 text-center"
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
