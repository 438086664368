import React, { ChangeEvent, ReactElement, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { isAdmin } from "../../../utils/NavigationUtils";
import ChoiceField from "./ChoiceField";
import Button from "../../shared/Button";
import { ChoiceEditType } from "../../../models/Choice";
import HiddenForm from "../../shared/forms/HiddenForm";
import { QuestionResponse, QuestionEditType } from "../../../models/Question";

interface Props {
  question: QuestionEditType;
  relatedModelName?: string;
  placeholder?: string;
  containerClassName?: string;
  fieldWrapContainerClassName?: string;
  className?: string;
  index?: number;
  hideLabel?: boolean;
  required?: boolean;
  requiredClassName?: string;
  value?: string;
  readOnly?: boolean;
  label?: string;
  labelClassName?: string;
  showBottomBorder?: boolean;
  onChange?: (text: string) => void;
}

const newChoice = (): ChoiceEditType => ({
  body: "",
  role: "basic_role",
  tempKey: uuidv4(),
});
const otherChoice = (): ChoiceEditType => ({
  body: "その他(自由記入)",
  role: "with_text_role",
  tempKey: uuidv4(),
});

export default function ChoiceEditForm(props: Props): ReactElement {
  const {
    question,
    relatedModelName,
    placeholder,
    index,
    containerClassName = isAdmin
      ? "flex flex-wrap items-center"
      : "flex flex-wrap items-center mt-3",
    fieldWrapContainerClassName: propFieldWrapContainerClassName = isAdmin
      ? undefined
      : "w-full",
    className = isAdmin
      ? "w-full border rounded p-2"
      : "w-full border-2 border-quaternary placeholder-quaternary rounded-lg px-4 py-2",
    required = false,
    requiredClassName = isAdmin
      ? "ml-1 rounded px-1 text-red-600 text-xs"
      : "ml-1 px-1 text-red-600 text-xs leading-loose",
    value,
    readOnly = false,
    hideLabel = false,
    label = "",
    labelClassName = isAdmin
      ? "w-full md:w-1/4 pt-2 px-2 md:p-2 text-gray-600 text-sm flex items-center"
      : "w-full pt-2 px-2 md:p-2 text-sm flex items-center flex-wrap font-bold",
    showBottomBorder = false,
    onChange,
  } = props;
  const [choices, setChoices] = useState<ChoiceEditType[]>(
    question.choices.length > 0 ? question.choices : [newChoice(), newChoice()]
  );

  let fieldWrapContainerClassName = "w-full md:w-3/4";
  if (propFieldWrapContainerClassName) {
    fieldWrapContainerClassName = propFieldWrapContainerClassName;
  } else if (hideLabel) {
    fieldWrapContainerClassName = "w-full";
  }

  const addChoice = (): void => {
    setChoices(choices.concat([newChoice()]));
  };

  const addOtherChoice = (): void => {
    setChoices(choices.concat([otherChoice()]));
  };

  const removeChoice = (choice: ChoiceEditType) => {
    if (choice.id) {
      const newChoices = choices.map((c) => {
        if (c.id && c.id === choice.id) {
          return Object.assign({}, c, { destroyed: true });
        }
        return c;
      });
      setChoices(newChoices);
      return;
    }
    setChoices(choices.filter((c) => c.tempKey !== choice.tempKey));
  };

  const normalChoices = choices.filter(
    (c) => !["with_text_role", "blank_role"].includes(c.role)
  );
  const otherChoices = choices.filter((c) => c.role === "with_text_role");
  const existsActiveOtherChoice = otherChoices.some((c) => c && !c.destroyed);
  const activeFieldCount =
    normalChoices.filter((c) => c && !c.destroyed).length +
    (existsActiveOtherChoice ? 1 : 0);

  let activeIndex = 0;
  return (
    <div
      className={`${containerClassName} ${
        showBottomBorder ? "border-b border-gray-300" : ""
      }`}
    >
      {!hideLabel && (
        <label className={labelClassName}>
          <div>{label}</div>
          {required && <div className={requiredClassName}>必須</div>}
        </label>
      )}
      <div className={fieldWrapContainerClassName}>
        {normalChoices.concat(otherChoices).map((choice, index) => {
          if (!choice) {
            return null;
          }
          if (choice.id && choice.destroyed) {
            return (
              <React.Fragment key={`destroy_${choice.id || choice.tempKey}`}>
                <HiddenForm
                  scope="question"
                  relatedModelName="choices"
                  index={index}
                  columnName="id"
                  value={choice.id}
                />
                <HiddenForm
                  scope="question"
                  relatedModelName="choices"
                  index={index}
                  columnName="_destroy"
                  value="1"
                />
              </React.Fragment>
            );
          }
          activeIndex += 1;
          return (
            <ChoiceField
              key={`field_${choice.id || choice.tempKey}`}
              choice={choice}
              index={index}
              showDeleteButton={activeFieldCount > 1}
              onRemove={() => removeChoice(choice)}
              activeIndex={activeIndex}
            />
          );
        })}
        <div className="flex justify-end py-1 mt-4">
          <Button
            title="選択肢追加"
            className="sub-button px-4 py-1"
            onClick={addChoice}
          />
          {!existsActiveOtherChoice && (
            <Button
              title="その他選択肢追加"
              className="sub-button px-4 py-1 ml-2"
              onClick={addOtherChoice}
            />
          )}
        </div>
      </div>
    </div>
  );
}
