import React, { ReactElement, useState } from "react";
import Button from "../shared/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import CompanyMemberAdminEditModal from "./CompanyMemberAdminEditModal";
import { CompanyMemberIndexResponse } from "../../models/CompanyMemberApi";
import { CompanyResponse } from "../../models/Company";

interface Props {
  company: CompanyResponse;
  companyMember: CompanyMemberIndexResponse;
  afterDeleted: () => void;
  onChange: (editedCompanyMember: CompanyMemberIndexResponse) => void;
  afterResendMail: (editedCompanyMember: CompanyMemberIndexResponse) => void;
}

export default function CompanyMemberAdminListItem(props: Props): ReactElement {
  const { company, companyMember, afterDeleted, onChange, afterResendMail } =
    props;
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {showModal && (
        <CompanyMemberAdminEditModal
          company={company}
          companyMember={companyMember}
          afterDeleted={() => {
            setShowModal(false);
            afterDeleted();
          }}
          onClose={() => setShowModal(false)}
          onChange={(cm) => {
            setShowModal(false);
            onChange(cm);
          }}
          afterResendMail={(cm) => {
            setShowModal(false);
            afterResendMail(cm);
          }}
        />
      )}
      <tr className="border-t">
        <td className="text-left p-2 text-sm">
          {companyMember.name || companyMember.user.name}
        </td>
        <td className="text-left p-2 text-sm">
          {companyMember.user.is_confirmed ? "有効" : "メールアドレス確認中"}
        </td>
        <td className="text-right">
          <Button
            onClick={() => setShowModal(true)}
            className="text-sm opacity-50 p-2"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </td>
      </tr>
    </>
  );
}
