import React, { ReactElement, useState } from "react";
import { QuestionnaireEditType } from "../../../models/Questionnaire";
import { CommunityCapitalQuestionResponse } from "../../../models/CommunityCapitalQuestion";
import SixPointScaleQuestionItem from "./SixPointScaleQuestionItem";

interface Props {
  questionnaire: QuestionnaireEditType;
  mainQuestions: CommunityCapitalQuestionResponse[];
  showForm: boolean;
  onInputComplete: () => void;
}

export default function CommunityCapitalForm(props: Props): ReactElement {
  const { showForm, questionnaire, mainQuestions, onInputComplete } = props;
  const [inputCompleteQuestionIdList, setInputCompleteQuestionIdList] =
    useState<number[]>([]);

  const addInputCompleteQuestionIdList = (questionId: number): void => {
    let newList = inputCompleteQuestionIdList.concat();
    if (!inputCompleteQuestionIdList.includes(questionId)) {
      newList = inputCompleteQuestionIdList.concat([questionId]);
      setInputCompleteQuestionIdList(newList);
    }
    if (mainQuestions.length === newList.length) {
      onInputComplete();
    }
  };

  return (
    <>
      {mainQuestions.map((q, i) => (
        <SixPointScaleQuestionItem
          key={q.id}
          questionnaire={questionnaire}
          communityCapitalQuestion={q}
          questionIndex={i}
          showForm={showForm}
          onInputComplete={() => addInputCompleteQuestionIdList(q.id)}
        />
      ))}
    </>
  );
}
