import React, { ReactElement } from "react";
import { Bar } from "react-chartjs-2";
import { colors } from "../../../utils/GraphColorUtils";
import { MeasurementPeriodAttributesSummaryResponse } from "../../../models/MeasurementPeriodAttributesSummary";
import MultiLineText from "../../shared/MultiLineText";
import { QuestionnaireSummaryResponse } from "../../../models/QuestionnaireSummary";
import AttributeScoreCell from "../../shared/AttributeScoreCell";
import { MeasurementPeriodResponse } from "../../../models/MeasurementPeriod";
import { MeasurementPeriodSummaryResponse } from "../../../models/MeasurementPeriodSummary";

const tableCell = "border px-1 text-xs text-center whitespace-no-wrap";

const chartOptions = {
  responsive: false,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          max: 10,
          stepSize: 2,
        },
      },
    ],
  },
  fill: false,
};

interface Props {
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
  title: string;
  summaries: MeasurementPeriodAttributesSummaryResponse[];
  options: Array<{ key: string; value: string }>;
}

export default function ReportAttributeFactorScore(props: Props): ReactElement {
  const { measurementPeriodSummary, title, summaries, options } = props;
  return (
    <>
      <h5 className="text-center text-sm">
        <MultiLineText text={title} />
      </h5>
      <div>
        <div className="p-2">
          <Bar
            height={200}
            width={350}
            data={{
              labels: ["", "", ""],
              datasets: options
                .concat({ key: "null", value: "無回答" })
                .map((o, i) => {
                  const summary = summaries.find((s) => s.key === o.key);
                  return {
                    label: o.value,
                    data: [
                      summary?.empathy_and_willingness_point || 0,
                      summary?.self_importance_point || 0,
                      summary?.comfort_point || 0,
                    ],
                    borderColor: colors[i],
                    backgroundColor: colors[i],
                  };
                }),
            }}
            options={chartOptions}
          />
          <div className="flex justify-around text-xs">
            <div>理念共感と貢献意欲</div>
            <div>自己有用感</div>
            <div>居心地の良さ</div>
          </div>
        </div>
        <div>
          <div className="px-3">
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="border font-normal text-xs px-2"></th>
                  <th className="border font-normal text-xs px-2">
                    理念共感と貢献意欲
                  </th>
                  <th className="border font-normal text-xs px-2">
                    自己有用感
                  </th>
                  <th className="border font-normal text-xs px-2">
                    居心地の良さ
                  </th>
                </tr>
              </thead>
              <tbody>
                {options
                  .concat({ key: "null", value: "無回答" })
                  .map((o, i) => {
                    const summary = summaries.find((s) => s.key === o.key);
                    return (
                      <tr key={o.key}>
                        <td className="border px-1 text-xs">
                          <div className="flex items-center">
                            <div
                              className="rounded h-3 w-3"
                              style={{ backgroundColor: colors[i] }}
                            />
                            <div className="ml-2 truncate">{o.value}</div>
                          </div>
                        </td>
                        <AttributeScoreCell
                          className={tableCell}
                          answeredCount={
                            summary?.empathy_and_willingness_answered_count
                          }
                          point={summary?.empathy_and_willingness_point}
                          averagePoint={
                            measurementPeriodSummary.empathy_and_willingness_point
                          }
                        />
                        <AttributeScoreCell
                          className={tableCell}
                          answeredCount={
                            summary?.self_importance_answered_count
                          }
                          point={summary?.self_importance_point}
                          averagePoint={
                            measurementPeriodSummary.self_importance_point
                          }
                        />
                        <AttributeScoreCell
                          className={tableCell}
                          answeredCount={summary?.comfort_answered_count}
                          point={summary?.comfort_point}
                          averagePoint={measurementPeriodSummary.comfort_point}
                        />
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
