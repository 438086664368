import React, { ReactElement } from "react";

interface Props {
  title: string | string[];
}

export default function SubHeader(props: Props): ReactElement {
  const { title } = props;

  if (Array.isArray(title)) {
    return (
      <h4 className="px-3 py-2 -mx-3 bg-secondary text-over-secondary">
        {title.map((t) => (
          <div>{t}</div>
        ))}
      </h4>
    );
  }

  return (
    <h4 className="px-3 py-2 -mx-3 bg-secondary text-over-secondary">
      {title}
    </h4>
  );
}
