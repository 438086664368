import React, { ReactElement } from "react";

interface Props {
  text: string;
}

export default function MultiLineText(props: Props): ReactElement {
  const { text } = props;

  const texts = text
    .split(/(\n)/)
    .map((item, i) => (
      <React.Fragment key={i}>
        {item.match(/\n/) ? <br /> : item}
      </React.Fragment>
    ));
  return <div>{texts}</div>;
}
