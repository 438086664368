import React, { ReactElement, useState } from "react";
import SelectForm from "../../../shared/forms/SelectForm";
import HiddenForm from "../../../shared/forms/HiddenForm";
import { QuestionnaireEditType } from "../../../../models/Questionnaire";
import { QuestionIndexResponse } from "../../../../models/QuestionApi";
import { baseScope } from "../../../../utils/AttributeUtils";
import TextAreaForm from "../../../shared/forms/TextAreaForm";

interface Props {
  showForm: boolean;
  questionnaire: QuestionnaireEditType;
  question: QuestionIndexResponse;
  index: number;
  onChange: (val: string) => void;
}

export default function AdditionalQuestionSelectForm(
  props: Props
): ReactElement {
  const { showForm, questionnaire, question, index, onChange } = props;
  const answer = questionnaire.answers.find(
    (a) => a.question_id === question.id
  );
  let initialChoiceRole:
    | "basic_role"
    | "with_text_role"
    | "blank_role"
    | undefined = undefined;
  let initialChoiceId = "";
  let initialTextValue = "";
  if (answer) {
    const c = question.choices.find((c) =>
      answer.answer_choices.map((ac) => ac.choice_id).includes(c.id)
    );
    if (c) {
      initialChoiceRole = c.role;
      initialChoiceId = String(c.id);
      if (initialChoiceRole && initialChoiceRole === "with_text_role") {
        const ac = answer.answer_choices.find((ac) => ac.choice_id === c.id);
        if (ac) {
          initialTextValue = ac.body;
        }
      }
    }
  }
  const [choiceRole, setChoiceRole] = useState(initialChoiceRole);
  const [choiceId, setChoiceId] = useState(initialChoiceId);
  const [textValue, setTextValue] = useState(initialTextValue);
  const [blankChoice] = question.choices.filter((c) => c.role === "blank_role");

  const updateChoice = (choiceId: string) => {
    if (choiceId) {
      const [choice] = question.choices.filter(
        (c) => String(c.id) === choiceId
      );
      setChoiceRole(choice.role);
      if (choice.role !== "with_text_role") {
        onChange(choiceId);
      }
    } else {
      setChoiceRole(undefined);
      onChange(choiceId);
    }
    setChoiceId(choiceId);
  };

  return (
    <>
      <HiddenForm
        scope="questionnaire"
        relatedModelName="answers"
        index={index}
        columnName="question_id"
        value={question.id}
      />
      {!showForm && (
        <>
          <HiddenForm
            scope={baseScope("questionnaire", "answers", index)}
            relatedModelName="answer_choices"
            index={0}
            columnName="choice_id"
            value={choiceId}
          />
          <HiddenForm
            scope={baseScope("questionnaire", "answers", index)}
            relatedModelName="answer_choices"
            index={0}
            columnName="body"
            value={textValue}
          />
        </>
      )}
      {showForm && (
        <>
          <SelectForm
            model={answer || {}}
            scope={baseScope("questionnaire", "answers", index)}
            relatedModelName="answer_choices"
            index={0}
            columnName="choice_id"
            option={{ include_blank: blankChoice ? blankChoice.body : "" }}
            options={question.choices
              .filter((c) => !(c.role === "blank_role"))
              .map((c) => ({ key: String(c.id), value: c.body }))}
            hideLabel
            defaultKey=""
            selectedKey={choiceId}
            onChange={(e) => {
              updateChoice(e.target.value);
            }}
          />
          {choiceRole === "with_text_role" && (
            <TextAreaForm
              model={answer || {}}
              scope={baseScope("questionnaire", "answers", index)}
              relatedModelName="answer_choices"
              index={0}
              columnName="body"
              rows={3}
              hideLabel
              value={textValue}
              placeholder="自由記入欄"
              onChange={(e) => {
                setTextValue(e.target.value);
                if (e.target.value) {
                  onChange(choiceId);
                } else {
                  onChange("");
                }
              }}
            />
          )}
        </>
      )}
    </>
  );
}
