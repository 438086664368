import React, { ReactElement, useState } from "react";
import { animateScroll } from "react-scroll";
import MultiLineText from "../shared/MultiLineText";

interface Props {
  title: string;
}

const options = [
  { key: "1", value: "まったくなかった" },
  { key: "2", value: "週に1～2日ほどあった" },
  { key: "3", value: "週に3～4日ほどあった" },
  { key: "4", value: "ほとんど毎日あった" },
];

function scrollMore() {
  if (window.screen.width < 753) {
    animateScroll.scrollMore(150, { duration: 500 });
  } else {
    animateScroll.scrollMore(142, { duration: 500 });
  }
}

export default function HealthStateQuestionItem(props: Props): ReactElement {
  const { title } = props;
  const [selectdKey, setSelectedKey] = useState<string>();

  return (
    <div className="mt-4">
      <div className="font-bold mt-2">{title}</div>
      <div className="flex justify-center mt-2">
        <div className="w-full md:w-4/5 flex justify-around">
          {options.map((o) => (
            <div
              key={o.key}
              className="w-1/4 py-1"
              style={{ paddingLeft: 1, paddingRight: 1 }}
            >
              <div
                className={`border rounded cursor-pointer ${
                  selectdKey && selectdKey === o.key
                    ? "bg-secondary border-yellow-300"
                    : "bg-white"
                }`}
                onClick={() => {
                  setSelectedKey(o.key);
                  scrollMore();
                }}
              >
                <div className="flex justify-center items-center mt-2">
                  <div
                    className={`border-4 rounded-full h-8 w-8 ${
                      selectdKey && selectdKey === o.key
                        ? "border-yellow-300"
                        : ""
                    }`}
                  />
                </div>
                <div
                  className={`flex justify-center items-center text-center mt-1 h-12 md:h-10 text-tiny md:text-xs ${
                    selectdKey && selectdKey === o.key ? "text-white" : ""
                  }`}
                >
                  <MultiLineText text={o.value} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
