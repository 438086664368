import React, { ReactElement } from "react";
import { HorizontalBar } from "react-chartjs-2";
import SubHeader from "../../shared/SubHeader";
import { colors } from "../../../utils/GraphColorUtils";

const options = {
  indexAxis: "y",
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
          min: 0,
          max: 100,
          stepSize: 20,
          callback: (value, index, values) => {
            return `${value}%`;
          },
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
      },
    ],
  },
  fill: false,
  tooltips: {
    enabled: false,
  },
};

interface Props {
  title: string;
  data: {
    label: string;
    rate: number;
  }[];
}

export default function RespondentDistributionItem(props: Props): ReactElement {
  const { title, data } = props;
  return (
    <>
      <div className="p-2 flex flex-col items-center">
        <div className="text-center text-sm">{title}</div>
        <div className="" style={{ width: 300 }}>
          <HorizontalBar
            height={70}
            width={300}
            data={{
              labels: [""],
              datasets: data.map((d, i) => ({
                label: d.label,
                data: [d.rate],
                borderColor: colors[i],
                backgroundColor: colors[i],
              })),
            }}
            options={options}
          />
        </div>
        <div className="flex justify-center mt-2">
          <table className="table-auto">
            <thead>
              <tr>
                <th className="border font-normal text-xs px-2"></th>
                <th className="border font-normal text-xs px-2">
                  回答者の割合
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((d, i) => (
                <tr key={d.label}>
                  <td className="border text-xs px-2">
                    <div className="flex items-center">
                      <div
                        className="h-3 w-3 rounded"
                        style={{ backgroundColor: colors[i] }}
                      />
                      <div className="ml-2" style={{ maxWidth: 300 }}>
                        {d.label}
                      </div>
                    </div>
                  </td>
                  <td className="border text-xs text-center">{d.rate}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
