import React, { ReactElement } from "react";
import { Line } from "react-chartjs-2";
import { parseISO, format } from "date-fns";
import SubHeader from "../shared/SubHeader";
import { MeasurementPeriodSummaryResponse } from "../../models/MeasurementPeriodSummary";
import { QuestionnaireSummaryResponse } from "../../models/QuestionnaireSummary";
import { QuestionnaireOverviewResponse } from "../../models/QuestionnaireApi";

const options = (min: number, max: number) => {
  return {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: Math.floor((min - 5) / 5) * 5,
            max: Math.ceil((max + 5) / 5) * 5,
            stepSize: 5,
          },
        },
      ],
    },
  };
};

interface Props {
  questionnaires: QuestionnaireOverviewResponse[];
}

const createGraphData = (qs: QuestionnaireOverviewResponse[]) => {
  const data = qs.reverse().map(
    (
      q
    ): {
      label: string;
      factor1Value: number | null;
      factor2Value: number | null;
      factor3Value: number | null;
    } => {
      return {
        label: format(parseISO(q.created_at), "yyyy年MM月"),
        factor1Value: q.empathy_and_willingness_deviation_value,
        factor2Value: q.self_importance_deviation_value,
        factor3Value: q.comfort_deviation_value,
      };
    }
  );
  // 10個になるまで空のデータを詰める
  for (let i = data.length; i < 10; i++) {
    data.push({
      label: "",
      factor1Value: null,
      factor2Value: null,
      factor3Value: null,
    });
  }
  return data;
};

export default function PersonalFactorScoreTrend(props: Props): ReactElement {
  const { questionnaires } = props;
  const data = createGraphData(questionnaires);

  return (
    <>
      <SubHeader title="あなたの３因子の経年変化" />
      <div className="mt-2">
        <div className="flex justify-around mt-2 flex-wrap">
          <div className="flex justify-center items-center">
            <div
              className="w-10 relative flex justify-center items-center"
              style={{
                backgroundColor: "#0171C0",
                height: 2,
              }}
            >
              <div
                className="rounded-full h-2 w-2"
                style={{ backgroundColor: "#0171C0" }}
              />
            </div>
            <div className="text-xs text-gray-600 ml-1">第１因子</div>
          </div>
          <div className="flex justify-center items-center">
            <div
              className="w-10 relative flex justify-center items-center"
              style={{
                backgroundColor: "#F89546",
                height: 2,
              }}
            >
              <div
                className="rounded-full h-2 w-2"
                style={{ backgroundColor: "#F89546" }}
              />
            </div>
            <div className="text-xs text-gray-600 ml-1">第２因子</div>
          </div>
          <div className="flex justify-center items-center">
            <div
              className="w-10 relative flex justify-center items-center"
              style={{
                backgroundColor: "#00B050",
                height: 2,
              }}
            >
              <div
                className="rounded-full h-2 w-2"
                style={{ backgroundColor: "#00B050" }}
              />
            </div>
            <div className="text-xs text-gray-600 ml-1">第３因子</div>
          </div>
        </div>
        <div className="px-4">
          <Line
            height={350}
            data={{
              labels: data.map((d) => d.label),
              datasets: [
                {
                  label: "第１因子",
                  data: data.map((d) => d.factor1Value),
                  fill: false,
                  borderColor: "#0171C0",
                  backgroundColor: "#0171C0",
                  borderWidth: 2,
                  borderDash: [10, 5],
                },
                {
                  label: "第２因子",
                  data: data.map((d) => d.factor2Value),
                  fill: false,
                  borderColor: "#F89546",
                  backgroundColor: "#F89546",
                  borderWidth: 2,
                  borderDash: [10, 5],
                },
                {
                  label: "第３因子",
                  data: data.map((d) => d.factor3Value),
                  fill: false,
                  borderColor: "#00B050",
                  backgroundColor: "#00B050",
                  borderWidth: 2,
                  borderDash: [10, 5],
                },
              ],
            }}
            options={options(
              Math.min(
                ...data
                  .filter((d) => d.factor1Value)
                  .map((d) => d.factor1Value || 0),
                ...data
                  .filter((d) => d.factor2Value)
                  .map((d) => d.factor2Value || 0),
                ...data
                  .filter((d) => d.factor3Value)
                  .map((d) => d.factor3Value || 0)
              ),
              Math.max(
                ...data
                  .filter((d) => d.factor1Value)
                  .map((d) => d.factor1Value || 70),
                ...data
                  .filter((d) => d.factor2Value)
                  .map((d) => d.factor2Value || 70),
                ...data
                  .filter((d) => d.factor3Value)
                  .map((d) => d.factor3Value || 70)
              )
            )}
          />
        </div>
      </div>
    </>
  );
}
