import React, { ReactElement, useState } from "react";
import "@nosferatu500/react-sortable-tree/style.css";
import SortableTree, {
  toggleExpandedForAll,
  map,
  addNodeUnderParent,
  removeNodeAtPath,
  insertNode,
} from "@nosferatu500/react-sortable-tree";
import { CompanyResponse } from "../../models/Company";
import { MeasurementPeriodResponse } from "../../models/MeasurementPeriod";

interface TreeDataType {
  id: number;
  title: ReactElement;
  children?: TreeDataType[];
}

interface DepartmentHierarchyPropType {
  id: number;
  name: string;
  expanded: boolean;
  children?: DepartmentHierarchyPropType[];
}

interface Props {
  company: CompanyResponse;
  departmentHierarchies: DepartmentHierarchyPropType[];
  isMeasurementFinished: boolean;
  measurementPeriod: MeasurementPeriodResponse;
}

function setLinkTitle(
  company: CompanyResponse,
  measurementPeriod: MeasurementPeriodResponse,
  treeData: DepartmentHierarchyPropType[],
  isMeasurementFinished: boolean
): TreeDataType[] {
  return map({
    treeData,
    callback: ({ node }) => ({
      ...node,
      title: isMeasurementFinished ? (
        <a
          href={`/user/companies/${company.id}/departments/${node.id}/overview?period=${measurementPeriod.id}`}
        >
          {node.name}
        </a>
      ) : (
        <span>{node.name}</span>
      ),
    }),
    getNodeKey: ({ node }) => String(node.id),
    ignoreCollapsed: false,
  });
}

export default function DepartmentList(props: Props): ReactElement {
  const {
    company,
    departmentHierarchies: propDepartmentHierarchies,
    isMeasurementFinished,
    measurementPeriod,
  } = props;

  const [treeData, setTreeData] = useState<TreeDataType[]>(
    setLinkTitle(
      company,
      measurementPeriod,
      propDepartmentHierarchies,
      isMeasurementFinished
    )
  );

  if (treeData.length === 0) {
    return <div className="text-center p-2">部署は存在しません</div>;
  }

  return (
    <div className="department-sortable-tree__container">
      {!isMeasurementFinished && (
        <div className="text-red-600 font-bold text-sm">
          診断期間が終わっていません。
          <br />
          診断期間終了後に部署別の診断結果が参照できます。
        </div>
      )}
      <SortableTree
        getNodeKey={({ node }) => String(node.id)}
        canDrag={false}
        treeData={treeData}
        onChange={(d) => setTreeData(d)}
      />
    </div>
  );
}
