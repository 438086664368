import React, { ReactElement } from "react";
import { Radar } from "react-chartjs-2";
import { QuestionnaireSummaryResponse } from "../../models/QuestionnaireSummary";
import { MeasurementPeriodSummaryResponse } from "../../models/MeasurementPeriodSummary";

const options = {
  responsive: false,
  legend: {
    position: "top",
    onClick: () => {
      return false;
    },
  },
  scale: {
    ticks: { min: 35, max: 65, stepSize: 10 },
  },
  fill: false,
};

const columnHeader = "border-black font-normal px-2";
const rowLabel = "border-black px-4 py-2 text-center";
const contentCell = "border-black px-2 text-center";

interface Props {
  allQuestionnaireSummary: QuestionnaireSummaryResponse;
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
}

export default function ReportFactorScore(props: Props): ReactElement {
  const { allQuestionnaireSummary, measurementPeriodSummary } = props;
  return (
    <>
      <div className="mt-2 ml-2 p-2 font-bold text-lg">●因子得点</div>
      <div className="flex flex-row justify-center items-center my-3">
        <div>
          <Radar
            height={450}
            width={450}
            data={{
              labels: ["理念共感と貢献意欲", "自己有用感", "居心地の良さ"],
              datasets: [
                {
                  label: "貴団体",
                  data: [
                    measurementPeriodSummary.empathy_and_willingness_deviation_value,
                    measurementPeriodSummary.self_importance_deviation_value,
                    measurementPeriodSummary.comfort_deviation_value,
                  ],
                  fill: false,
                  borderColor: "rgba(255, 99, 132, 1)",
                  backgroundColor: "rgba(255, 99, 132, 1)",
                  borderWidth: 2,
                },
                {
                  label: "サンプル全体",
                  data: [50, 50, 50],
                  fill: false,
                  borderColor: "#F5A300",
                  backgroundColor: "#F5A300",
                  borderWidth: 2,
                },
              ],
            }}
            options={options}
          />
        </div>
        <div>
          <div className="px-3">
            <table className="table-auto">
              <thead>
                <tr>
                  <th className={`border ${columnHeader}`}>因子名称</th>
                  <th className={`border-t border-r border-b ${columnHeader}`}>
                    貴団体平均
                  </th>
                  <th className={`border-t border-r border-b ${columnHeader}`}>
                    全サンプル平均
                  </th>
                  <th className={`border-t border-r border-b ${columnHeader}`}>
                    偏差値
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={`border-l border-r border-b ${rowLabel}`}
                    style={{ backgroundColor: "#99CCFF" }}
                  >
                    第１因子
                    <br />
                    理念共感と貢献意欲
                  </td>
                  <td className={`border-r border-b ${contentCell}`}>
                    {measurementPeriodSummary.empathy_and_willingness_point?.toFixed(
                      2
                    )}
                  </td>
                  <td className={`border-r border-b ${contentCell}`}>
                    {allQuestionnaireSummary.empathy_and_willingness_point?.toFixed(
                      2
                    )}
                  </td>
                  <td className={`border-r border-b ${contentCell}`}>
                    {measurementPeriodSummary.empathy_and_willingness_deviation_value?.toFixed(
                      1
                    ) || ""}
                  </td>
                </tr>
                <tr>
                  <td
                    className={`border-l border-r border-b ${rowLabel}`}
                    style={{ backgroundColor: "#FFFFCC" }}
                  >
                    第２因子
                    <br />
                    自己有用感
                  </td>
                  <td className={`border-r border-b ${contentCell}`}>
                    {measurementPeriodSummary.self_importance_point?.toFixed(2)}
                  </td>
                  <td className={`border-r border-b ${contentCell}`}>
                    {allQuestionnaireSummary.self_importance_point?.toFixed(2)}
                  </td>
                  <td className={`border-r border-b ${contentCell}`}>
                    {measurementPeriodSummary.self_importance_deviation_value?.toFixed(
                      1
                    ) || ""}
                  </td>
                </tr>
                <tr>
                  <td
                    className={`border-l border-r border-b ${rowLabel}`}
                    style={{ backgroundColor: "#CCFFCC" }}
                  >
                    第３因子
                    <br />
                    居心地の良さ
                  </td>
                  <td className={`border-r border-b ${contentCell}`}>
                    {measurementPeriodSummary.comfort_point?.toFixed(2)}
                  </td>
                  <td className={`border-r border-b ${contentCell}`}>
                    {allQuestionnaireSummary.comfort_point?.toFixed(2)}
                  </td>
                  <td className={`border-r border-b ${contentCell}`}>
                    {measurementPeriodSummary.comfort_deviation_value?.toFixed(
                      1
                    ) || ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
