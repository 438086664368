import React, { ReactElement } from "react";
import { Radar } from "react-chartjs-2";
import { MeasurementPeriodSummaryResponse } from "../../models/MeasurementPeriodSummary";
import { filterNotEmpty } from "../../utils/ArrayUtils";

const options = (min: number, max: number) => {
  return {
    responsive: false,
    legend: {
      position: "top",
      onClick: () => {
        return false;
      },
    },
    scale: {
      ticks: {
        min: min < 40 ? min - 10 : 40,
        max: max > 60 ? max + 10 : 60,
        stepSize: 10,
      },
    },
    fill: false,
  };
};

interface Props {
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
}

export default function MindStateReportFactorScore(props: Props): ReactElement {
  const { measurementPeriodSummary } = props;

  const healthStateDeviationValues = [
    measurementPeriodSummary.depressive_tendency_deviation_value,
    measurementPeriodSummary.loneliness_deviation_value,
    measurementPeriodSummary.self_affirmation_deviation_value,
    measurementPeriodSummary.health_perception_deviation_value,
    measurementPeriodSummary.well_being_deviation_value,
  ];

  const mindsetDeviationValues = [
    measurementPeriodSummary.diversity_acceptance_deviation_value,
    measurementPeriodSummary.resilience_deviation_value,
    measurementPeriodSummary.social_trust_deviation_value,
    measurementPeriodSummary.positive_sum_thinking_deviation_value,
    measurementPeriodSummary.growth_mindset_deviation_value,
    measurementPeriodSummary.self_efficacy_deviation_value,
    measurementPeriodSummary.human_relations_thinking_deviation_value,
  ];

  return (
    <>
      <div className="mt-2 ml-2 p-2 font-bold text-lg">●チャート</div>
      <div className="flex flex-row justify-center my-3">
        <div className="w-1/2">
          <div className="p-2 font-bold text-lg text-center">心の健康状態</div>
          <div className="flex justify-center">
            <Radar
              height={400}
              width={400}
              data={{
                labels: [
                  "抑うつ傾向",
                  "孤独感の低さ",
                  "自己肯定感",
                  "主観的健康感",
                  "主観的幸福感",
                ],
                datasets: [
                  {
                    label: "貴団体",
                    data: healthStateDeviationValues,
                    fill: false,
                    borderColor: "rgba(255, 99, 132, 1)",
                    backgroundColor: "rgba(255, 99, 132, 1)",
                    borderWidth: 2,
                  },
                  {
                    label: "サンプル全体",
                    data: [50, 50, 50, 50, 50],
                    fill: false,
                    borderColor: "#F5A300",
                    backgroundColor: "#F5A300",
                    borderWidth: 2,
                  },
                ],
              }}
              options={options(
                Math.min(...filterNotEmpty<number>(healthStateDeviationValues)),
                Math.max(...filterNotEmpty<number>(healthStateDeviationValues))
              )}
            />
          </div>
          <div className="p-2 font-bold text-lg text-center mt-10">
            マインドセット
          </div>
          <div className="flex justify-center">
            <Radar
              height={400}
              width={400}
              data={{
                labels: [
                  "多様性受容",
                  "レジリエンス",
                  "社会的信頼",
                  "ポジティブサム思考",
                  "成長思考",
                  "自己効力感",
                  "人間関係思考",
                ],
                datasets: [
                  {
                    label: "貴団体",
                    data: mindsetDeviationValues,
                    fill: false,
                    borderColor: "rgba(255, 99, 132, 1)",
                    backgroundColor: "rgba(255, 99, 132, 1)",
                    borderWidth: 2,
                  },
                  {
                    label: "サンプル全体",
                    data: [50, 50, 50, 50, 50, 50, 50],
                    fill: false,
                    borderColor: "#F5A300",
                    backgroundColor: "#F5A300",
                    borderWidth: 2,
                  },
                ],
              }}
              options={options(
                Math.min(...filterNotEmpty<number>(mindsetDeviationValues)),
                Math.max(...filterNotEmpty<number>(mindsetDeviationValues))
              )}
            />
          </div>
        </div>
        <div className="w-1/2 px-5">
          <div className="p-2 border border-black relative">
            <div
              className="font-bold absolute bg-white text-lg px-2"
              style={{ top: -15, left: 5 }}
            >
              レーダーチャートの見方
            </div>
            <div className="mt-2">
              ・新質問によって調査した下記の10の項目と主観的健康感、幸福感の度合いを示しています。
              <br />
              ・全回答者の平均値を50とした団体の偏差値を計算して円グラフで表示しています。
              <br />
              ・これまでの調査ではコミュニティキャピタルが高い団体ほどレーダーチャートの円が大きくなる傾向があります。
              <br />
              <span className="text-red-600 font-bold">
                ・比較対象となる全回答者の数はまだ少ないため、今後回答者数が増えることで偏差値が変わる可能性があります。
              </span>
            </div>
          </div>
          <div className="p-2 border border-black relative mt-10">
            <div
              className="font-bold absolute bg-white text-lg px-2"
              style={{ top: -15, left: 5 }}
            >
              調査項目
            </div>
            <div className="mt-2">
              <div className="font-bold">心の健康状態</div>
              <div className="p-2">
                １．抑うつ傾向の低さ
                <br />
                ２．孤独感の低さ
                <br />
                ３．自己肯定感
                <br />
                ４．主観的健康感
                <br />
                ５．主観的幸福感
                <br />
              </div>
              <div className="font-bold mt-2">マインドセット</div>
              <div className="p-2">
                ４．多様性受容
                <br />
                ５．レジリエンス
                <br />
                ６．社会的信頼
                <br />
                ７．ポジティブサム思考
                <br />
                ８．成長思考
                <br />
                ９．自己効力感
                <br />
                １０．人間関係思考
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
