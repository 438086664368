import React, { ReactElement } from "react";
import MindStateFactorScore from "./MindStateResult/MindStateFactorScore";
import MindStateFactorDistribution from "./MindStateResult/MindStateFactorDistribution";
import { MeasurementPeriodSummaryResponse } from "../../models/MeasurementPeriodSummary";
import { CategoryDistributionResponse } from "../../models/CategoryDistribution";
import { QuestionnaireSummaryResponse } from "../../models/QuestionnaireSummary";

interface Props {
  allQuestionnaireSummary: QuestionnaireSummaryResponse;
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
  categoryDistribution: CategoryDistributionResponse;
}

export default function MindStateResult(props: Props): ReactElement {
  const {
    allQuestionnaireSummary,
    measurementPeriodSummary,
    categoryDistribution,
  } = props;

  return (
    <>
      <MindStateFactorScore
        measurementPeriodSummary={measurementPeriodSummary}
      />
      <MindStateFactorDistribution
        allQuestionnaireSummary={allQuestionnaireSummary}
        measurementPeriodSummary={measurementPeriodSummary}
        categoryDistribution={categoryDistribution}
      />
    </>
  );
}
