import React, { ReactElement, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "qrcode.react";
import ja from "date-fns/locale/ja";
import BaseModal from "../shared/BaseModal";
import Button from "../shared/Button";
import { MeasurementPeriodResponse } from "../../models/MeasurementPeriod";
import { parseISO, isAfter, format, addDays, isBefore } from "date-fns";
import { CompanyResponse } from "../../models/Company";

interface Props {
  buttonType?: "link" | "shine-btn";
  token: string;
  measurementPeriod: MeasurementPeriodResponse;
  company: CompanyResponse;
}

const subject = "【ご回答のお願い】コミュニティキャピタル診断";

const body = (
  url: string,
  company: CompanyResponse,
  measurementPeriod: MeasurementPeriodResponse
) => {
  return `みなさん

この度、${company.name}では、
ＮＰＯ法人ＣＲファクトリーと上智大学が共同研究プロジェクトの一環として行なっている
「コミュニティキャピタル診断」を実施します。

「コミュニティキャピタル診断」とは、一言でいうと
『NPO団体の組織・コミュニティの状態を測定するツール』です。

約20個の質問によって構成され「組織・コミュニティの状態」を多角的に診断することができるそうです。

組織の今の状態を測ることができ、今後の組織のマネジメントのあり方を振り返ることができる機会となるので、よろしければぜひみなさんにもご協力いただければ幸いです。
 
 
実施期間は、${format(
    parseISO(measurementPeriod.start_date),
    "yyyy年MM月dd日(E)",
    { locale: ja }
  )}〜${format(parseISO(measurementPeriod.end_date), "yyyy年MM月dd日(E)", {
    locale: ja,
  })}となります。

下記ウェブアンケートからご回答ください。
---------------------------------------------------------------

【アンケート】
「コミュニティキャピタル診断」のアンケート
${url}

※回答時間は5分〜10分程度です。
※無記名で実施し、結果は統計的に処理されますので、個人のプライバシーがもれることはありません。
 

【回答期限】
${format(parseISO(measurementPeriod.end_date), "yyyy年MM月dd日(E)", {
  locale: ja,
})}23時59分までにお願いいたします。


【フィードバックのお願い】
コミュニティキャピタル診断のサービス向上のため、可能であれば以下よりフィードバックをお願いいたします。
https://forms.gle/AaL2mdQHPHmYDpCv5
`;
};

export default function QuestionnaireUrl(props: Props): ReactElement {
  const { buttonType = "link", token, measurementPeriod, company } = props;
  const [showModal, setShowModal] = useState(false);

  const url = () => {
    if (window.location.host === "localhost:3000") {
      return encodeURI(
        `http://${window.location.host}/questionnaires/start_new?token=${token}`
      );
    } else {
      return encodeURI(
        `https://${window.location.host}/questionnaires/start_new?token=${token}`
      );
    }
  };

  let isAfterMeasurementPeriod = false;
  let isBeforeMeasurementPeriod = false;
  const today = parseISO(format(new Date(), "yyyy-MM-dd"));

  if (isBefore(parseISO(measurementPeriod.end_date), today)) {
    // 診断終了日が本日以前
    isAfterMeasurementPeriod = true;
  }
  if (isAfter(parseISO(measurementPeriod.start_date), today)) {
    // 診断開始日が本日以降
    isBeforeMeasurementPeriod = true;
  }

  const mailBody = body(url(), company, measurementPeriod);

  return (
    <>
      {showModal && (
        <BaseModal
          contentLabel="QuestionnaireUrl"
          show={showModal}
          title="アンケートURL・依頼テンプレート"
          onCloseModal={() => setShowModal(false)}
        >
          <BaseModal.Body>
            <>
              {isAfterMeasurementPeriod && (
                <div className="text-center p-3">診断期間を過ぎています</div>
              )}
              {isBeforeMeasurementPeriod && (
                <div className="text-center p-3">
                  診断期間前です
                  <br />
                  診断期間になりましたら、登録いただいているメールアドレスにご連絡いたします。
                </div>
              )}
              {!isAfterMeasurementPeriod && !isBeforeMeasurementPeriod && (
                <>
                  <div className="text-center">
                    以下のURLをメンバーの方々に共有し、回答を依頼してください。
                  </div>
                  <div className="mt-3">
                    <input
                      id="company-overview-url-box"
                      type="text"
                      disabled={false}
                      defaultValue={url()}
                      className="w-full rounded border border-quaternary px-2 py-1"
                    />
                  </div>
                  <div className="mt-3 flex justify-center">
                    <CopyToClipboard
                      text={url()}
                      onCopy={() => {
                        window.alert("コピーしました");
                      }}
                    >
                      <button className="primary-button py-1 px-3">
                        URLをコピー
                      </button>
                    </CopyToClipboard>
                  </div>
                  <div className="mt-8 text-sm text-center">
                    以下はメンバーに依頼をする際のメール文面の例・QRコードです
                    <br />
                    必要があればご利用ください
                  </div>
                  <div className="mt-2">
                    <div className="opacity-75 text-sm">件名</div>
                  </div>
                  <div>
                    <input
                      type="text"
                      disabled={false}
                      defaultValue={subject}
                      className="w-full rounded border border-quaternary px-2 py-1"
                    />
                  </div>
                  <div className="mt-3 flex justify-center">
                    <CopyToClipboard
                      text={subject}
                      onCopy={() => {
                        window.alert("件名をコピーしました");
                      }}
                    >
                      <button className="primary-button py-1 px-3">
                        件名をコピー
                      </button>
                    </CopyToClipboard>
                  </div>
                  <div className="mt-2">
                    <div className="opacity-75 text-sm">本文</div>
                  </div>
                  <div>
                    <textarea
                      defaultValue={mailBody}
                      className="w-full border border-quaternary rounded p-2 text-sm"
                      rows={10}
                    />
                  </div>
                  <div className="mt-3 flex justify-center">
                    <CopyToClipboard
                      text={mailBody}
                      onCopy={() => {
                        window.alert("本文をコピーしました");
                      }}
                    >
                      <button className="primary-button py-1 px-3">
                        本文をコピー
                      </button>
                    </CopyToClipboard>
                  </div>
                  <div className="flex justify-center my-10">
                    <QRCode value={url()} />
                  </div>
                </>
              )}
            </>
          </BaseModal.Body>
          <BaseModal.Footer className="flex justify-end">
            <Button
              title="閉じる"
              className="sub-button px-3 py-1"
              onClick={() => setShowModal(false)}
            />
          </BaseModal.Footer>
        </BaseModal>
      )}
      {buttonType === "link" && (
        <a className="cursor-pointer" onClick={() => setShowModal(true)}>
          アンケートURL・依頼テンプレート
        </a>
      )}
      {buttonType === "shine-btn" && (
        <div className="shine-btn" onClick={() => setShowModal(true)}>
          <span>アンケートURL・依頼テンプレート</span>
        </div>
      )}
    </>
  );
}
