import React, { ReactElement } from "react";
import { Bar } from "react-chartjs-2";
import AttributeScoreCell from "../shared/AttributeScoreCell";
const tableCell = "border px-1 text-xs text-center whitespace-no-wrap";
interface Props {
  options: any;
}

export default function ReportItemSpecificFactorScore2(
  props: Props
): ReactElement {
  const { options } = props;
  return (
    <>
      <h5 className="text-center text-sm">
        団体内での報酬による
        <br />
        因子得点平均値の比較
      </h5>
      <div>
        <div className="p-2">
          <Bar
            height={200}
            width={350}
            data={{
              labels: ["理念共感と貢献意欲", "自己有用感", "居心地の良さ"],
              datasets: [
                {
                  // barThickness: 10,
                  label: "有給である",
                  data: [9.1, 5.83, 7.92],
                  borderColor: "#FEFF02",
                  backgroundColor: "#FEFF02",
                },
                {
                  // barThickness: 10,
                  label: "有給でない",
                  data: [6.38, 5.67, 8.21],
                  borderColor: "#F5A300",
                  backgroundColor: "#F5A300",
                },
                {
                  // barThickness: 10,
                  label: "わからない",
                  data: [0, 0, 0],
                  borderColor: "#92D050",
                  backgroundColor: "#92D050",
                },
              ],
            }}
            options={options}
          />
        </div>
        <div>
          <div className="px-3">
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="border font-normal text-xs px-2"></th>
                  <th className="border font-normal text-xs px-2">
                    理念共感と貢献意欲
                  </th>
                  <th className="border font-normal text-xs px-2">
                    自己有用感
                  </th>
                  <th className="border font-normal text-xs px-2">
                    居心地の良さ
                  </th>
                </tr>
              </thead>
              <tbody>
                {[
                  {
                    title: "あり",
                    color: "#FEFF02",
                    answeredCount: 10,
                    point1: 9.1,
                    point2: 5.83,
                    point3: 7.92,
                  },
                  {
                    title: "なし",
                    color: "#F5A300",
                    answeredCount: 10,
                    point1: 9.38,
                    point2: 5.67,
                    point3: 8.21,
                  },
                  {
                    title: "わからない",
                    color: "#92D050",
                    answeredCount: 0,
                    point1: 0,
                    point2: 0,
                    point3: 0,
                  },
                ].map((d) => (
                  <tr key={d.title}>
                    <td className="border px-1 text-xs">
                      <div className="flex items-center">
                        <div
                          className="rounded h-3 w-3"
                          style={{ backgroundColor: d.color }}
                        />
                        <div className="ml-2">{d.title}</div>
                      </div>
                    </td>
                    <AttributeScoreCell
                      answeredCount={d.answeredCount}
                      point={d.point1}
                      averagePoint={9.31}
                      className={tableCell}
                    />
                    <AttributeScoreCell
                      answeredCount={d.answeredCount}
                      point={d.point2}
                      averagePoint={5.71}
                      className={tableCell}
                    />
                    <AttributeScoreCell
                      answeredCount={d.answeredCount}
                      point={d.point3}
                      averagePoint={8.14}
                      className={tableCell}
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
