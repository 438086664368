import React, { ReactElement, ChangeEvent } from "react";
import HiddenField from "./fields/HiddenField";
import { fieldId, fieldName, i18nKey } from "../../../utils/AttributeUtils";

interface Props {
  scope: string;
  columnName: string;
  relatedModelName?: string;
  index?: number;
  title: string;
  defaultChecked?: boolean;
  activeKey?: string;
  disableKey?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  subTitle?: string;
}

export default function CheckboxForm(props: Props): ReactElement {
  const {
    scope,
    relatedModelName,
    columnName,
    index,
    title,
    defaultChecked = false,
    activeKey = "1",
    disableKey = "0",
    onChange,
    disabled = false,
    subTitle,
  } = props;
  return (
    <div>
      <input
        name={fieldName(scope, relatedModelName, columnName, index)}
        type="hidden"
        value={disableKey}
      />
      <input
        className="form-check-input"
        type="checkbox"
        value={activeKey}
        name={fieldName(scope, relatedModelName, columnName, index)}
        id={`${fieldId(
          scope,
          relatedModelName,
          columnName,
          index
        )}_${activeKey}`}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={(e) => onChange && onChange(e)}
      />
      {disabled && (
        <HiddenField
          name={fieldName(scope, relatedModelName, columnName, index)}
          id={`${fieldId(
            scope,
            relatedModelName,
            columnName,
            index
          )}_${activeKey}`}
          value={defaultChecked ? activeKey : disableKey}
        />
      )}
      <label
        htmlFor={`${fieldId(
          scope,
          relatedModelName,
          columnName,
          index
        )}_${activeKey}`}
        className="p-1 ml-2 text-sm"
      >
        {title}
        {subTitle && <span>{`（${subTitle}）`}</span>}
      </label>
    </div>
  );
}
