import React, { ReactElement } from 'react'

interface Props {
  greeting: string
}

export default function Test (props: Props): ReactElement {
  const { greeting } = props
  return (
    <div className="mt-2 bg-yellow-500">
      {greeting}
    </div>
  )
}
