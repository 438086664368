import React, { ReactElement, useState } from "react";
import BaseMoal from "../../../../shared/BaseModal";
import Button from "../../../../shared/Button";
import { deleteRequest } from "../../../../../utils/ApiClient";
import {
  DepartmentNode,
  DepartmentResponse,
} from "../../../../../models/Department";
import BaseModal from "../../../../shared/BaseModal";
import BaseForm, { ajaxSubmit } from "../../../../shared/BaseForm";
import HiddenForm from "../../../../shared/forms/HiddenForm";
import TextForm from "../../../../shared/forms/TextForm";
import { CompanyResponse } from "../../../../../models/Company";
import { Props as FlushMessageProps } from "../../../../shared/FlushMessage";
import { getMilliseconds } from "date-fns";
import { MeasurementPeriodResponse } from "../../../../../models/MeasurementPeriod";

interface Props {
  company: CompanyResponse;
  measurementPeriod: MeasurementPeriodResponse;
  node: DepartmentNode;
  onCloseButtonClicked: () => void;
  beforeSave: () => void;
  afterSave: (department?: DepartmentResponse) => void;
  onSwitchToMenu?: () => void;
}

export default function EditModalBody(props: Props): ReactElement {
  const {
    company,
    measurementPeriod,
    node,
    onCloseButtonClicked,
    beforeSave,
    afterSave,
    onSwitchToMenu,
  } = props;
  const [flushMessage, setFlushMessage] = useState<FlushMessageProps>();

  const save = async (): Promise<void> => {
    if (
      !window.confirm(
        "名前を変更してよろしいですか？\n他の診断期間の部署名も変更されますのでご注意ください。"
      )
    ) {
      return;
    }
    const form = document.forms["EditDepartmentForm"];
    if (form.checkValidity()) {
      beforeSave();
      const { result, error } = await ajaxSubmit<DepartmentResponse>(form);
      if (result && result.status === 200) {
        const editedDepartment = result.data;
        afterSave(editedDepartment);
      } else {
        if (error) {
          setFlushMessage({
            title: "エラーが発生しました",
            messages: error.data.messages,
            milliseconds: getMilliseconds(new Date()),
          });
        }
        afterSave();
      }
    } else {
      const submit = document.getElementById("EditDepartmentFormSubmit");
      if (submit) {
        submit.click();
      }
    }
  };
  return (
    <>
      <BaseForm
        name="EditDepartmentForm"
        action={`/admin/companies/${company.id}/measurement_periods/${measurementPeriod.id}/departments/${node.id}`}
        method="put"
      >
        <BaseMoal.Body flushMessage={flushMessage}>
          {onSwitchToMenu && (
            <div className="flex justify-start">
              <Button
                title={`< 戻る`}
                onClick={onSwitchToMenu}
                className="text-sm text-gray-700 p-2"
              />
            </div>
          )}
          <div className="container-fluid">
            <div>
              <TextForm
                model={{ name: node.name }}
                scope="department"
                columnName="name"
                label="部署名"
                required
              />
            </div>
          </div>
          <button
            type="submit"
            id="EditDepartmentFormSubmit"
            style={{ border: "none", padding: 0 }}
          />
        </BaseMoal.Body>
        <BaseMoal.Footer className="flex justify-between items-center">
          <Button
            className="text-gray-600 p-2 text-sm"
            onClick={onCloseButtonClicked}
            title="閉じる"
          />
          <Button
            title="更新"
            className="admin-button px-3 py-1"
            onClick={save}
          />
        </BaseMoal.Footer>
      </BaseForm>
    </>
  );
}
