import React, { useEffect, useState } from "react";
import MenuIcon from "../../../shared/images/TreePointIcon.png";
import {
  DepartmentNode,
  DepartmentResponse,
} from "../../../../models/Department";
import ActionModal from "./ActionModal";
import { CompanyResponse } from "../../../../models/Company";
import { MeasurementPeriodResponse } from "../../../../models/MeasurementPeriod";

interface Props {
  company: CompanyResponse;
  measurementPeriod: MeasurementPeriodResponse;
  node: DepartmentNode;
  afterCreated: (department: DepartmentResponse) => void;
  afterEdited: (department: DepartmentResponse) => void;
  afterDeleted: (nodePath: number[]) => void;
}

export default function MenuButton(props: Props) {
  const {
    company,
    measurementPeriod,
    node,
    afterCreated,
    afterEdited,
    afterDeleted,
  } = props;
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {showModal && (
        <ActionModal
          company={company}
          measurementPeriod={measurementPeriod}
          node={node}
          showModal={showModal}
          onClose={() => setShowModal(false)}
          afterCreated={(d) => {
            setShowModal(false);
            afterCreated(d);
          }}
          afterEdited={(d) => {
            setShowModal(false);
            afterEdited(d);
          }}
          afterDeleted={(path) => {
            setShowModal(false);
            afterDeleted(path);
          }}
        />
      )}
      <div
        role="button"
        tabIndex={0}
        style={{
          fontSize: 14,
          cursor: "pointer",
          color: "gray",
          marginRight: 5,
        }}
        onClick={() => setShowModal(true)}
      >
        <img
          src={MenuIcon}
          alt="menu icon"
          style={{ height: 17, marginTop: -2 }}
        />
      </div>
    </>
  );
}
