function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function filterNotEmpty<T>(arr: Array<T | null | undefined>): T[] {
  return arr.filter(notEmpty);
}

export function addNullElement<T>(array: T[]): Array<T | null> {
  const temp: Array<T | null> = array.concat();
  temp.push(null);
  return temp;
}
