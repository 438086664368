import React, { ReactElement } from "react";
import { Bar } from "react-chartjs-2";
import AttributeScoreCell from "../shared/AttributeScoreCell";

interface Props {
  options: any;
}

export default function ReportItemSpecificFactorScore1(
  props: Props
): ReactElement {
  const { options } = props;
  return (
    <>
      <h5 className="text-center text-sm">
        団体内でのポジションによる
        <br />
        因子得点平均値の比較
      </h5>
      <div>
        <div className="p-2">
          <Bar
            height={200}
            width={350}
            data={{
              labels: ["理念共感と貢献意欲", "自己有用感", "居心地の良さ"],
              datasets: [
                {
                  // barThickness: 10,
                  label: "代表・副代表",
                  data: [8.89, 6.53, 9.03],
                  borderColor: "#FEFF02",
                  backgroundColor: "#FEFF02",
                },
                {
                  // barThickness: 10,
                  label: "理事・幹事",
                  data: [8.23, 5.68, 8.54],
                  borderColor: "#F5A300",
                  backgroundColor: "#F5A300",
                },
                {
                  // barThickness: 10,
                  label: "運営会議メンバー",
                  data: [8.42, 5.56, 7.75],
                  borderColor: "#92D050",
                  backgroundColor: "#92D050",
                },
              ],
            }}
            options={options}
          />
        </div>
        <div>
          <div className="px-3">
            <table>
              <thead>
                <tr>
                  <th className="border font-normal text-xs px-2"></th>
                  <th className="border font-normal text-xs w-20">
                    理念共感と
                    <br />
                    貢献意欲
                  </th>
                  <th className="border font-normal text-xs w-20">
                    自己有用感
                  </th>
                  <th className="border font-normal text-xs w-20">
                    居心地の良さ
                  </th>
                </tr>
              </thead>
              <tbody>
                {[
                  {
                    title: "代表・副代表",
                    color: "#FEFF02",
                    point1: 8.89,
                    point2: 6.53,
                    point3: 9.03,
                  },
                  {
                    title: "理事・幹事",
                    color: "#F5A300",
                    point1: 8.23,
                    point2: 5.68,
                    point3: 8.54,
                  },
                  {
                    title: "運営会議メンバー",
                    color: "#92D050",
                    point1: 8.42,
                    point2: 5.56,
                    point3: 7.75,
                  },
                ].map((d) => (
                  <tr key={d.title}>
                    <td className="border px-1 text-xs">
                      <div className="flex items-center">
                        <div
                          className="rounded h-3 w-3"
                          style={{ backgroundColor: d.color }}
                        />
                        <div className="ml-2">{d.title}</div>
                      </div>
                    </td>
                    <AttributeScoreCell
                      answeredCount={10}
                      point={d.point1}
                      averagePoint={9.31}
                      className="border px-1 text-xs text-center whitespace-no-wrap"
                    />
                    <AttributeScoreCell
                      answeredCount={10}
                      point={d.point2}
                      averagePoint={5.71}
                      className="border px-1 text-xs text-center whitespace-no-wrap"
                    />
                    <AttributeScoreCell
                      answeredCount={10}
                      point={d.point3}
                      averagePoint={8.14}
                      className="border px-1 text-xs text-center whitespace-no-wrap"
                    />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
