import React, { ReactElement, useState } from "react";
import Button from "../../shared/Button";
import BaseModal from "../../shared/BaseModal";
import TextForm from "../../shared/forms/TextForm";
import {
  primaryButtonClassName,
  subButtonClassName,
} from "../../../utils/ClassNameUtils";

interface Props {
  managerialPosition: {
    id?: number;
    name: string;
    tempId?: string;
  };
  onChange: (name: string) => void;
  onClose: () => void;
  onDelete: () => void;
}

export default function ManagerialPositionEditForm(props: Props): ReactElement {
  const { managerialPosition, onChange, onClose, onDelete } = props;
  const [value, setValue] = useState(managerialPosition.name);

  const save = (): void => {
    onChange(value);
  };

  const destroy = (): void => {
    if (confirm("削除してよろしいですか？")) {
      onDelete();
    }
  };

  return (
    <>
      <BaseModal.Body>
        <div>
          <TextForm
            model={managerialPosition}
            scope="managerial_position"
            columnName="name"
            hideLabel
            onChange={(e) => setValue(e.target.value)}
            fucus
          />
        </div>
      </BaseModal.Body>
      <BaseModal.Footer className="flex justify-between">
        <Button
          title="削除"
          className="opacity-50 text-sm px-3 py-1"
          onClick={destroy}
        />
        <div className="flex justify-end">
          <Button
            title="閉じる"
            className={`${subButtonClassName()} px-3 py-1`}
            onClick={onClose}
          />
          <Button
            title="保存"
            className={`${primaryButtonClassName()} ml-4 px-4 py-1`}
            onClick={save}
          />
        </div>
      </BaseModal.Footer>
    </>
  );
}
