import React, { ReactElement } from "react";
import FactorScore from "./CommunityCapitalDemoResult/FactorScore";
import FactorScoreDistribution from "./CommunityCapitalDemoResult/FactorScoreDistribution";
import RespondentDistribution from "./CommunityCapitalDemoResult/RespondentDistribution";
import ItemSpecificFactorScores from "./CommunityCapitalDemoResult/ItemSpecificFactorScores";

export default function CommunityCapitalDemoResult(): ReactElement {
  return (
    <>
      <FactorScore />
      <FactorScoreDistribution />
      <RespondentDistribution />
      <ItemSpecificFactorScores />
    </>
  );
}
