import React, { ReactElement, useState } from "react";
import Button from "../../shared/Button";
import { UserResponse } from "../../../models/User";
import AdminUserEditModal from "./AdminUserEditModal";

interface Props {
  afterSaved: (user: UserResponse) => void;
}

export default function AdminUserAddButton(props: Props): ReactElement {
  const { afterSaved } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <AdminUserEditModal
          onClose={() => setShowModal(false)}
          afterSaved={(u) => {
            setShowModal(false);
            afterSaved(u);
          }}
        />
      )}
      <Button
        className="admin-button px-2 py-1"
        title="メンバー追加"
        onClick={() => setShowModal(true)}
      />
    </>
  );
}
