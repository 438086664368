import React, { ReactElement } from "react";
import axios, { AxiosResponse } from "axios";

// form: form object
//  ex: document.forms.{formName} で取得した結果
export async function ajaxSubmit<T>(form): Promise<{
  result?: AxiosResponse<T>;
  error?: { data: { messages: string[] } };
}> {
  const formData = new FormData(form);
  try {
    const r = await axios({
      method: form.method,
      url: `${form.action}.json`,
      data: formData,
    });
    return { result: r };
  } catch (e) {
    return { error: e.response };
  }
}

interface Props {
  name: string;
  action: string;
  method: "post" | "put" | "patch" | "get" | "delete";
  children?: React.ReactNode;
  type?: "ajax" | "normal";
  authenticityToken?: string;
  className?: string;
}

function defaultCsrfToken(): string {
  return axios.defaults.headers["X-CSRF-TOKEN"];
}

export default function BaseForm(props: Props): ReactElement {
  const {
    name,
    action,
    method,
    children,
    authenticityToken = defaultCsrfToken(),
    type = "ajax",
    className = "",
  } = props;

  return (
    <form
      name={name}
      action={action}
      method={method === "get" ? "get" : "post"}
      onSubmit={(e) => {
        if (type === "ajax") {
          e.preventDefault();
        }
      }}
      className={className}
    >
      <input name="utf8" type="hidden" value="✓" />
      {authenticityToken !== "" && (
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticityToken}
        />
      )}
      {(method === "put" || method === "patch") && (
        <input type="hidden" name="_method" value="put" />
      )}
      {method === "delete" && (
        <input type="hidden" name="_method" value="delete" />
      )}
      {children}
    </form>
  );
}
