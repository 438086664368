import React, { ReactElement, useState } from "react";
import Button from "../shared/Button";
import SubHeader from "../shared/SubHeader";
import QuestionEditModal from "./QuestionEditModal";
import { QuestionIndexResponse } from "../../models/QuestionApi";
import QuestionItem from "./QuestionItem";
import SortableQuestionList from "./SortableQuestionList";
import BaseForm from "../shared/BaseForm";
import BaseModal from "../shared/BaseModal";
import { SortEndHandler, arrayMove } from "react-sortable-hoc";
import { MeasurementPeriodResponse } from "../../models/MeasurementPeriod";
import HiddenForm from "../shared/forms/HiddenForm";

interface Props {
  questions: QuestionIndexResponse[];
  measurementPeriod: MeasurementPeriodResponse;
  answeredCount: number;
}

export default function QuestionList(props: Props): ReactElement {
  const {
    questions: initialQuestions,
    measurementPeriod,
    answeredCount,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [isSortMode, setSortMode] = useState(false);
  const [questions, setQuestions] = useState(initialQuestions);

  const isEditable = answeredCount === 0;

  const handleDragEnd: SortEndHandler = ({ oldIndex, newIndex }): void => {
    const oldQuestions = questions.concat([]);
    const newQuestions = arrayMove(oldQuestions, oldIndex, newIndex);
    setQuestions(newQuestions);
  };

  const addQuestion = (q: QuestionIndexResponse): void => {
    setQuestions(questions.concat([q]));
  };

  const updateQuestion = (index: number, q: QuestionIndexResponse): void => {
    const arr = questions.concat();
    arr[index] = Object.assign({}, questions[index], q);
    setQuestions(arr);
  };

  const deleteQuestion = (index: number): void => {
    const arr = questions.concat();
    arr.splice(index, 1);
    setQuestions(arr);
  };

  return (
    <>
      {showModal && (
        <QuestionEditModal
          isEditable={isEditable}
          show={showModal}
          measurementPeriod={measurementPeriod}
          onClose={() => setShowModal(false)}
          afterSaved={(q) => {
            addQuestion(q);
            setShowModal(false);
            window.Alert.success("質問を追加しました");
          }}
        />
      )}
      {!isEditable && (
        <div className="p-3 text-red-600 bg-quinary my-3 font-bold">
          既に回答が存在するため、変更することはできません
        </div>
      )}
      {isEditable && (
        <>
          <div className="flex justify-end mt-2 ">
            <Button
              onClick={() => setSortMode(true)}
              title="並べ替え"
              className={`sub-button px-3 py-1 ${
                isSortMode ? "opacity-50" : ""
              }`}
              disabled={isSortMode}
            />
            <Button
              onClick={() => setShowModal(true)}
              title="追加"
              className={`primary-button px-6 py-1 ml-3 ${
                isSortMode ? "opacity-50" : ""
              }`}
              disabled={isSortMode}
            />
          </div>
        </>
      )}
      <div className="mt-4">
        <SubHeader title={isSortMode ? "追加質問 (並べ替え)" : "追加質問"} />
        {isSortMode && (
          <div className="flex flex-wrap justify-between items-center py-3">
            <div className="text-sm opacity-75">
              ドラッグ&ドロップで並べ替えが可能です。
              <br />
              右記保存ボタンを押して並び替えを完了させてください。
            </div>
            <div className="flex justify-end w-full md:w-auto">
              <Button
                onClick={() => {
                  if (
                    window.confirm("並べ替えをリセットしてよろしいですか？")
                  ) {
                    setSortMode(false);
                    setQuestions(initialQuestions);
                  }
                }}
                title="元に戻す"
                className="sub-button px-4 py-1 text-sm"
              />
              <BaseForm
                action={`/user/measurement_periods/${measurementPeriod.id}/questions/update_all`}
                name="QuestionPositionsEditForm"
                method="put"
                type="normal"
              >
                {questions.map((q, i) => (
                  <React.Fragment key={q.id}>
                    <HiddenForm
                      scope="measurement_period"
                      relatedModelName="questions"
                      index={i}
                      columnName="id"
                      value={q.id}
                    />
                    <HiddenForm
                      scope="measurement_period"
                      relatedModelName="questions"
                      index={i}
                      columnName="position"
                      value={i + 1}
                    />
                  </React.Fragment>
                ))}
                <button
                  type="submit"
                  className="primary-button px-4 py-1 text-sm ml-3"
                >
                  保存
                </button>
              </BaseForm>
            </div>
          </div>
        )}
      </div>
      {questions.length === 0 && (
        <div className="p-4 text-sm opacity-75 text-center">
          質問が未設定です
          <br />
          追加ボタンからオリジナルの質問を追加できます
        </div>
      )}
      {questions.length > 0 && (
        <>
          {!isSortMode &&
            questions.map((q, i) => (
              <QuestionItem
                key={q.id}
                isEditable={isEditable}
                measurementPeriod={measurementPeriod}
                question={q}
                afterSaved={(q) => {
                  updateQuestion(i, q);
                  window.Alert.success("質問の編集に成功しました");
                }}
                afterDeleted={() => deleteQuestion(i)}
              />
            ))}
          {isSortMode && (
            <SortableQuestionList
              questions={questions}
              onSortEnd={handleDragEnd}
            />
          )}
        </>
      )}
    </>
  );
}
