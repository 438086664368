import React, { ReactElement, useState } from "react";
import Button from "../shared/Button";
import QuestionEditModal from "./QuestionEditModal";
import { QuestionIndexResponse } from "../../models/QuestionApi";
import MultiLineText from "../shared/MultiLineText";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MeasurementPeriodResponse } from "../../models/MeasurementPeriod";

interface Props {
  question: QuestionIndexResponse;
  isEditable: boolean;
  measurementPeriod: MeasurementPeriodResponse;
  afterSaved: (editedQuestion: QuestionIndexResponse) => void;
  afterDeleted: () => void;
}

function viewAnswerFormat(q: QuestionIndexResponse) {
  switch (q.answer_format) {
    case "title_format":
      return "タイトル";
    case "description_format":
      return "説明";
    case "text_format":
      return "テキストボックス";
    case "select_format":
      return "セレクトボックス";
    case "checkbox_format":
      return "チェックボックス";
  }
}

export default function QuestionItem(props: Props): ReactElement {
  const { question, measurementPeriod, afterSaved, afterDeleted, isEditable } =
    props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <QuestionEditModal
          question={question}
          isEditable={isEditable}
          measurementPeriod={measurementPeriod}
          show={showModal}
          onClose={() => setShowModal(false)}
          afterSaved={(q) => {
            setShowModal(false);
            afterSaved(q);
          }}
          afterDeleted={afterDeleted}
        />
      )}
      <div className="border border-quaternary rounded p-2 mt-3 flex justify-between items-center">
        <div>
          <div className="text-xs opacity-50 flex">
            <div>{viewAnswerFormat(question)}</div>
            {question.required_flg && <div className="ml-3">必須</div>}
          </div>
          <div className="p-1">
            <MultiLineText text={question.body} />
          </div>
        </div>
        <div>
          <Button
            onClick={() => setShowModal(true)}
            className="text-sm opacity-50 p-2"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </div>
      </div>
    </>
  );
}
