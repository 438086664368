import React, { ReactElement, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ShareIcon from "./images/ShareIcon.png";
import BaseModal from "./BaseModal";
import Button from "./Button";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseForm from "./BaseForm";

// interface Props {
// }

const shareUrl = window.location.href;

export default function AccountLink(): ReactElement {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <div className="flex relative text-senary">
        <Button
          className="cursor-pointer"
          onClick={() => setShowMenu(!showMenu)}
        >
          <div className="flex items-center text-xl p-2">
            <FontAwesomeIcon icon={faUser} className="opacity-75" />
          </div>
        </Button>
        {showMenu && (
          <>
            <div className="fixed inset-0" onClick={() => setShowMenu(false)} />
            <div
              className="bg-white px-4 py-1 absolute right-0 border whitespace-no-wrap"
              style={{ top: 30 }}
            >
              <Button
                className="p-2 text-sm border-b"
                title="アカウント情報"
                onClick={() => (window.location.href = "/user")}
              />
              <BaseForm
                action="/users/sign_out"
                method="delete"
                name="logoutForm"
                type="normal"
              >
                <button className="p-2 text-sm w-full">ログアウト</button>
              </BaseForm>
            </div>
          </>
        )}
      </div>
    </>
  );
}
