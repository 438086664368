import React, { ReactElement } from "react";
import ReportFactorScoreTrend from "./ReportFactorScoreTrend";

const companyInfoLabel = "border-black px-4 py-2 text-sm text-center";
const companyInfoValue = "border-black px-2 text-sm text-center";
const factorTableHeader =
  "border-black font-normal px-2 bg-gray-600 text-white font-bold";
const factorDescription = "border-black px-4 text-center text-xs p-1";
const factorPoint = "border-black p-2 text-center font-bold text-lg";
const subInfoLabel = "border-black px-2 text-sm text-center";
const subInfoValue = "border-black px-2 text-center";

export default function ReportCover(): ReactElement {
  return (
    <div className="flex flex-col justify-center h-full p-3">
      <div className="text-center text-xl font-bold">
        コミュニティキャピタル診断
        <br />
        診断結果レポート
      </div>
      <div className="text-center mt-2">2020年8月20日</div>

      <div className="flex justify-center w-full mt-3">
        <table className="w-4/5">
          <tbody>
            <tr>
              <td className={`border ${companyInfoLabel}`}>団体名</td>
              <td className={`border-t border-r border-b ${companyInfoValue}`}>
                〇〇法人
              </td>
            </tr>
            <tr>
              <td className={`border-l border-r border-b ${companyInfoLabel}`}>
                担当者名
              </td>
              <td className={`border-r border-b ${companyInfoValue}`}>
                〇〇様
              </td>
            </tr>
            <tr>
              <td className={`border-l border-r border-b ${companyInfoLabel}`}>
                回答者数
              </td>
              <td className={`border-r border-b ${companyInfoValue}`}>21名</td>
            </tr>
            <tr>
              <td className={`border-l border-r border-b ${companyInfoLabel}`}>
                実施期間
              </td>
              <td className={`border-r border-b ${companyInfoValue}`}>
                2021年12月1日〜12月14日
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-5">
        <table>
          <thead>
            <tr>
              <th className={`border ${factorTableHeader}`} colSpan={2}>
                第1因子
                <br />
                「理念共感と貢献意欲」
              </th>
              <th
                className={`border-t border-r border-b ${factorTableHeader}`}
                colSpan={2}
              >
                第2因子
                <br />
                「自己有用感」
              </th>
              <th
                className={`border-t border-r border-b ${factorTableHeader}`}
                colSpan={2}
              >
                第3因子
                <br />
                「居心地の良さ」
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className={`border-l border-r border-b ${factorDescription}`}
                colSpan={2}
              >
                第1の因子は、団体の活動理念への共感とその活動への貢献意欲に関するものです。同じ集団に属する人間として、仲間と共通の目的や目標を共有し、共にそれを目指そうと思えること。「この団体を自分も一緒に担っていきたい」「とことんがんばりたい」と思える感覚が第1の因子です。
              </td>
              <td
                className={`border-r border-b ${factorDescription}`}
                colSpan={2}
              >
                第2の因子は、団体の中で自分が役に立っている、必要とされているという感覚です。ただ団体の活動に参加するだけでなく、活動・関わりを通して「役に立っている」「必要とされている」と感じられること。欠かせない存在であり、自分は重要であると思える感覚が第2の因子です。
              </td>
              <td
                className={`border-r border-b ${factorDescription}`}
                colSpan={2}
              >
                第3の因子は、居場所としての団体の状態です。それも物理的に快適であるということではなく、「人間関係が良好である」「メンバーと一緒に活動することが楽しい」「仲間といると落ち着く」と感じられること。これらによる「居心地の良さ」が第3の因子です。
              </td>
            </tr>
            <tr>
              <td
                className={`border-l border-r border-b ${factorPoint}`}
                colSpan={2}
              >
                9.31
              </td>
              <td className={`border-r border-b ${factorPoint}`} colSpan={2}>
                5.71
              </td>
              <td className={`border-r border-b ${factorPoint}`} colSpan={2}>
                8.14
              </td>
            </tr>
            <tr>
              <td className={`border-l border-r border-b ${subInfoLabel}`}>
                一般偏差値
              </td>
              <td className={`border-r border-b ${subInfoValue}`}>56.0</td>
              <td className={`border-r border-b ${subInfoLabel}`}>
                一般偏差値
              </td>
              <td className={`border-r border-b ${subInfoValue}`}>47.34</td>
              <td className={`border-r border-b ${subInfoLabel}`}>
                一般偏差値
              </td>
              <td className={`border-r border-b ${subInfoValue}`}>51.49</td>
            </tr>
            <tr>
              <td className={`border-l border-r border-b ${subInfoLabel}`}>
                前回得点
              </td>
              <td className={`border-r border-b ${subInfoValue}`}>8.81</td>
              <td className={`border-r border-b ${subInfoLabel}`}>前回得点</td>
              <td className={`border-r border-b ${subInfoValue}`}>6.64</td>
              <td className={`border-r border-b ${subInfoLabel}`}>前回得点</td>
              <td className={`border-r border-b ${subInfoValue}`}>7.71</td>
            </tr>
            <tr>
              <td className={`border-l border-r border-b ${subInfoLabel}`}>
                前回診断との差
              </td>
              <td className={`border-r border-b ${subInfoValue}`}>+0.50</td>
              <td className={`border-r border-b ${subInfoLabel}`}>
                前回診断との差
              </td>
              <td className={`border-r border-b ${subInfoValue}`}>-0.93</td>
              <td className={`border-r border-b ${subInfoLabel}`}>
                前回診断との差
              </td>
              <td className={`border-r border-b ${subInfoValue}`}>+0.43</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-2">
        <ReportFactorScoreTrend />
      </div>
    </div>
  );
}
