import React, { ReactElement, useState } from "react";
import Button from "../../shared/Button";
import BaseModal from "../../shared/BaseModal";

interface Props {
  onReturn: () => void;
  onClose: () => void;
}

export default function BankTransferModalContent(props: Props): ReactElement {
  const { onReturn, onClose } = props;
  return (
    <>
      <BaseModal.Body>
        <div className="font-bold text-center text-lg">
          金額
          <br />
          5500円(税込)
        </div>
        <div className="text-center text-sm mt-4">
          以下口座に振込を行い、
          <br />「{window.ClientSideEnv.SUPPORT_MAIL_ADDRESS}
          」まで振込を行った旨ご連絡下さい。
          <br />
          振込が確認出来次第、
          <br />
          診断結果が参照できるようになります
          <br />
          <br />
          <span className="text-xs">
            ※領収書が必要な方はご連絡の際にその旨をお伝えください
            <br />
            ※確認に2-3日頂きます
          </span>
        </div>
        <div className="w-4/5 m-auto mt-2">
          {[
            { label: "銀行名", value: "みずほ銀行" },
            { label: "支店名", value: "恵比寿支店" },
            { label: "預金種類", value: "普通口座" },
            { label: "口座番号", value: "1213342" },
            { label: "口座名義", value: "特定非営利活動法人CRファクトリー" },
          ].map((d, i, arr) => (
            <div key={d.label} className="flex items-center">
              <div
                className={`w-1/4 p-1 text-center border-t border-l border-r ${
                  i === arr.length - 1 ? "border-b" : ""
                } border-quaternary`}
              >
                {d.label}
              </div>
              <div
                className={`w-3/4 border-t border-r ${
                  i === arr.length - 1 ? "border-b" : ""
                } border-quaternary p-1`}
              >
                {d.value}
              </div>
            </div>
          ))}
        </div>
      </BaseModal.Body>
      <BaseModal.Footer className="flex justify-between items-center">
        <div>
          <Button
            onClick={onReturn}
            title="戻る"
            className="opacity-75 tex-xs px-2 py-1"
          />
        </div>
        <div>
          <Button
            title="閉じる"
            className="sub-button px-3 py-1"
            onClick={onClose}
          />
        </div>
      </BaseModal.Footer>
    </>
  );
}
