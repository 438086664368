import React, { useEffect, useState } from "react";
import MenuIcon from "../../../shared/images/TreePointIcon.png";
import {
  DepartmentNode,
  DepartmentResponse,
} from "../../../../models/Department";
import ActionModal from "./ActionModal";
import CreateModalBody from "./ActionModal/CreateModalBody";
import BaseMoal from "../../../shared/BaseModal";
import Button from "../../../shared/Button";
import { CompanyResponse } from "../../../../models/Company";
import { MeasurementPeriodResponse } from "../../../../models/MeasurementPeriod";

interface Props {
  company: CompanyResponse;
  measurementPeriod: MeasurementPeriodResponse;
  afterCreated: (department: DepartmentResponse) => void;
}

export default function CreateButton(props: Props) {
  const { company, measurementPeriod, afterCreated } = props;
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <>
      {showModal && (
        <BaseMoal
          show={showModal}
          title="部署追加"
          contentLabel="CreateDepartment"
          onCloseModal={() => setShowModal(false)}
          loading={loading}
        >
          <CreateModalBody
            company={company}
            measurementPeriod={measurementPeriod}
            onCloseButtonClicked={() => setShowModal(false)}
            beforeSave={() => setLoading(true)}
            afterSave={(d) => {
              setLoading(false);
              if (d) {
                setShowModal(false);
                afterCreated(d);
              }
            }}
          />
        </BaseMoal>
      )}
      <Button
        onClick={() => setShowModal(true)}
        title="部署追加"
        className="admin-button px-3 py-1"
      />
    </>
  );
}
