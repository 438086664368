import React, { useState, ReactElement } from "react";
import Button from "./Button";
import EmailRegistModal from "./EmailRegistModal";
import { TempEmailRegistUserResponse } from "../../models/User";

interface Props {
  showModal: boolean;
  initialModalContent: "regist" | "integrate";
  user: TempEmailRegistUserResponse;
  errorMessages: string[];
}

export default function EmailRegistSuggestion(props: Props): ReactElement {
  const {
    showModal: propShowModal = false,
    initialModalContent,
    user,
    errorMessages: propErrorMessages,
  } = props;
  const [showModal, setShowModal] = useState(propShowModal);
  const [errorMessages, setErrorMessages] = useState(propErrorMessages);
  return (
    <>
      {showModal && (
        <EmailRegistModal
          user={user}
          initialModalContent={initialModalContent}
          errorMessages={errorMessages}
          onClose={() => {
            setErrorMessages([]);
            setShowModal(false);
          }}
        />
      )}
      <div className="flex flex-col items-center py-5 border-b -mx-2">
        <div className="p-5 text-center">
          アカウントを登録すると
          <br />
          次回診断時に状態の移り変わりを見ることができます
        </div>
        {user.temp_email && (
          <div className="p-2 bg-quinary">
            <p className="text-center text-red-600 text-sm">
              <span className="font-bold">
                {`${user.temp_email}にアカウント確認メールを送信しました。`}
              </span>
              <br />
              メール内のリンクからアカウントを有効化してください。
            </p>
            <p className="text-center text-red-600 text-xs mt-2">
              ※もしメールアドレスが誤っていた場合は、再度アカウント登録ボタンより正しいメールアドレスを入力してください。
            </p>
          </div>
        )}
        {user.integrate_email && (
          <div className="p-2 bg-quinary mt-2">
            <p className="text-center text-red-600 text-sm">
              <span className="font-bold">
                {`${user.integrate_email}に診断結果の統合確認メールを送信しました。`}
              </span>
              <br />
              メール内のリンクをクリックして統合を完了してください。
            </p>
            <p className="text-center text-red-600 text-xs mt-2">
              ※もしメールアドレスが誤っていた場合は、再度アカウント登録ボタンより正しいメールアドレスを入力してください。
            </p>
          </div>
        )}
        <div className="p-5">
          <Button
            onClick={() => setShowModal(true)}
            className="shine-btn cursor-pointer w-full"
          >
            <span>アカウント登録</span>
          </Button>
        </div>
      </div>
    </>
  );
}
