import React, { ReactElement, useState } from "react";
import SelectForm from "../../../shared/forms/SelectForm";
import HiddenForm from "../../../shared/forms/HiddenForm";
import TextForm from "../../../shared/forms/TextForm";
import { QuestionnaireEditType } from "../../../../models/Questionnaire";
import { QuestionIndexResponse } from "../../../../models/QuestionApi";
import TextAreaForm from "../../../shared/forms/TextAreaForm";

interface Props {
  showForm: boolean;
  questionnaire: QuestionnaireEditType;
  question: QuestionIndexResponse;
  index: number;
  onChange: (val: string) => void;
}

export default function AdditionalQuestionTextForm(props: Props): ReactElement {
  const { showForm, questionnaire, question, index, onChange } = props;
  const answer = questionnaire.answers.find(
    (a) => a.question_id === question.id
  );
  const [value, setValue] = useState(answer?.body || "");
  return (
    <>
      <HiddenForm
        scope="questionnaire"
        relatedModelName="answers"
        index={index}
        columnName="question_id"
        value={question.id}
      />
      {!showForm && (
        <HiddenForm
          scope="questionnaire"
          relatedModelName="answers"
          index={index}
          columnName="body"
          value={value}
        />
      )}
      {showForm && (
        <TextAreaForm
          model={answer || {}}
          scope="questionnaire"
          relatedModelName="answers"
          index={index}
          columnName="body"
          hideLabel
          rows={4}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
      )}
    </>
  );
}
