import React, { useState, ReactElement } from "react";
import BaseModal from "../shared/BaseModal";
import Button from "../shared/Button";
import MultiLineText from "../shared/MultiLineText";
import SelectForm from "../shared/forms/SelectForm";
import { MeasurementPeriodEditType } from "../../models/MeasurementPeriod";
import TextForm from "../shared/forms/TextForm";

interface Props {
  measurementPeriod: MeasurementPeriodEditType;
  howToFoundOptions: Array<{ key: string; value: string }>;
}
export default function HowToFoundForm(props: Props): ReactElement {
  const { measurementPeriod, howToFoundOptions } = props;
  const [selectedHowToFound, setSelectedHowToFound] = useState<string>(
    measurementPeriod.how_to_found as string
  );

  return (
    <>
      <SelectForm
        model={measurementPeriod}
        scope="measurement_period"
        columnName="how_to_found"
        options={howToFoundOptions}
        hideLabel
        option={{ include_blank: "-- 選択してください --" }}
        onChange={(e) => {
          setSelectedHowToFound(e.target.value);
        }}
      />
      {selectedHowToFound !== undefined &&
        ["referral", "other"].includes(selectedHowToFound) && (
          <TextForm
            model={measurementPeriod}
            scope="measurement_period"
            columnName="how_to_found_free_entry"
            hideLabel
            placeholder={
              selectedHowToFound === "referral"
                ? "自由記入(紹介者)"
                : "自由記入"
            }
          />
        )}
    </>
  );
}
