import React, { ReactElement } from "react";
import { Radar } from "react-chartjs-2";
import SubHeader from "../shared/SubHeader";
import { QuestionnaireOverviewResponse } from "../../models/QuestionnaireApi";

const options = (min: number, max: number) => {
  return {
    plugins: {
      legend: {
        position: "top",
        onClick: () => {
          return false;
        },
      },
    },
    scale: {
      ticks: {
        min: Math.floor((min - 5) / 5) * 5,
        max: Math.ceil((max + 5) / 5) * 5,
        stepSize: 5,
      },
    },
    fill: false,
  };
};

interface Props {
  questionnaire: QuestionnaireOverviewResponse;
}

export default function PersonalFactorScore(props: Props): ReactElement {
  const { questionnaire } = props;

  const points = [
    questionnaire.empathy_and_willingness_deviation_value || 0,
    questionnaire.self_importance_deviation_value || 0,
    questionnaire.comfort_deviation_value || 0,
  ];

  return (
    <>
      <SubHeader title="因子バランス" />
      <div className="flex flex-row justify-center items-center flex-wrap my-3">
        <div>
          <Radar
            height={300}
            data={{
              labels: ["理念共感と貢献意欲", "自己有用感", "居心地の良さ"],
              datasets: [
                {
                  label: "あなたの結果",
                  data: points,
                  fill: false,
                  borderColor: "rgba(255, 99, 132, 1)",
                  backgroundColor: "rgba(255, 99, 132, 1)",
                  borderWidth: 2,
                },
              ],
            }}
            options={options(Math.min(...points), Math.max(...points))}
          />
        </div>
        <div>
          <div className="px-3">
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="border font-normal text-sm px-2">因子名称</th>
                  <th className="border font-normal text-sm px-2">
                    あなたの結果
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-4 py-2 text-sm text-center">
                    第１因子
                    <br />
                    理念共感と貢献意欲
                  </td>
                  <td className="border px-2 text-center">
                    {questionnaire.empathy_and_willingness_deviation_value?.toFixed(
                      2
                    ) || "-"}
                  </td>
                </tr>
                <tr className="bg-gray-100">
                  <td className="border px-4 py-2 text-sm text-center">
                    第２因子
                    <br />
                    自己有用感
                  </td>
                  <td className="border px-2 text-center">
                    {questionnaire.self_importance_deviation_value?.toFixed(
                      2
                    ) || "-"}
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2 text-sm text-center">
                    第３因子
                    <br />
                    居心地の良さ
                  </td>
                  <td className="border px-2 text-center">
                    {questionnaire.comfort_deviation_value?.toFixed(2) || "-"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
