import React, { ReactElement } from "react";
import { Line } from "react-chartjs-2";
import { MeasurementPeriodSummaryResponse } from "../../models/MeasurementPeriodSummary";
import { QuestionnaireSummaryResponse } from "../../models/QuestionnaireSummary";
import { format, parseISO } from "date-fns";

const options = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          max: 10,
          stepSize: 2,
        },
      },
    ],
  },
};

interface Props {
  measurementPeriodTransitionSummaries: MeasurementPeriodSummaryResponse[];
  allQuestionnaireSummary: QuestionnaireSummaryResponse;
}

const createGraphData = (summaries: MeasurementPeriodSummaryResponse[]) => {
  const data = summaries.map((s) => {
    return {
      label: format(parseISO(s.measurement_period.start_date), "yyyy年MM月"),
      factor1Value: s.empathy_and_willingness_point,
      factor2Value: s.self_importance_point,
      factor3Value: s.comfort_point,
    };
  });
  // 10個になるまで空のデータを詰める
  for (let i = data.length; i < 10; i++) {
    data.push({
      label: "",
      factor1Value: null,
      factor2Value: null,
      factor3Value: null,
    });
  }
  return data;
};

export default function ReportFactorScoreTrend(props: Props): ReactElement {
  const { measurementPeriodTransitionSummaries, allQuestionnaireSummary } =
    props;
  const data = createGraphData(measurementPeriodTransitionSummaries);
  return (
    <>
      <div className="flex flex-row justify-center items-center mt-2">
        <div>
          <Line
            height={300}
            width={570}
            data={{
              labels: data.map((d) => d.label),
              datasets: [
                {
                  label: "第１因子 貴団体",
                  data: data.map((d) => d.factor1Value),
                  fill: false,
                  borderColor: "#0171C0",
                  backgroundColor: "#0171C0",
                  borderWidth: 2,
                  borderDash: [10, 5],
                },
                {
                  label: "第２因子 貴団体",
                  data: data.map((d) => d.factor2Value),
                  fill: false,
                  borderColor: "#F89546",
                  backgroundColor: "#F89546",
                  borderWidth: 2,
                  borderDash: [10, 5],
                },
                {
                  label: "第３因子 貴団体",
                  data: data.map((d) => d.factor3Value),
                  fill: false,
                  borderColor: "#00B050",
                  backgroundColor: "#00B050",
                  borderWidth: 2,
                  borderDash: [10, 5],
                },
                {
                  label: "第１因子 全サンプル",
                  data: Array(10).fill(
                    allQuestionnaireSummary.empathy_and_willingness_point
                  ),
                  fill: false,
                  borderColor: "#02AFF0",
                  backgroundColor: "#02AFF0",
                  borderWidth: 1,
                  pointRadius: 0,
                },
                {
                  label: "第２因子 全サンプル",
                  data: Array(10).fill(
                    allQuestionnaireSummary.self_importance_point
                  ),
                  fill: false,
                  borderColor: "#F69745",
                  backgroundColor: "#F69745",
                  borderWidth: 1,
                  pointRadius: 0,
                },
                {
                  label: "第３因子 全サンプル",
                  data: Array(10).fill(allQuestionnaireSummary.comfort_point),
                  fill: false,
                  borderColor: "#00B050",
                  backgroundColor: "#00B050",
                  borderWidth: 1,
                  pointRadius: 0,
                },
              ],
            }}
            options={options}
          />
        </div>
      </div>
      <div>
        <div className="flex justify-around">
          <div className="flex items-center">
            <div
              className="w-6"
              style={{
                backgroundColor: "#02AFF0",
                height: 2,
              }}
            />
            <div className="ml-2 text-sm">第１因子 全サンプル</div>
          </div>
          <div className="flex items-center">
            <div
              className="w-6"
              style={{
                backgroundColor: "#F69745",
                height: 2,
              }}
            />
            <div className="ml-2 text-sm">第２因子 全サンプル</div>
          </div>
          <div className="flex items-center">
            <div
              className="w-6"
              style={{
                backgroundColor: "#00B050",
                height: 2,
              }}
            />
            <div className="ml-2 text-sm">第３因子 全サンプル</div>
          </div>
        </div>
        <div className="flex justify-around">
          <div className="flex items-center">
            <div
              className="w-6 relative flex justify-center items-center"
              style={{
                backgroundColor: "#0171C0",
                height: 2,
              }}
            >
              <div
                className="rounded-full h-2 w-2"
                style={{ backgroundColor: "#0171C0" }}
              />
            </div>
            <div className="ml-2 text-sm">第１因子 貴団体</div>
          </div>
          <div className="flex items-center">
            <div
              className="w-6 relative flex justify-center items-center"
              style={{
                backgroundColor: "#F89546",
                height: 2,
              }}
            >
              <div
                className="rounded-full h-2 w-2"
                style={{ backgroundColor: "#F89546" }}
              />
            </div>
            <div className="ml-2 text-sm">第２因子 貴団体</div>
          </div>
          <div className="flex items-center">
            <div
              className="w-6 relative flex justify-center items-center"
              style={{
                backgroundColor: "#00B050",
                height: 2,
              }}
            >
              <div
                className="rounded-full h-2 w-2"
                style={{ backgroundColor: "#00B050" }}
              />
            </div>
            <div className="ml-2 text-sm">第３因子 貴団体</div>
          </div>
        </div>
      </div>
    </>
  );
}
