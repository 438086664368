import React, { ReactElement, useState } from "react";
import SelectForm from "../../shared/forms/SelectForm";
import HiddenForm from "../../shared/forms/HiddenForm";
import { QuestionnaireEditType } from "../../../models/Questionnaire";

interface Props {
  questionnaire: QuestionnaireEditType;
  options: Array<{ key: string; value: string }>;
  showForm: boolean;
}

export default function ManagerialPositionSelectForm(
  props: Props
): ReactElement {
  const { showForm, questionnaire, options } = props;
  const [value, setValue] = useState("");
  return (
    <>
      {!showForm && (
        <HiddenForm
          scope="questionnaire"
          columnName="managerial_position_id"
          value={value}
        />
      )}
      {showForm && (
        <SelectForm
          model={questionnaire}
          scope="questionnaire"
          columnName="managerial_position_id"
          option={{ include_blank: "-- 選択してください --" }}
          options={options}
          hideLabel
          selectedKey={value}
          onChange={(e) => setValue(e.target.value)}
        />
      )}
    </>
  );
}
