import React, { useState, ReactElement } from "react";
import BaseModal from "./BaseModal";
import EmailRegistModalContent from "./EmailRegistModal/EmailRegistModalContent";
import IntegrateModalContent from "./EmailRegistModal/IntegrateModalContent";
import { TempEmailRegistUserResponse } from "../../models/User";

interface Props {
  onClose: () => void;
  initialModalContent: "regist" | "integrate";
  user: TempEmailRegistUserResponse;
  errorMessages: string[];
}

export default function EmailRegistModal(props: Props): ReactElement {
  const {
    onClose,
    user,
    initialModalContent,
    errorMessages: propErrorMessages,
  } = props;
  const [mode, setMode] = useState(initialModalContent);
  const [errorMessages, setErrorMessages] = useState(propErrorMessages);

  return (
    <BaseModal
      show={true}
      title={
        mode === "regist"
          ? "アカウント登録"
          : "アカウント統合(パスワードを忘れた場合)"
      }
      contentLabel="CompanyAdminEdit"
      onCloseModal={() => onClose()}
    >
      {mode === "regist" && (
        <EmailRegistModalContent
          onClose={onClose}
          user={user}
          errorMessages={errorMessages}
          onChangeMode={(m) => {
            setErrorMessages([]);
            setMode(m);
          }}
        />
      )}
      {mode === "integrate" && (
        <IntegrateModalContent
          user={user}
          errorMessages={errorMessages}
          onChangeMode={(m) => {
            setErrorMessages([]);
            setMode(m);
          }}
        />
      )}
    </BaseModal>
  );
}
