import React, { ReactElement } from "react";

interface Props {
  className?: string;
  answeredCount: number | undefined;
  point: number | null | undefined;
  averagePoint: number | null;
}

// const maxColor = "#F79646";
// const minColor = "#4BACC6";

export default function AttributeScoreCell(props: Props): ReactElement {
  const { className, answeredCount, point, averagePoint } = props;

  let value: string | number | null | undefined = point?.toFixed(2) || "-";
  let bgColor = "";
  if (answeredCount === 0 || answeredCount === undefined) {
    value = "該当なし";
  } else if (answeredCount <= 2) {
    value = "非表示";
  } else if (averagePoint && point) {
    if (point - averagePoint > 0) {
      const alpha = (point - averagePoint) / (10 - averagePoint);
      bgColor = `rgba(247, 150, 70, ${alpha})`; // "#F79646"
    } else if (point - averagePoint < 0) {
      const alpha = 1 - point / averagePoint;
      bgColor = `rgba(75, 172, 198, ${alpha})`; // "#4BACC6"
    }
  }

  return (
    <td style={{ backgroundColor: bgColor }} className={className}>
      <span>{value}</span>
    </td>
  );
}
