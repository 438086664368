import React, { ReactElement, useState } from "react";
import LoadingImage from "../shared/images/SpinGray.svg";
// import html2pdf from "html2pdf.js";

// interface Props {
// }

// https://github.com/eKoopmans/html2pdf.js/issues/311
const downloadPdf = (fileName: string, afterCallback: () => void): void => {
  scrollTo(0, 0);
  const elements = Array.from(
    document.querySelectorAll('div[aria-label^="pdf-page-"]')
  );
  const opt = {
    filename: `${fileName}.pdf`,
    image: { type: "jpeg", quality: 0.95 },
    html2canvas: { windowWidth: "1585px", x: 0, y: 0, scale: 1.5 },
    jsPDF: { format: "a3", orientation: "landscape" },
    enableLinks: false,
    // pagebreak: { mode: ["css", "legacy"], before: ".page-break" },
  };
  let worker = window.html2pdf().set(opt).from(elements[0]);
  if (elements.length > 1) {
    worker = worker.toPdf();
    elements.slice(1).forEach((element, _) => {
      worker = worker
        .get("pdf")
        .then((pdf) => {
          pdf.addPage();
        })
        .from(element)
        .toContainer()
        .toCanvas()
        .toPdf();
    });
  }
  worker.save().then(() => afterCallback());
};

interface Props {
  fileName: string;
}

export default function ReportDownloadButton(props: Props): ReactElement {
  const { fileName = "report" } = props;
  const [downloading, setDownloading] = useState(false);

  const download = (): void => {
    if (downloading) {
      return;
    }
    setDownloading(true);
    downloadPdf(fileName, () => {
      setDownloading(false);
    });
  };

  return (
    <div className="flex justify-center">
      <div
        className={`cursor-pointer p-2 rounded bg-primary text-white relative ${
          downloading ? "opacity-75" : ""
        }`}
        onClick={download}
      >
        PDFダウンロード
        {downloading && (
          <object
            type="image/svg+xml"
            data={LoadingImage}
            width="40"
            height="40"
            title="loading"
            style={{
              position: "absolute",
              top: "calc(50% - 20px)",
              transform: "translate(-50%)",
              left: "50%",
            }}
          />
        )}
      </div>
    </div>
  );
}
