import React, { ReactElement, useState, ChangeEvent } from "react";
import HiddenForm from "../../../shared/forms/HiddenForm";
import CheckboxesForm from "../../../shared/forms/CheckboxesForm";
import { QuestionnaireEditType } from "../../../../models/Questionnaire";
import { QuestionIndexResponse } from "../../../../models/QuestionApi";
import { baseScope } from "../../../../utils/AttributeUtils";
import TextAreaForm from "../../../shared/forms/TextAreaForm";

interface Props {
  showForm: boolean;
  questionnaire: QuestionnaireEditType;
  question: QuestionIndexResponse;
  index: number;
  onChange: (values: string[]) => void;
}

export default function AdditionalQuestionCheckboxForm(
  props: Props
): ReactElement {
  const { showForm, questionnaire, question, index, onChange } = props;
  const withTextChoice = question.choices.find(
    (c) => c.role === "with_text_role"
  );
  const answer = questionnaire.answers.find(
    (a) => a.question_id === question.id
  );
  let initialShowTextBox = false;
  let initialChoiceIds: string[] = [];
  let initialTextValue = "";
  if (answer) {
    if (withTextChoice) {
      const ac = answer.answer_choices.find(
        (ac) => ac.choice_id === withTextChoice.id
      );
      if (ac) {
        initialShowTextBox = true;
        initialTextValue = ac.body;
      }
    }
    initialChoiceIds = answer.answer_choices
      .filter((ac) => ac.choice_id)
      .map((ac) => String(ac.choice_id));
  }
  const [showTextBox, setShowTextBox] = useState(initialShowTextBox);
  const [choiceIds, setChoiceIds] = useState<string[]>(initialChoiceIds);
  const [textValue, setTextValue] = useState(initialTextValue);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newChoiceIds: string[] = [];
    if (choiceIds.includes(e.target.value)) {
      newChoiceIds = choiceIds.filter((v) => v !== e.target.value);
    } else {
      newChoiceIds = [...choiceIds, e.target.value];
    }
    if (withTextChoice && String(withTextChoice.id) === e.target.value) {
      setShowTextBox(!showTextBox);
      setChoiceIds(newChoiceIds);
    } else {
      setChoiceIds(newChoiceIds);
      onChange(newChoiceIds);
    }
  };

  return (
    <>
      <HiddenForm
        scope="questionnaire"
        relatedModelName="answers"
        index={index}
        columnName="question_id"
        value={question.id}
      />
      {!showForm &&
        question.choices.map((c, i) => (
          <React.Fragment key={c.id}>
            <HiddenForm
              scope={baseScope("questionnaire", "answers", index)}
              relatedModelName="answer_choices"
              index={i}
              columnName="choice_id"
              value={c.id}
            />
            {withTextChoice && c.id === withTextChoice.id && (
              <HiddenForm
                scope={baseScope("questionnaire", "answers", index)}
                relatedModelName="answer_choices"
                index={i}
                columnName="body"
                value={textValue}
              />
            )}
          </React.Fragment>
        ))}

      {showForm && (
        <>
          <CheckboxesForm
            scope={baseScope("questionnaire", "answers", index)}
            relatedModelName="answer_choices"
            columnName="choice_id"
            required
            hideLabel
            options={question.choices.map((c) => ({
              activeKey: String(c.id),
              disableKey: "",
              title: c.body,
              defaultChecked: choiceIds.includes(String(c.id)),
            }))}
            onChange={handleChange}
          />
          {showTextBox && withTextChoice && (
            <TextAreaForm
              model={answer || {}}
              scope={baseScope("questionnaire", "answers", index)}
              relatedModelName="answer_choices"
              index={question.choices.findIndex(
                (c) => c.role === "with_text_role"
              )}
              columnName="body"
              rows={3}
              hideLabel
              placeholder="自由記入欄"
              value={textValue}
              onChange={(e) => {
                let newValues: string[] = [];
                if (e.target.value) {
                  newValues = [...choiceIds, String(withTextChoice.id)];
                } else {
                  newValues = choiceIds.filter(
                    (v) => v !== String(withTextChoice.id)
                  );
                }
                setTextValue(e.target.value);
                setChoiceIds(newValues);
                onChange(newValues);
              }}
            />
          )}
        </>
      )}
    </>
  );
}
