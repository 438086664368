import React, { ReactElement } from "react";
import { Line } from "react-chartjs-2";
import { QuestionnaireSummaryResponse } from "../../models/QuestionnaireSummary";
import { MeasurementPeriodSummaryResponse } from "../../models/MeasurementPeriodSummary";
import { CategoryDistributionResponse } from "../../models/CategoryDistribution";
import CategoryDistributionItem from "../category_distributions/CategoryDistributionItem";

interface Props {
  allQuestionnaireSummary: QuestionnaireSummaryResponse;
  categoryDistribution: CategoryDistributionResponse;
}

export default function ReportFactorScoreDistribution(
  props: Props
): ReactElement {
  const { allQuestionnaireSummary, categoryDistribution } = props;
  return (
    <div>
      <div className="mt-2 ml-2 p-2 font-bold text-lg">●因子得点の分布</div>
      <div className="flex flex-row justify-around items-center flex-wrap my-3">
        <CategoryDistributionItem
          title={`第１因子\n理念共感と貢献意欲`}
          height={330}
          width={400}
          allDistribution={
            allQuestionnaireSummary.empathy_and_willingness_distribution
          }
          distribution={
            categoryDistribution.empathy_and_willingness_distribution
          }
        />

        <CategoryDistributionItem
          title={`第２因子\n自己有用感`}
          height={330}
          width={400}
          allDistribution={allQuestionnaireSummary.self_importance_distribution}
          distribution={categoryDistribution.self_importance_distribution}
        />
        <CategoryDistributionItem
          title={`第３因\n居心地の良さ`}
          height={330}
          width={400}
          allDistribution={allQuestionnaireSummary.comfort_distribution}
          distribution={categoryDistribution.comfort_distribution}
        />
      </div>
      <div className="text-center text-gray-600 mt-3">
        ※各得点の範囲内にいる人数の割合分布図です。横軸は因子得点、縦軸は各得点の人数の割合です。
      </div>
    </div>
  );
}
