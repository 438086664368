import React, { ReactElement, useState } from "react";
import { format, getMilliseconds, parseISO } from "date-fns";
import Button from "../../shared/Button";
import BaseModal from "../../shared/BaseModal";
import SelectForm from "../../shared/forms/SelectForm";
import { PaymentStatusResponse } from "../../../models/MeasurementPeriodApi";
import BaseForm, { ajaxSubmit } from "../../shared/BaseForm";
import { Props as FlushMessageProps } from "../../shared/FlushMessage";
import TextForm from "../../shared/forms/TextForm";

interface Props {
  measurementPeriod: PaymentStatusResponse;
  onClose: () => void;
  afterSaved: (editedMeasurementPeriod: PaymentStatusResponse) => void;
}

export default function PaymentStatusEditModal(props: Props): ReactElement {
  const { measurementPeriod, onClose, afterSaved } = props;
  const [loading, setLoading] = useState(false);
  const [flushMessage, setFlushMessage] = useState<FlushMessageProps>();

  const save = async (): Promise<void> => {
    const form = document.forms["PaymentStatusEditForm"];
    if (form.checkValidity()) {
      setLoading(true);
      const { result, error } = await ajaxSubmit<PaymentStatusResponse>(form);
      if (result && result.status === 200) {
        const mp = result.data;
        setLoading(false);
        afterSaved(mp);
      } else {
        setLoading(false);
        if (error) {
          setFlushMessage({
            title: "エラーが発生しました",
            messages: error.data.messages,
            milliseconds: getMilliseconds(new Date()),
          });
        }
      }
    }
  };

  return (
    <BaseModal
      show={true}
      contentLabel="PaymentStatus"
      title="入金状態編集"
      loading={loading}
      onCloseModal={onClose}
    >
      <BaseForm
        action={`/admin/measurement_periods/${measurementPeriod.id}`}
        name="PaymentStatusEditForm"
        method="put"
      >
        <BaseModal.Body flushMessage={flushMessage}>
          <div className="flex flex-wrap border-b">
            <div className="w-full md:w-1/4 pt-2 px-2 md:p-2 text-gray-600 text-sm">
              診断期間
            </div>
            <div className="w-full md:w-3/4 p-2">
              {format(parseISO(measurementPeriod.start_date), "yyyy年MM月dd日")}
              〜{format(parseISO(measurementPeriod.end_date), "yyyy年MM月dd日")}
            </div>
          </div>
          <div className="flex flex-wrap border-b">
            <div className="w-full md:w-1/4 pt-2 px-2 md:p-2 text-gray-600 text-sm">
              団体名
            </div>
            <div className="w-full md:w-3/4 p-2">
              {measurementPeriod.company.name}
            </div>
          </div>
          <div className="flex flex-wrap border-b">
            <div className="w-full md:w-1/4 pt-2 px-2 md:p-2 text-gray-600 text-sm">
              請求額
            </div>
            <div className="w-full md:w-3/4 p-2">
              <div className="font-bold">
                {measurementPeriod.billing_amount}円
              </div>
              <div className="text-gray-600 text-xs">
                <div>[内訳]</div>
                {measurementPeriod.billing_amount_breakdowns.map((bab) => (
                  <div
                    key={bab.id}
                  >{`${bab.description}: ${bab.amount}円`}</div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-wrap border-b items-center">
            <div className="w-full md:w-1/4 pt-2 px-2 md:p-2 text-gray-600 text-sm">
              入金状態
            </div>
            <div className="w-full md:w-3/4">
              <SelectForm
                model={measurementPeriod}
                scope="measurement_period"
                columnName="payment_status"
                options={[
                  { key: "unpaid", value: "入金未確認" },
                  { key: "paid_by_bank_transfer", value: "入金確認済" },
                ]}
                hideLabel
              />
            </div>
          </div>
          <div className="flex flex-wrap border-b items-center">
            <div className="w-full md:w-1/4 pt-2 px-2 md:p-2 text-gray-600 text-sm">
              決済担当者名
            </div>
            <div className="w-full md:w-3/4">
              <TextForm
                model={measurementPeriod}
                scope="measurement_period"
                columnName="payment_staff_name"
                hideLabel
              />
            </div>
          </div>
          <div className="flex flex-wrap border-b items-center">
            <div className="w-full md:w-1/4 pt-2 px-2 md:p-2 text-gray-600 text-sm">
              決済担当者メールアドレス
            </div>
            <div className="w-full md:w-3/4">
              <TextForm
                model={measurementPeriod}
                scope="measurement_period"
                columnName="payment_staff_email"
                hideLabel
              />
            </div>
          </div>
        </BaseModal.Body>
        <BaseModal.Footer className="flex justify-end">
          <Button
            title="閉じる"
            className="admin-sub-button px-3 py-1"
            onClick={onClose}
          />
          <Button
            title="保存"
            className="admin-button ml-4 px-4 py-1"
            onClick={save}
          />
        </BaseModal.Footer>
      </BaseForm>
    </BaseModal>
  );
}
