import React, { ReactElement, useEffect } from "react";

// interface Props {
// }

export default function MountCompleteDispatcher(): ReactElement {
  useEffect(() => {
    $(".display-after-react-mounted").removeClass(
      "display-after-react-mounted"
    );
  }, []);
  return <div />;
}
