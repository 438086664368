import React, { ReactElement, useState } from "react";
import BaseMoal from "../../../shared/BaseModal";
import MenuModalBody from "./ActionModal/MenuModalBody";
import CreateModalBody from "./ActionModal/CreateModalBody";
import EditModalBody from "./ActionModal/EditModalBody";
import {
  DepartmentNode,
  DepartmentResponse,
} from "../../../../models/Department";
import { CompanyResponse } from "../../../../models/Company";
import { MeasurementPeriodResponse } from "../../../../models/MeasurementPeriod";

interface Props {
  company: CompanyResponse;
  measurementPeriod: MeasurementPeriodResponse;
  node: DepartmentNode;
  showModal: boolean;
  onClose: () => void;
  afterCreated: (department: DepartmentResponse) => void;
  afterEdited: (department: DepartmentResponse) => void;
  afterDeleted: (nodePath: number[]) => void;
}

export default function ActionModal(props: Props): ReactElement {
  const {
    company,
    measurementPeriod,
    node,
    showModal,
    onClose,
    afterCreated,
    afterDeleted,
    afterEdited,
  } = props;
  const [mode, setMode] = useState<"menu" | "create" | "edit">("menu");
  const [loading, setLoading] = useState(false);

  const subTitle = () => {
    switch (mode) {
      case "create":
        return "(配下部署作成)";
      case "edit":
        return "(編集)";
      default:
        return "";
    }
  };
  return (
    <React.Fragment>
      <BaseMoal
        show={showModal}
        title={`${node.name}${subTitle()}`}
        contentLabel="control_department"
        onCloseModal={onClose}
        loading={loading}
      >
        {mode === "menu" && (
          <MenuModalBody
            company={company}
            measurementPeriod={measurementPeriod}
            node={node}
            onCreationSelected={() => setMode("create")}
            onEditSelected={() => setMode("edit")}
            onCloseButtonClicked={onClose}
            beforeSave={() => setLoading(true)}
            afterSave={(path) => {
              setLoading(false);
              if (path) {
                afterDeleted(path);
              }
            }}
          />
        )}
        {mode === "create" && (
          <CreateModalBody
            company={company}
            measurementPeriod={measurementPeriod}
            node={node}
            onCloseButtonClicked={onClose}
            onSwitchToMenu={() => setMode("menu")}
            beforeSave={() => setLoading(true)}
            afterSave={(d) => {
              setLoading(false);
              if (d) {
                afterCreated(d);
              }
            }}
          />
        )}
        {mode === "edit" && (
          <EditModalBody
            company={company}
            measurementPeriod={measurementPeriod}
            node={node}
            onCloseButtonClicked={onClose}
            onSwitchToMenu={() => setMode("menu")}
            beforeSave={() => setLoading(true)}
            afterSave={(d) => {
              setLoading(false);
              if (d) {
                afterEdited(d);
              }
            }}
          />
        )}
      </BaseMoal>
    </React.Fragment>
  );
}
