import React, { ReactElement, useState } from "react";
import { getMilliseconds } from "date-fns";
import { loadStripe } from "@stripe/stripe-js";
import BaseModal from "../shared/BaseModal";
import PaymentMethodSelectModalContent from "./PaymentButton/PaymentMethodSelectModalContent";
import BankTransferModalContent from "./PaymentButton/BankTransferModalContent";
import CreditCardModalContent from "./PaymentButton/CreditCardModalContent";
import Button from "../shared/Button";
import CompanyInformationInputModalContent from "./PaymentButton/CompanyInformationInputModalContent";
import BaseForm, { ajaxSubmit } from "../shared/BaseForm";
import { Props as FlushMessageProps } from "../shared/FlushMessage";
import { CompanyOverviewResponse } from "../../models/CompanyApi";
import { ActivityFieldResponse } from "../../models/ActivityField";
import { MeasurementPeriodResponse } from "../../models/MeasurementPeriod";

interface Props {
  company: CompanyOverviewResponse;
  measurementPeriod: MeasurementPeriodResponse;
  annualIncomeOptions: Array<{ key: string; value: string }>;
  numberOfEmployeesOptions: Array<{ key: string; value: string }>;
  numberOfMembersOptions: Array<{ key: string; value: string }>;
  prefectureOptions: Array<{ key: string; value: string }>;
  establishmentYearOptions: Array<{ key: string; value: string }>;
  activityFields: ActivityFieldResponse[];
}

export default function PaymentButton(props: Props): ReactElement {
  const {
    company: propCompany,
    measurementPeriod: propMeasurementPeriod,
    annualIncomeOptions,
    numberOfEmployeesOptions,
    numberOfMembersOptions,
    prefectureOptions,
    establishmentYearOptions,
    activityFields,
  } = props;
  const [company, setCompany] = useState(propCompany);
  const [measurementPeriod, setMeasurementPeriod] = useState(
    propMeasurementPeriod
  );
  const [modalMode, setModalMode] = useState<
    | "SelectPaymentMethod"
    | "BankTransfer"
    | "CompanyInformationInput"
    | "CreditCard"
    | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const [flushMessage, setFlushMessage] = useState<FlushMessageProps>();

  const stripePromise = loadStripe(window.ClientSideEnv.STRIPE_API_KEY);

  const modalTitle = () => {
    if (modalMode === "BankTransfer") {
      return "決済: 銀行振込";
    } else if (modalMode === "CreditCard") {
      return "クレジットカード支払";
    } else if (modalMode === "CompanyInformationInput") {
      return "団体情報入力";
    } else {
      return "決済";
    }
  };

  return (
    <>
      {modalMode !== undefined && (
        <BaseModal
          contentLabel="Payment"
          show={true}
          title={modalTitle()}
          loading={loading}
          onCloseModal={() => {
            setFlushMessage(undefined);
            setModalMode(undefined);
          }}
        >
          {modalMode === "SelectPaymentMethod" && (
            <PaymentMethodSelectModalContent
              company={company}
              measurementPeriod={measurementPeriod}
              flushMessage={flushMessage}
              onClickCreditCard={() => {
                setFlushMessage(undefined);
                setModalMode("CreditCard");
              }}
              onClickBankTransfier={() => {
                setFlushMessage(undefined);
                setModalMode("BankTransfer");
              }}
              onClickCompanyInformationInput={() => {
                setFlushMessage(undefined);
                setModalMode("CompanyInformationInput");
              }}
            />
          )}
          {modalMode === "CreditCard" && (
            <CreditCardModalContent
              stripePromise={stripePromise}
              measurementPeriod={measurementPeriod}
              onReturn={() => {
                setFlushMessage(undefined);
                setModalMode("SelectPaymentMethod");
              }}
              onClose={() => {
                setFlushMessage(undefined);
                setModalMode(undefined);
              }}
              beforeSave={() => setLoading(true)}
              afterSave={() => setLoading(false)}
            />
          )}
          {modalMode === "BankTransfer" && (
            <BankTransferModalContent
              onReturn={() => {
                setFlushMessage(undefined);
                setModalMode("SelectPaymentMethod");
              }}
              onClose={() => {
                setFlushMessage(undefined);
                setModalMode(undefined);
              }}
            />
          )}
          {modalMode === "CompanyInformationInput" && (
            <CompanyInformationInputModalContent
              company={company}
              measurementPeriod={measurementPeriod}
              annualIncomeOptions={annualIncomeOptions}
              numberOfEmployeesOptions={numberOfEmployeesOptions}
              numberOfMembersOptions={numberOfMembersOptions}
              prefectureOptions={prefectureOptions}
              establishmentYearOptions={establishmentYearOptions}
              activityFields={activityFields}
              flushMessage={flushMessage}
              onReturn={() => {
                setFlushMessage(undefined);
                setModalMode("SelectPaymentMethod");
              }}
              beforeSave={() => setLoading(true)}
              afterSave={(editedCompany, editedMeasurementPeriod) => {
                setCompany(editedCompany);
                setMeasurementPeriod(editedMeasurementPeriod);
                setLoading(false);
                setModalMode("SelectPaymentMethod");
                setFlushMessage({
                  messageType: "success",
                  title: "団体情報のご入力ありがとうございました！",
                  messages: ["利用料の値引をさせていただきました"],
                  milliseconds: getMilliseconds(new Date()),
                });
              }}
              onError={(error) => {
                setLoading(false);
                if (error) {
                  setFlushMessage({
                    title: "エラーが発生しました",
                    messages: error.data.messages,
                    milliseconds: getMilliseconds(new Date()),
                  });
                }
              }}
            />
          )}
        </BaseModal>
      )}
      <div
        id="payment-button"
        className="shine-btn"
        onClick={() => setModalMode("SelectPaymentMethod")}
      >
        <span>決済</span>
      </div>
    </>
  );
}
