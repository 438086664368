import React, { useEffect, ReactElement, ReactNode } from "react";
import FlushMessage, { Props as FlushMessageProps } from "./FlushMessage";

interface Props {
  children: ReactNode;
  flushMessage?: FlushMessageProps;
}

const scrollToTop = (): void => {
  const element = document.getElementById("react-modal-body-id");
  if (element) {
    element.scrollTop = 0;
  }
};

export default function BaseModalBody(props: Props): ReactElement {
  const { children, flushMessage } = props;

  useEffect(() => {
    scrollToTop();
  }, [flushMessage?.milliseconds]);

  return (
    <div
      id="react-modal-body-id"
      className="px-2 py-3 overflow-auto react-modal-body"
    >
      <>
        {flushMessage && (
          <FlushMessage
            messageType={flushMessage.messageType}
            messages={flushMessage.messages}
            title={flushMessage.title}
            milliseconds={flushMessage.milliseconds}
          />
        )}
        {children}
      </>
    </div>
  );
}
