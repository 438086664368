import React, { ReactElement } from "react";
import FactorScore from "./CommunityCapitalResult/FactorScore";
import FactorScoreDistribution from "./CommunityCapitalResult/FactorScoreDistribution";
import RespondentDistribution from "./CommunityCapitalResult/RespondentDistribution";
import ItemSpecificFactorScores from "./CommunityCapitalResult/ItemSpecificFactorScores";
import { MeasurementPeriodSummaryResponse } from "../../models/MeasurementPeriodSummary";
import { CategoryDistributionResponse } from "../../models/CategoryDistribution";
import { QuestionnaireSummaryResponse } from "../../models/QuestionnaireSummary";
import { ManagerialPositionResponse } from "../../models/ManagerialPosition";

interface Props {
  allQuestionnaireSummary: QuestionnaireSummaryResponse;
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
  categoryDistribution: CategoryDistributionResponse;
  managerialPositions: ManagerialPositionResponse[];
  salariedStaffOptions: Array<{ key: string; value: string }>;
  averageActivityTimeOptions: Array<{ key: string; value: string }>;
  activityHistoryOptions: Array<{ key: string; value: string }>;
}

export default function CommunityCapitalResult(props: Props): ReactElement {
  const {
    allQuestionnaireSummary,
    measurementPeriodSummary,
    categoryDistribution,
    managerialPositions,
    salariedStaffOptions,
    averageActivityTimeOptions,
    activityHistoryOptions,
  } = props;

  return (
    <>
      <FactorScore
        allQuestionnaireSummary={allQuestionnaireSummary}
        measurementPeriodSummary={measurementPeriodSummary}
      />
      <FactorScoreDistribution
        allQuestionnaireSummary={allQuestionnaireSummary}
        categoryDistribution={categoryDistribution}
      />
      <RespondentDistribution
        measurementPeriodSummary={measurementPeriodSummary}
        managerialPositions={managerialPositions}
        salariedStaffOptions={salariedStaffOptions}
        averageActivityTimeOptions={averageActivityTimeOptions}
        activityHistoryOptions={activityHistoryOptions}
      />
      <ItemSpecificFactorScores
        measurementPeriodSummary={measurementPeriodSummary}
        managerialPositions={managerialPositions}
        salariedStaffOptions={salariedStaffOptions}
        averageActivityTimeOptions={averageActivityTimeOptions}
        activityHistoryOptions={activityHistoryOptions}
      />
    </>
  );
}
