import React, { ReactElement, useState } from "react";
import SelectForm from "../../shared/forms/SelectForm";
import HiddenForm from "../../shared/forms/HiddenForm";
import TextForm from "../../shared/forms/TextForm";
import { QuestionnaireEditType } from "../../../models/Questionnaire";

interface Props {
  questionnaire: QuestionnaireEditType;
  showForm: boolean;
}

export default function CellPhoneNumberTextForm(props: Props): ReactElement {
  const { showForm, questionnaire } = props;
  const [value, setValue] = useState(questionnaire.cell_phone_number || "");
  return (
    <>
      {!showForm && (
        <HiddenForm
          scope="questionnaire"
          columnName="cell_phone_number"
          value={value}
        />
      )}
      {showForm && (
        <>
          <div className="text-sm mt-4">
            携帯番号下4桁は診断結果の推移を統計的に分析することのみに利用します。
          </div>
          <div className="mt-4">
            <TextForm
              model={questionnaire}
              scope="questionnaire"
              columnName="cell_phone_number"
              placeholder="1234"
              type="number"
              hideLabel
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
        </>
      )}
    </>
  );
}
