import React, { ReactElement, useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { postRequest } from "../../../utils/ApiClient";
import { MeasurementPeriodResponse } from "../../../models/MeasurementPeriod";
import { AdminUserResponse } from "../../../models/User";
import { getMilliseconds } from "date-fns";
import CheckoutForm from "./CheckoutForm";
import { number } from "prop-types";
import BaseModal from "../../shared/BaseModal";
import { Props as FlushMessageProps } from "../../shared/FlushMessage";
import Button from "../../shared/Button";
import { isIE } from "../../../utils/UserAgentUtils";

interface Props {
  measurementPeriod: MeasurementPeriodResponse;
  stripePromise: Promise<Stripe | null>;
  onReturn: () => void;
  onClose: () => void;
  beforeSave: () => void;
  afterSave: () => void;
}

export default function CreditCardModalContent(props: Props): ReactElement {
  const {
    measurementPeriod,
    stripePromise,
    onReturn,
    onClose,
    beforeSave,
    afterSave,
  } = props;
  const [flushMessage, setFlushMessage] = useState<FlushMessageProps>();
  const [intentInformation, setIntentInformation] = useState<{
    clientSecret: string | null;
    amount: number | null;
  }>({
    clientSecret: null,
    amount: null,
  });

  useEffect(() => {
    if (!isIE()) {
      getPaymentIntent();
    }
  }, []);

  const getPaymentIntent = async () => {
    try {
      beforeSave();
      const r = await postRequest<{ clientSecret: string; amount: number }>(
        `/user/measurement_periods/${measurementPeriod.id}/create_payment_intent`
      );
      setIntentInformation(r.data);
      afterSave();
    } catch (e) {
      const error = e.response;
      setFlushMessage({
        title: "エラーが発生しました",
        messages: error.data.messages,
        milliseconds: getMilliseconds(new Date()),
      });
      afterSave();
    }
  };

  return (
    <>
      <BaseModal.Body flushMessage={flushMessage}>
        {intentInformation.amount && (
          <div className="font-bold text-center text-lg">
            金額
            <br />
            {`${intentInformation.amount}円(税込)`}
          </div>
        )}
        {window.ClientSideEnv.IS_TEST_MODE === "true" && (
          <div className="text-sm p-2 border mt-2">
            テスト用クレジットカード情報(※本番環境ではこのメッセージは表示されません)
            <br />
            ・クレジットカード番号: 4242 4242 4242 4242
            <br />
            ・有効期限: 任意
            <br />
            ・セキュリティーコード: 任意
            <br />
          </div>
        )}
        {isIE() && (
          <div className="text-red-600 text-center">
            大変申し訳ありませんが、お使いのブラウザではクレジットカード決済は行えません。
            <br />
            ブラウザを変更してお試しいただくか、銀行振込をご利用ください。
          </div>
        )}
        {!isIE() && (
          <div className="text-red-600 text-sm font-bold w-4/5 m-auto mt-2">
            クレジットカード番号等は半角数字での入力をお願いいたします。
          </div>
        )}
        <div className="w-4/5 m-auto mt-2">
          {intentInformation.clientSecret && (
            <Elements
              options={{
                clientSecret: intentInformation.clientSecret,
                appearance: {
                  theme: "stripe",
                },
              }}
              stripe={stripePromise}
            >
              <CheckoutForm measurementPeriod={measurementPeriod} />
            </Elements>
          )}
        </div>
      </BaseModal.Body>
      <BaseModal.Footer className="flex justify-between items-center">
        <div>
          <Button
            onClick={onReturn}
            title="戻る"
            className="opacity-75 tex-xs px-2 py-1"
          />
        </div>
        <div>
          <Button
            title="閉じる"
            className="sub-button px-3 py-1"
            onClick={onClose}
          />
        </div>
      </BaseModal.Footer>
    </>
  );
}
