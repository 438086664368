import React, { ReactElement } from "react";
import CategoryDistributionItem from "../../category_distributions/CategoryDistributionItem";
import MultiLineText from "../../shared/MultiLineText";

interface Props {
  allDistribution: number[];
  distribution: number[];
  title: string;
  description: string;
  minAxisTitle: string;
  maxAxisTitle: string;
}

export default function PersonalMindStateCategoryDistribution(
  props: Props
): ReactElement {
  const {
    allDistribution,
    distribution,
    title,
    description,
    minAxisTitle,
    maxAxisTitle,
  } = props;

  return (
    <div className="flex justify-center flex-wrap-reverse mt-4">
      <div className="w-full md:w-1/2">
        <CategoryDistributionItem
          title={title}
          allDistribution={allDistribution}
          distribution={distribution}
          minAxisTitle={minAxisTitle}
          maxAxisTitle={maxAxisTitle}
          legendTitle="あなた"
        />
      </div>
      <div className="w-full md:w-1/2">
        <div className="text-sm p-2 bg-quinary">
          <div className="font-bold">{title}</div>
          <div className="mt-2">
            <MultiLineText text={description} />
          </div>
        </div>
      </div>
    </div>
  );
}
