import React, { ReactElement } from "react";
import { Line } from "react-chartjs-2";

const options = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          max: 10,
          stepSize: 2,
        },
      },
    ],
  },
};

export default function ReportFactorScoreTrend(): ReactElement {
  return (
    <>
      <div className="flex flex-row justify-center items-center mt-2">
        <div>
          <Line
            height={300}
            width={570}
            data={{
              labels: [
                "2012年12月",
                "2013年12月",
                "2014年12月",
                "2015年12月",
                "2016年12月",
                "2017年12月",
                "2018年12月",
                "2019年12月",
                "2020年12月",
                "2021年12月",
              ],
              datasets: [
                {
                  label: "第１因子 貴団体",
                  data: [4, 4, 4, 6, 5, 5, 6, 6, 8.81, 9.31],
                  fill: false,
                  borderColor: "#0171C0",
                  backgroundColor: "#0171C0",
                  borderWidth: 2,
                  borderDash: [10, 5],
                },
                {
                  label: "第２因子 貴団体",
                  data: [5, 5, 6, 5, 3, 4, 4, 5, 6.64, 5.71],
                  fill: false,
                  borderColor: "#F89546",
                  backgroundColor: "#F89546",
                  borderWidth: 2,
                  borderDash: [10, 5],
                },
                {
                  label: "第３因子 貴団体",
                  data: [4, 4, 6, 5, 6, 6, 7, 7, 7.71, 8.14],
                  fill: false,
                  borderColor: "#00B050",
                  backgroundColor: "#00B050",
                  borderWidth: 2,
                  borderDash: [10, 5],
                },
                {
                  label: "第１因子 全サンプル",
                  data: [7, 7, 7, 7, 7, 7, 7, 7, 7, 7],
                  fill: false,
                  borderColor: "#02AFF0",
                  backgroundColor: "#02AFF0",
                  borderWidth: 2,
                  pointRadius: 0,
                },
                {
                  label: "第２因子 全サンプル",
                  data: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
                  fill: false,
                  borderColor: "#F69745",
                  backgroundColor: "#F69745",
                  borderWidth: 2,
                  pointRadius: 0,
                },
                {
                  label: "第３因子 全サンプル",
                  data: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
                  fill: false,
                  borderColor: "#00B050",
                  backgroundColor: "#00B050",
                  borderWidth: 2,
                  pointRadius: 0,
                },
              ],
            }}
            options={options}
          />
        </div>
      </div>
      <div>
        <div className="flex justify-around">
          <div className="flex items-center">
            <div
              className="w-6"
              style={{
                backgroundColor: "#02AFF0",
                height: 2,
              }}
            />
            <div className="ml-2 text-sm">第１因子 全サンプル</div>
          </div>
          <div className="flex items-center">
            <div
              className="w-6"
              style={{
                backgroundColor: "#F69745",
                height: 2,
              }}
            />
            <div className="ml-2 text-sm">第２因子 全サンプル</div>
          </div>
          <div className="flex items-center">
            <div
              className="w-6"
              style={{
                backgroundColor: "#00B050",
                height: 2,
              }}
            />
            <div className="ml-2 text-sm">第３因子 全サンプル</div>
          </div>
        </div>
        <div className="flex justify-around">
          <div className="flex items-center">
            <div
              className="w-6 relative flex justify-center items-center"
              style={{
                backgroundColor: "#0171C0",
                height: 2,
              }}
            >
              <div
                className="rounded-full h-2 w-2"
                style={{ backgroundColor: "#0171C0" }}
              />
            </div>
            <div className="ml-2 text-sm">第１因子 貴団体</div>
          </div>
          <div className="flex items-center">
            <div
              className="w-6 relative flex justify-center items-center"
              style={{
                backgroundColor: "#F89546",
                height: 2,
              }}
            >
              <div
                className="rounded-full h-2 w-2"
                style={{ backgroundColor: "#F89546" }}
              />
            </div>
            <div className="ml-2 text-sm">第２因子 貴団体</div>
          </div>
          <div className="flex items-center">
            <div
              className="w-6 relative flex justify-center items-center"
              style={{
                backgroundColor: "#00B050",
                height: 2,
              }}
            >
              <div
                className="rounded-full h-2 w-2"
                style={{ backgroundColor: "#00B050" }}
              />
            </div>
            <div className="ml-2 text-sm">第３因子 貴団体</div>
          </div>
        </div>
      </div>
    </>
  );
}
