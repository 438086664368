export interface CategoryDistributionResponse {
  empathy_and_willingness_distribution: number[];
  self_importance_distribution: number[];
  comfort_distribution: number[];
  well_being_distribution: number[];
  health_perception_distribution: number[];
  depressive_tendency_distribution: number[];
  loneliness_distribution: number[];
  self_affirmation_distribution: number[];
  diversity_acceptance_distribution: number[];
  resilience_distribution: number[];
  social_trust_distribution: number[];
  positive_sum_thinking_distribution: number[];
  growth_mindset_distribution: number[];
  self_efficacy_distribution: number[];
  human_relations_thinking_distribution: number[];
}

export const toPercent = (distribution: number[]): number[] => {
  return distribution.map((d) => (d * 100) / sum(distribution));
};

const sum = (distribution: number[]): number => {
  return distribution.reduce((sum, d) => sum + d, 0);
};
