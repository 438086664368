import React, { ReactElement } from "react";
import { HorizontalBar } from "react-chartjs-2";
import { MeasurementPeriodSummaryResponse } from "../../models/MeasurementPeriodSummary";
import { ManagerialPositionResponse } from "../../models/ManagerialPosition";
import RespondentDistributionItem from "../questionnaires/CommunityCapitalResult/RespondentDistributionItem";
import { answerCountPercent as managerialPositionAnswerCountPercent } from "../../models/MeasurementPeriodManagerialPositionSummary";
import ReportRespondentDistributionItem from "./ReportRespondentDistributionItem";
import { answerCountPercent as attributesAnswerCountPercent } from "../../models/MeasurementPeriodAttributesSummary";
import { addNullElement } from "../../utils/ArrayUtils";

interface Props {
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
  managerialPositions: ManagerialPositionResponse[];
  salariedStaffOptions: Array<{ key: string; value: string }>;
  averageActivityTimeOptions: Array<{ key: string; value: string }>;
  activityHistoryOptions: Array<{ key: string; value: string }>;
}

export default function ReportRespondentDistribution(
  props: Props
): ReactElement {
  const {
    measurementPeriodSummary,
    managerialPositions,
    salariedStaffOptions,
    averageActivityTimeOptions,
    activityHistoryOptions,
  } = props;
  return (
    <>
      <div className="mt-2 ml-2 p-2 font-bold text-lg">●回答者の分布</div>
      <div className="flex flex-row justify-around my-3">
        <ReportRespondentDistributionItem
          title="ポジション"
          data={addNullElement(managerialPositions).map((mp) => ({
            label: mp?.name || "無回答",
            rate: managerialPositionAnswerCountPercent(
              measurementPeriodSummary.managerial_position_summaries,
              mp
            ),
          }))}
        />
        <ReportRespondentDistributionItem
          title="報酬"
          data={salariedStaffOptions
            .concat({ key: "null", value: "無回答" })
            .map((o) => ({
              label: o.value,
              rate: attributesAnswerCountPercent(
                measurementPeriodSummary.salaried_staff_summaries,
                o.key
              ),
            }))}
        />
        <ReportRespondentDistributionItem
          title="平均活動時間(一ヶ月)"
          data={averageActivityTimeOptions
            .concat({ key: "null", value: "無回答" })
            .map((o) => ({
              label: o.value,
              rate: attributesAnswerCountPercent(
                measurementPeriodSummary.average_activity_time_summaries,
                o.key
              ),
            }))}
        />
        <ReportRespondentDistributionItem
          title="活動歴"
          data={activityHistoryOptions
            .concat({ key: "null", value: "無回答" })
            .map((o) => ({
              label: o.value,
              rate: attributesAnswerCountPercent(
                measurementPeriodSummary.activity_history_summaries,
                o.key
              ),
            }))}
        />
      </div>
    </>
  );
}
