import React, { ReactElement } from "react";
import { Bar } from "react-chartjs-2";
import { MeasurementPeriodSummaryResponse } from "../../../../models/MeasurementPeriodSummary";
import { ManagerialPositionResponse } from "../../../../models/ManagerialPosition";
import { colors } from "../../../../utils/GraphColorUtils";
import { getSummary } from "../../../../models/MeasurementPeriodManagerialPositionSummary";
import { addNullElement } from "../../../../utils/ArrayUtils";
import AttributeScoreCell from "../../../shared/AttributeScoreCell";
import { QuestionnaireSummaryResponse } from "../../../../models/QuestionnaireSummary";

const options = {
  responsive: false,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          min: 2,
          max: 10,
          stepSize: 2,
        },
      },
    ],
  },
};

interface Props {
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
  managerialPositions: ManagerialPositionResponse[];
}

export default function ManagerialPositionFactorScore(
  props: Props
): ReactElement {
  const { measurementPeriodSummary, managerialPositions } = props;

  return (
    <>
      <h5 className="p-3 text-center">
        団体内でのポジションによる因子得点平均値の比較
      </h5>
      <div className="flex flex-row justify-center items-center flex-wrap">
        <div className="p-2 w-full lg:w-1/3 flex justify-center">
          <div>
            <Bar
              height={320}
              width={320}
              data={{
                // labels: ["理念共感と貢献意欲", "自己有用感", "居心地の良さ"],
                labels: ["", "", ""],
                datasets: addNullElement(managerialPositions).map((mp, i) => {
                  const summary = getSummary(
                    measurementPeriodSummary.managerial_position_summaries,
                    mp
                  );
                  return {
                    label: mp?.name || "無回答",
                    data: [
                      summary?.empathy_and_willingness_point || 0,
                      summary?.self_importance_point || 0,
                      summary?.comfort_point || 0,
                    ],
                    borderColor: colors[i],
                    backgroundColor: colors[i],
                  };
                }),
              }}
              options={options}
            />
            <div className="flex justify-around text-xs">
              <div>理念共感と貢献意欲</div>
              <div>自己有用感</div>
              <div>居心地の良さ</div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-2/3 flex justify-center">
          <div className="px-3 w-full">
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="border font-normal text-sm px-2"></th>
                  <th
                    className="border font-normal text-sm px-2"
                    style={{ minWidth: 80 }}
                  >
                    理念共感と貢献意欲
                  </th>
                  <th
                    className="border font-normal text-sm px-2"
                    style={{ minWidth: 80 }}
                  >
                    自己有用感
                  </th>
                  <th
                    className="border font-normal text-sm px-2"
                    style={{ minWidth: 80 }}
                  >
                    居心地の良さ
                  </th>
                </tr>
              </thead>
              <tbody>
                {addNullElement(managerialPositions).map((mp, i) => {
                  const summary = getSummary(
                    measurementPeriodSummary.managerial_position_summaries,
                    mp
                  );
                  return (
                    <tr key={mp?.id || "null"}>
                      <td className="border px-4 py-2 text-sm">
                        <div className="flex items-center">
                          <div
                            className="rounded h-3 w-3 mr-2 flex-shrink-0"
                            style={{ backgroundColor: colors[i] }}
                          />
                          <div>{mp?.name || "無回答"}</div>
                        </div>
                      </td>
                      <AttributeScoreCell
                        className="border px-2 text-center text-sm"
                        answeredCount={
                          summary?.empathy_and_willingness_answered_count
                        }
                        point={summary?.empathy_and_willingness_point}
                        averagePoint={
                          measurementPeriodSummary.empathy_and_willingness_point
                        }
                      />
                      <AttributeScoreCell
                        className="border px-2 text-center text-sm"
                        answeredCount={summary?.self_importance_answered_count}
                        point={summary?.self_importance_point}
                        averagePoint={
                          measurementPeriodSummary.self_importance_point
                        }
                      />
                      <AttributeScoreCell
                        className="border px-2 text-center text-sm"
                        answeredCount={summary?.comfort_answered_count}
                        point={summary?.comfort_point}
                        averagePoint={measurementPeriodSummary.comfort_point}
                      />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
