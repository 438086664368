import { ManagerialPositionResponse } from "./ManagerialPosition";

export interface MeasurementPeriodManagerialPositionSummaryResponse {
  managerial_position: ManagerialPositionResponse | null;
  answered_count: number;
  // 因子ポイント
  empathy_and_willingness_point: number | null;
  self_importance_point: number | null;
  comfort_point: number | null;
  // 因子回答数
  empathy_and_willingness_answered_count: number;
  self_importance_answered_count: number;
  comfort_answered_count: number;
}

export const answerCountPercent = (
  summaries: MeasurementPeriodManagerialPositionSummaryResponse[],
  mp: ManagerialPositionResponse | null
): number => {
  if (summaries.length === 0) {
    return 0;
  }
  const mps = summaries.find((mps) => {
    if (mps.managerial_position === null) {
      return mp === null;
    }
    return mp !== null && mps.managerial_position.id === mp.id;
  });
  let val = 0;
  if (mps) {
    val = (mps.answered_count * 100) / totalAnswerCount(summaries);
  }
  return Math.round(val);
};

export const getSummary = (
  summaries: MeasurementPeriodManagerialPositionSummaryResponse[],
  mp: ManagerialPositionResponse | null
): MeasurementPeriodManagerialPositionSummaryResponse | undefined => {
  const mps = summaries.find((mps) => {
    if (mps.managerial_position === null) {
      return mp === null;
    }
    return mp !== null && mps.managerial_position.id === mp.id;
  });
  return mps;
};

const totalAnswerCount = (
  summaries: MeasurementPeriodManagerialPositionSummaryResponse[]
) => {
  return summaries.reduce((sum, mps) => sum + mps.answered_count, 0);
};
