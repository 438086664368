import React, { ChangeEvent, ReactElement } from "react";
import { ReactSVG } from "react-svg";
import HiddenForm from "../../shared/forms/HiddenForm";
import TextForm from "../../shared/forms/TextForm";
import Button from "../../shared/Button";
import ClearIcon from "../../shared/images/Clear.svg";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  choice: any;
  index: number;
  activeIndex: number;
  showDeleteButton: boolean;
  onRemove: () => void;
}

export default function ChoiceField(props: Props): ReactElement {
  const { choice, index, activeIndex, showDeleteButton, onRemove } = props;
  const isOtherChoice = choice.role === "with_text_role";
  return (
    <>
      {choice.id && (
        <HiddenForm
          scope="question"
          relatedModelName="choices"
          index={index}
          columnName="id"
          value={choice.id}
        />
      )}
      <HiddenForm
        scope="question"
        relatedModelName="choices"
        index={index}
        columnName="role"
        value={choice.role}
      />
      <HiddenForm
        scope="question"
        relatedModelName="choices"
        index={index}
        columnName="position"
        value={index}
      />
      <div className="flex items-center mt-2">
        <div className={showDeleteButton ? "w-4/5" : "w-full"}>
          <TextForm
            scope="question"
            model={choice}
            relatedModelName="choices"
            columnName="body"
            index={index}
            required
            hideLabel
            readOnly={isOtherChoice}
            placeholder={`選択肢内容${activeIndex}`}
            containerClassName="flex flex-wrap items-center"
          />
        </div>
        {showDeleteButton && (
          <Button className="w-1/5" onClick={onRemove}>
            <FontAwesomeIcon icon={faTimes} className="opacity-50" />
          </Button>
        )}
      </div>
      <div style={{ clear: "both" }} />
    </>
  );
}
