import React, { ReactElement } from "react";
import SubHeader from "../../shared/SubHeader";
import { CategoryDistributionResponse } from "../../../models/CategoryDistribution";
import { QuestionnaireSummaryResponse } from "../../../models/QuestionnaireSummary";
import CategoryDistributionItem from "../../category_distributions/CategoryDistributionItem";

interface Props {
  allQuestionnaireSummary: QuestionnaireSummaryResponse;
  categoryDistribution: CategoryDistributionResponse;
}

export default function FactorScoreDistribution(props: Props): ReactElement {
  const { allQuestionnaireSummary, categoryDistribution } = props;

  return (
    <>
      <SubHeader title="因子得点の分布" />
      <div className="flex flex-row justify-center items-center flex-wrap my-3">
        <CategoryDistributionItem
          title={`第１因子\n理念共感と貢献意欲`}
          allDistribution={
            allQuestionnaireSummary.empathy_and_willingness_distribution
          }
          distribution={
            categoryDistribution.empathy_and_willingness_distribution
          }
        />
        <CategoryDistributionItem
          title={`第２因子\n自己有用感`}
          allDistribution={allQuestionnaireSummary.self_importance_distribution}
          distribution={categoryDistribution.self_importance_distribution}
        />
        <CategoryDistributionItem
          title={`第３因\n居心地の良さ`}
          allDistribution={allQuestionnaireSummary.comfort_distribution}
          distribution={categoryDistribution.comfort_distribution}
        />
        <div className="text-center text-xs text-gray-600 mt-3">
          ※各得点の範囲内にいる人数の割合分布図です。横軸は因子得点、縦軸は各得点の人数の割合です。
        </div>
      </div>
    </>
  );
}
