import React, { ReactElement } from "react";
import { Radar } from "react-chartjs-2";
import SubHeader from "../../shared/SubHeader";

const options = {
  legend: {
    position: "top",
    onClick: () => {
      return false;
    },
  },
  scale: {
    ticks: { min: 30, max: 65, stepSize: 10 },
  },
  fill: false,
};

function graphSize(): number {
  if (window.screen.width < 450) {
    return 300;
  }
  return 400;
}

export default function FactorScore(): ReactElement {
  return (
    <>
      <SubHeader title="因子得点" />
      <div className="flex flex-row justify-center items-center flex-wrap my-3">
        <div>
          <Radar
            height={graphSize()}
            width={graphSize()}
            data={{
              labels: ["理念共感と貢献意欲", "自己有用感", "居心地の良さ"],
              datasets: [
                {
                  label: "貴団体",
                  data: [56, 47.34, 51.49],
                  fill: false,
                  borderColor: "rgba(255, 99, 132, 1)",
                  backgroundColor: "rgba(255, 99, 132, 1)",
                  borderWidth: 2,
                },
                {
                  label: "サンプル全体",
                  data: [50, 50, 50],
                  fill: false,
                  borderColor: "#F5A300",
                  backgroundColor: "#F5A300",
                  borderWidth: 2,
                },
              ],
            }}
            options={options}
          />
        </div>
        <div>
          <div className="px-3">
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="border font-normal text-sm px-2">因子名称</th>
                  <th className="border font-normal text-sm px-2">貴団体</th>
                  <th className="border font-normal text-sm px-2">
                    サンプル全体
                  </th>
                  <th className="border font-normal text-sm px-2">偏差値</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-4 py-2 text-sm text-center">
                    第１因子
                    <br />
                    理念共感と貢献意欲
                  </td>
                  <td className="border px-2 text-center">9.31</td>
                  <td className="border px-2 text-center">8.11</td>
                  <td className="border px-2 text-center">56.00</td>
                </tr>
                <tr className="bg-gray-100">
                  <td className="border px-4 py-2 text-sm text-center">
                    第２因子
                    <br />
                    自己有用感
                  </td>
                  <td className="border px-2 text-center">5.71</td>
                  <td className="border px-2 text-center">6.25</td>
                  <td className="border px-2 text-center">47.34</td>
                </tr>
                <tr>
                  <td className="border px-4 py-2 text-sm text-center">
                    第３因子
                    <br />
                    居心地の良さ
                  </td>
                  <td className="border px-2 text-center">8.14</td>
                  <td className="border px-2 text-center">7.85</td>
                  <td className="border px-2 text-center">51.49</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
