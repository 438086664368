import React, { ReactElement, ReactNode, useState } from "react";
import QuestionnaireLabel from "./QuestionnaireLabel";
import Button from "../shared/Button";
import ManagerialPositionSelectForm from "./QuestionnaireForm/ManagerialPositionSelectForm";
import CommunityCapitalForm from "./QuestionnaireForm/CommunityCapitalForm";
import HealthConditionSelectForm from "./QuestionnaireForm/HealthConditionSelectForm";
import WellBeingSelectForm from "./QuestionnaireForm/WellBeingSelectForm";
import CellPhoneNumberTextForm from "./QuestionnaireForm/CellPhoneNumberTextForm";
import QuestionnairePropertySelectForm from "./QuestionnaireForm/QuestionnairePropertySelectForm";
import { QuestionnaireEditType } from "../../models/Questionnaire";
import { CommunityCapitalQuestionResponse } from "../../models/CommunityCapitalQuestion";
import SixPointScaleQuestionItem from "./QuestionnaireForm/SixPointScaleQuestionItem";
import FrequencyInWeekQuestionItem from "./QuestionnaireForm/FrequencyInWeekQuestionItem";
import { MeasurementPeriodResponse } from "../../models/MeasurementPeriod";
import { QuestionIndexResponse } from "../../models/QuestionApi";
import AdditionalQuestionForm from "./QuestionnaireForm/AdditionalQuestionForm";
import HiddenField from "../shared/forms/fields/HiddenField";

interface Props {
  isPreview?: boolean;
  phoneNumberTracingFlg: boolean;
  inputCompleteKeyList: string[];
  measurementPeriod: MeasurementPeriodResponse;
  questionnaire: QuestionnaireEditType;
  communityCapitalMainQuestions: CommunityCapitalQuestionResponse[];
  questions: QuestionIndexResponse[];
  wellBeingQuestion: CommunityCapitalQuestionResponse;
  healthPerceptionQuestion: CommunityCapitalQuestionResponse;
  emotionsInWeekQuestions: CommunityCapitalQuestionResponse[];
  selfAffirmationQuestions: CommunityCapitalQuestionResponse[];
  mindsetQuestions: CommunityCapitalQuestionResponse[];
  departmentOptions: Array<{ key: string; value: string }>;
  salariedStaffOptions: Array<{ key: string; value: string }>;
  averageActivityTimeOptions: Array<{ key: string; value: string }>;
  degreeOfInvolvementOptions: Array<{ key: string; value: string }>;
  activityHistoryOptions: Array<{ key: string; value: string }>;
  sexOptions: Array<{ key: string; value: string }>;
  occupationOptions: Array<{ key: string; value: string }>;
  finalEducationOptions: Array<{ key: string; value: string }>;
  annualIncomeOptions: Array<{ key: string; value: string }>;
  maritalStatusOptions: Array<{ key: string; value: string }>;
  answerCountOptions: Array<{ key: string; value: string }>;
  birthYearOptions: Array<{ key: string; value: string }>;
  prefectureOptions: Array<{ key: string; value: string }>;
  managerialPositionOptions: Array<{ key: string; value: string }>;
}

export default function QuestionnaireForm(props: Props): ReactElement {
  const {
    isPreview = false,
    departmentOptions,
    phoneNumberTracingFlg,
    inputCompleteKeyList: propInputCompleteKeyList,
    measurementPeriod,
    questionnaire,
    communityCapitalMainQuestions,
    questions,
    wellBeingQuestion,
    healthPerceptionQuestion,
    emotionsInWeekQuestions,
    selfAffirmationQuestions,
    mindsetQuestions,
    salariedStaffOptions,
    averageActivityTimeOptions,
    degreeOfInvolvementOptions,
    activityHistoryOptions,
    sexOptions,
    occupationOptions,
    finalEducationOptions,
    annualIncomeOptions,
    maritalStatusOptions,
    answerCountOptions,
    birthYearOptions,
    prefectureOptions,
    managerialPositionOptions,
  } = props;
  const [page, setPage] = useState(1);
  const [inputCompleteKeyList, setInputCompleteKeyList] = useState<string[]>(
    propInputCompleteKeyList
  );
  const maxPage = measurementPeriod.mindset_question_flg ? 5 : 4;

  const addInputCompleteKey = (key: string): void => {
    if (!inputCompleteKeyList.includes(key)) {
      setInputCompleteKeyList(inputCompleteKeyList.concat([key]));
    }
  };

  const removeInputCompleteKey = (key: string): void => {
    setInputCompleteKeyList(inputCompleteKeyList.filter((k) => k !== key));
  };

  const formArray: Array<{
    key: string;
    page: number;
    title?: string | string[];
    required?: boolean;
    node: ReactNode;
  }> = [];
  formArray.push({
    key: "ManagerialPosition",
    page: 1,
    title: "この団体におけるポジション",
    node: (
      <QuestionnairePropertySelectForm
        showForm={page === 1}
        questionnaire={questionnaire}
        options={managerialPositionOptions}
        columnName="managerial_position_id"
      />
    ),
  });
  formArray.push({
    key: "SalariedStaff",
    page: 1,
    title: "あなたはこの団体の有給職員ですか？",
    node: (
      <QuestionnairePropertySelectForm
        showForm={page === 1}
        questionnaire={questionnaire}
        options={salariedStaffOptions}
        columnName="salaried_staff"
      />
    ),
  });
  formArray.push({
    key: "AverageActivityTime",
    page: 1,
    title: "この団体での1ヶ月の平均活動時間はどれくらいですか？",
    node: (
      <QuestionnairePropertySelectForm
        showForm={page === 1}
        questionnaire={questionnaire}
        options={averageActivityTimeOptions}
        columnName="average_activity_time"
      />
    ),
  });
  formArray.push({
    key: "DegreeOfInvolvement",
    page: 1,
    title:
      "この団体のコミットメント（かかわりの度合い）あなたは、どの程度熱心にこの団体に関わっていますか？",
    node: (
      <QuestionnairePropertySelectForm
        showForm={page === 1}
        questionnaire={questionnaire}
        options={degreeOfInvolvementOptions}
        columnName="degree_of_involvement"
      />
    ),
  });
  formArray.push({
    key: "ActivityHistory",
    page: 1,
    title: "この団体での活動歴はどれくらいですか？",
    node: (
      <QuestionnairePropertySelectForm
        showForm={page === 1}
        questionnaire={questionnaire}
        options={activityHistoryOptions}
        columnName="activity_history"
      />
    ),
  });
  if (departmentOptions.length > 0) {
    formArray.push({
      key: "Department",
      page: 1,
      title: "あなたの所属部署を教えてください",
      node: (
        <QuestionnairePropertySelectForm
          showForm={page === 1}
          questionnaire={questionnaire}
          options={departmentOptions}
          columnName="department_id"
        />
      ),
    });
  }
  formArray.push({
    key: "CommunityCapital",
    page: 2,
    required: true,
    title: [
      "上記の団体に対するあなたの考えや気持ちについてお尋ねします。",
      "各項目について最も当てはまると思うものを一つ選び、該当する欄をチェックして下さい。※必須",
    ],
    node: (
      <CommunityCapitalForm
        showForm={page === 2}
        mainQuestions={communityCapitalMainQuestions}
        questionnaire={questionnaire}
        onInputComplete={() => addInputCompleteKey("CommunityCapital")}
      />
    ),
  });
  formArray.push({
    key: "EmotionsInWeek",
    page: 3,
    title: [
      "この1週間の間に次のように感じたことがどのくらいありましたか？",
      "それぞれについてあてはまる選択肢を一つだけ選んでください。",
    ],
    node: (
      <>
        {emotionsInWeekQuestions.map((q, i) => (
          <FrequencyInWeekQuestionItem
            key={q.id}
            communityCapitalQuestion={q}
            // community_capital_answersの回答パラメータがかぶらないように
            // 100から始まるindexにしている
            questionIndex={100 + i}
            questionnaire={questionnaire}
            showForm={page === 3}
          />
        ))}
      </>
    ),
  });
  formArray.push({
    key: "SelfAffirmation",
    page: 3,
    title: [
      "あなたの考えや気持ちについてお聞きします。",
      "最もあてはまるものを一つ選んでください。",
    ],
    node: (
      <>
        {selfAffirmationQuestions.map((q, i) => (
          <SixPointScaleQuestionItem
            key={q.id}
            questionnaire={questionnaire}
            communityCapitalQuestion={q}
            // community_capital_answersの回答パラメータがかぶらないように
            // 200から始まるindexにしている
            questionIndex={200 + i}
            showForm={page === 3}
          />
        ))}
      </>
    ),
  });
  formArray.push({
    key: "HealthCondition",
    page: 3,
    title: healthPerceptionQuestion.body,
    node: (
      <HealthConditionSelectForm
        questionnaire={questionnaire}
        communityCapitalQuestion={healthPerceptionQuestion}
        // community_capital_answersの回答パラメータがかぶらないように
        // 400から始まるindexにしている
        questionIndex={400}
        showForm={page === 3}
      />
    ),
  });

  formArray.push({
    key: "WellBeing",
    page: 3,
    title: wellBeingQuestion.body,
    node: (
      <WellBeingSelectForm
        questionnaire={questionnaire}
        communityCapitalQuestion={wellBeingQuestion}
        // community_capital_answersの回答パラメータがかぶらないように
        // 500から始まるindexにしている
        questionIndex={500}
        showForm={page === 3}
      />
    ),
  });

  if (measurementPeriod.mindset_question_flg) {
    formArray.push({
      key: "Mindset",
      page: 4,
      title: [
        "あなたの考えや気持ちについてお聞きします。",
        "最もあてはまるものを一つ選んでください。",
      ],
      node: (
        <>
          {mindsetQuestions.map((q, i) => (
            <SixPointScaleQuestionItem
              key={q.id}
              questionnaire={questionnaire}
              communityCapitalQuestion={q}
              // community_capital_answersの回答パラメータがかぶらないように
              // 300から始まるindexにしている
              questionIndex={300 + i}
              showForm={page === 4}
            />
          ))}
        </>
      ),
    });
  }

  formArray.push({
    key: "Sex",
    page: maxPage,
    title: "性別",
    node: (
      <QuestionnairePropertySelectForm
        showForm={page === maxPage}
        questionnaire={questionnaire}
        options={sexOptions}
        columnName="sex"
      />
    ),
  });
  formArray.push({
    key: "BirthYear",
    page: maxPage,
    title: "あなたの生まれた年をお知らせください。",
    node: (
      <QuestionnairePropertySelectForm
        showForm={page === maxPage}
        questionnaire={questionnaire}
        options={birthYearOptions}
        columnName="birth_year"
      />
    ),
  });
  formArray.push({
    key: "Occupation",
    page: maxPage,
    title: "職業",
    node: (
      <QuestionnairePropertySelectForm
        showForm={page === maxPage}
        questionnaire={questionnaire}
        options={occupationOptions}
        columnName="occupation"
      />
    ),
  });
  formArray.push({
    key: "Prefecture",
    page: maxPage,
    title: "お住まいの都道府県名",
    node: (
      <QuestionnairePropertySelectForm
        showForm={page === maxPage}
        questionnaire={questionnaire}
        options={prefectureOptions}
        columnName="prefecture_code"
      />
    ),
  });

  formArray.push({
    key: "FinalEducation",
    page: maxPage,
    title:
      "あなたが最後に卒業した学校、または現在在学中の学校をお答えください。",
    node: (
      <QuestionnairePropertySelectForm
        showForm={page === maxPage}
        questionnaire={questionnaire}
        options={finalEducationOptions}
        columnName="final_education"
      />
    ),
  });
  formArray.push({
    key: "AnnualIncome",
    page: maxPage,
    title:
      "昨年のあなた自身の年間収入（税・社会保険料込み）は、およそいくらでしたか。 （学生の方は、アルバイト代、仕送り、奨学金等の総額をお答えください）",
    node: (
      <QuestionnairePropertySelectForm
        showForm={page === maxPage}
        questionnaire={questionnaire}
        options={annualIncomeOptions}
        columnName="annual_income"
      />
    ),
  });
  formArray.push({
    key: "MaritalStatus",
    page: maxPage,
    title: "あなたの婚姻状況をお知らせください。",
    node: (
      <QuestionnairePropertySelectForm
        showForm={page === maxPage}
        questionnaire={questionnaire}
        options={maritalStatusOptions}
        columnName="marital_status"
      />
    ),
  });
  formArray.push({
    key: "AnswerCount",
    page: maxPage,
    title:
      "このアンケート（コミュニティキャピタル診断）に回答するのは初めてですか？",
    node: (
      <QuestionnairePropertySelectForm
        showForm={page === maxPage}
        questionnaire={questionnaire}
        options={answerCountOptions}
        columnName="answer_count"
      />
    ),
  });
  if (phoneNumberTracingFlg) {
    formArray.push({
      key: "CellPhoneNumber",
      page: maxPage,
      title: "携帯電話番号下4桁を教えてください。",
      node: (
        <CellPhoneNumberTextForm
          showForm={page === maxPage}
          questionnaire={questionnaire}
        />
      ),
    });
  }
  if (page === maxPage && questions.length > 0) {
    formArray.push({
      key: "AdditionalQuestionescription",
      page: maxPage,
      node: (
        <div className="flex justify-center mt-4 border-t">
          <div className="text-sm">
            <p className="p-4 text-red-600 font-bold w-full">
              これより先は団体個別の質問です。
              <br />
              回答は団体の担当者が参照することができます。
            </p>
          </div>
        </div>
      ),
    });
  }
  for (let i = 0; i < questions.length; i++) {
    const question = questions[i];
    if (
      ["title_format", "description_format"].includes(question.answer_format)
    ) {
      formArray.push({
        key: `AdditionalQuestion${question.id}`,
        page: maxPage,
        node: (
          <AdditionalQuestionForm
            showForm={page === maxPage}
            questionnaire={questionnaire}
            question={question}
            index={i}
          />
        ),
      });
    } else {
      formArray.push({
        key: `AdditionalQuestion${question.id}`,
        page: maxPage,
        required: question.required_flg,
        title: `${question.body}${question.required_flg ? " ※必須" : ""}`,
        node: (
          <AdditionalQuestionForm
            showForm={page === maxPage}
            questionnaire={questionnaire}
            question={question}
            index={i}
            onInputComplete={() => {
              addInputCompleteKey(`AdditionalQuestion${question.id}`);
            }}
            onInputDelete={() => {
              removeInputCompleteKey(`AdditionalQuestion${question.id}`);
            }}
          />
        ),
      });
    }
  }

  let buttonDisabled = false;
  const requiredKeys = formArray
    .filter((f) => f.page === page && f.required)
    .map((f) => f.key);
  if (
    !isPreview &&
    requiredKeys.length > 0 &&
    !requiredKeys.every((k) => inputCompleteKeyList.includes(k))
  ) {
    buttonDisabled = true;
  }

  return (
    <>
      {inputCompleteKeyList.map((k, i) => (
        <HiddenField
          key={k}
          id={`input_complete_key_list_${i}`}
          name="input_complete_key_list[]"
          value={k}
        />
      ))}
      {page === 1 && (
        <div className="flex justify-center mt-4">
          <div className="max-w-2xl text-sm bg-quinary">
            <p className="p-4">
              このアンケートは、コミュニティ・組織に対するメンバーの意識を様々な角度から調査することによってその団体・組織の状態を数値化し、より良い組織づくりに役立てることを目的としています。
            </p>
            <p className="p-4">
              無記名で実施し、結果は統計的に処理されますので、個人のプライバシーがもれることは決してありません。
            </p>
            <p className="p-4">
              【注意事項】
              <br />
              ・深く考え込まず、“直感で”お答えください。
              <br />
              ・同じような質問が出てきますが、気にせず全てにお答えください。
              <br />
              ・答えにくい質問については、飛ばして構いません。（Q
              {formArray.findIndex((f) => f.required) + 1}を除く）
            </p>
          </div>
        </div>
      )}
      {page > 1 && (
        <div className="text-center bg-quinary p-3 -mx-3">
          <span className="font-bold text-xl">{`${page} / ${maxPage}`}</span>
          <div className="mt-3">
            <Button
              onClick={() => setPage(page - 1)}
              title="戻る"
              className="opacity-75 text-sm"
            />
          </div>
        </div>
      )}

      {maxPage === page && (
        <div className="flex justify-center mt-4">
          <div className="text-sm">
            <p className="p-4 w-full text-center">
              以降の質問は、研究目的のための質問です。
              <br />
              答えにくい質問については飛ばしていただいて構いません。
              <br />
              <span className="text-red-600 font-bold">
                あなたの回答が団体の担当者や他のメンバーに知られることはありません。
              </span>
            </p>
          </div>
        </div>
      )}
      {formArray.map((f, i) => {
        return (
          <React.Fragment key={f.key}>
            {f.page === page && f.title && (
              <div className="mt-6">
                <QuestionnaireLabel
                  num={
                    formArray
                      .filter((temp) => temp.title)
                      .findIndex((temp) => temp.key === f.key) + 1
                  }
                  title={f.title}
                />
              </div>
            )}
            <div className={f.page === page ? "mt-4" : ""}>{f.node}</div>
          </React.Fragment>
        );
      })}
      <div className="mt-16">
        {buttonDisabled && (
          <div className="text-center text-sm text-red-600 mb-8">
            {requiredKeys
              .filter((k) => !inputCompleteKeyList.includes(k))
              .map((k) => {
                if (k === "CommunityCapital") {
                  return (
                    <div key={k}>{`Q${
                      formArray
                        .filter((f) => f.title)
                        .findIndex((f) => f.key === k) + 1
                    }に未回答の質問があります`}</div>
                  );
                }
                return (
                  <div key={k}>{`Q${
                    formArray
                      .filter((f) => f.title)
                      .findIndex((f) => f.key === k) + 1
                  }が未回答です`}</div>
                );
              })}
          </div>
        )}
        {page === maxPage && (
          <div className="max-w-xs mx-auto">
            <Button
              className={
                isPreview || buttonDisabled
                  ? "bg-primary rounded-full p-3 text-white opacity-50 w-full"
                  : "shine-btn cursor-pointer w-full"
              }
              disabled={isPreview || buttonDisabled}
              onClick={() => {
                const submit = document.getElementById("questionnaireSubmit");
                if (submit) {
                  submit.click();
                }
              }}
            >
              <span>送信</span>
            </Button>
            <button
              type="submit"
              id="questionnaireSubmit"
              className="p-0 border-none"
              disabled={isPreview || buttonDisabled}
            />
            {isPreview && (
              <div className="text-center text-sm mt-2">
                プレビュー画面なので送信はできません
              </div>
            )}
          </div>
        )}
        {page !== maxPage && (
          <div className="max-w-xs mx-auto">
            <Button
              onClick={() => {
                setPage(page + 1);
                window.scrollTo(0, 0);
              }}
              disabled={buttonDisabled}
              className={
                buttonDisabled
                  ? "bg-primary rounded-full p-3 text-white opacity-50 w-full"
                  : "shine-btn cursor-pointer w-full"
              }
            >
              <span>次へ</span>
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
