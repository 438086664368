import React, { ReactElement, useState } from "react";
import axios, { AxiosResponse } from "axios";
import BaseModal from "../shared/BaseModal";
import Button from "../shared/Button";
import SubHeader from "../shared/SubHeader";
import BaseForm, { ajaxSubmit } from "../shared/BaseForm";
import { format, getMilliseconds, parseISO } from "date-fns";
import SelectForm from "../shared/forms/SelectForm";
import TextAreaForm from "../shared/forms/TextAreaForm";
import ChoiceEditForm from "./QuestionEditModal/ChoiceEditForm";
import { Props as FlushMessageProps } from "../shared/FlushMessage";
import { QuestionResponse, QuestionEditType } from "../../models/Question";
import { QuestionIndexResponse } from "../../models/QuestionApi";
import { MeasurementPeriodResponse } from "../../models/MeasurementPeriod";
import { deleteRequest } from "../../utils/ApiClient";

interface Props {
  isEditable: boolean;
  show: boolean;
  question?: QuestionIndexResponse;
  measurementPeriod: MeasurementPeriodResponse;
  onClose: () => void;
  afterSaved: (editedQuestion: QuestionIndexResponse) => void;
  afterDeleted?: () => void;
}

const newQuestion: QuestionEditType = {
  answer_format: "text_format",
  required_flg: false,
  body: "",
  position: 0,
  choices: [],
};

export default function QuestionEditModal(props: Props): ReactElement {
  const {
    isEditable,
    show,
    question: propQuestion,
    measurementPeriod,
    onClose,
    afterSaved,
    afterDeleted,
  } = props;
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState<QuestionEditType>(
    propQuestion || newQuestion
  );
  const [flushMessage, setFlushMessage] = useState<FlushMessageProps>();

  const isEdit = !!question.id;

  const save = async (): Promise<void> => {
    const form = document.forms["QuestionEditForm"];
    if (form.checkValidity()) {
      setLoading(true);
      const { result, error } = await ajaxSubmit<QuestionIndexResponse>(form);
      if (result && result.status === 200) {
        const editedQuestion = result.data;
        setLoading(false);
        afterSaved(editedQuestion);
      } else {
        setLoading(false);
        if (error) {
          setFlushMessage({
            title: "エラーが発生しました",
            messages: error.data.messages,
            milliseconds: getMilliseconds(new Date()),
          });
        }
      }
    } else {
      const submit = document.getElementById("questionEditFormSubmit");
      if (submit) {
        submit.click();
      }
    }
  };

  const destroy = async (): Promise<void> => {
    if (!question.id) {
      return;
    }
    if (window.confirm("この質問を削除してよろしいですか？")) {
      try {
        deleteRequest(
          `/user/measurement_periods/${measurementPeriod.id}/questions/${question.id}`
        );
        afterDeleted && afterDeleted();
      } catch (e) {
        const error = e.response;
        setFlushMessage({
          title: "エラーが発生しました",
          messages: error.data.messages,
          milliseconds: getMilliseconds(new Date()),
        });
      }
    }
  };

  return (
    <>
      <BaseModal
        show={show}
        contentLabel="QuestionEdit"
        title={`追加質問${isEdit ? "編集" : "作成"}`}
        loading={loading}
        onCloseModal={onClose}
      >
        <BaseForm
          action={
            question.id
              ? `/user/measurement_periods/${measurementPeriod.id}/questions/${question.id}`
              : `/user/measurement_periods/${measurementPeriod.id}/questions`
          }
          name="QuestionEditForm"
          method={isEdit ? "put" : "post"}
        >
          <BaseModal.Body flushMessage={flushMessage}>
            <div>
              {["text_format", "select_format", "checkbox_format"].includes(
                question.answer_format
              ) && (
                <div>
                  <TextAreaForm
                    model={question}
                    scope="question"
                    columnName="body"
                    rows={3}
                    label="質問事項"
                    required
                  />
                </div>
              )}
            </div>
            <div className="mb-10">
              <SelectForm
                model={question}
                scope="question"
                columnName="answer_format"
                options={[
                  { key: "text_format", value: "テキストボックス" },
                  { key: "select_format", value: "セレクトボックス" },
                  { key: "checkbox_format", value: "チェックボックス" },
                ]}
                label="回答形式"
                onChange={(e) => {
                  setQuestion(
                    Object.assign({}, question, {
                      answer_format: e.target.value,
                    })
                  );
                }}
              />
              {["text_format", "select_format", "checkbox_format"].includes(
                question.answer_format
              ) && (
                <SelectForm
                  model={question}
                  scope="question"
                  columnName="required_flg"
                  options={[
                    { key: "false", value: "任意" },
                    { key: "true", value: "必須" },
                  ]}
                  label="必須/任意"
                />
              )}
              <div>
                {["select_format", "checkbox_format"].includes(
                  question.answer_format
                ) && (
                  <div>
                    <ChoiceEditForm
                      question={question}
                      label="選択肢"
                      required
                    />
                  </div>
                )}
                {question.answer_format === "title_format" && (
                  <div>
                    <TextAreaForm
                      model={question}
                      scope="question"
                      columnName="body"
                      rows={1}
                      label="タイトル"
                      required
                    />
                  </div>
                )}
                {question.answer_format === "description_format" && (
                  <div>
                    <TextAreaForm
                      model={question}
                      scope="question"
                      columnName="body"
                      rows={3}
                      label="説明"
                      required
                    />
                  </div>
                )}
              </div>
            </div>
            <button
              type="submit"
              id="questionEditFormSubmit"
              className="p-0 border-none"
            />
          </BaseModal.Body>
          {isEditable && (
            <>
              {isEdit && (
                <BaseModal.Footer className="flex justify-between">
                  <Button
                    title="削除"
                    className="opacity-50 px-3 py-1 text-sm"
                    onClick={destroy}
                  />
                  <Button
                    title="保存"
                    className="primary-button px-8 py-1"
                    onClick={save}
                  />
                </BaseModal.Footer>
              )}
              {!isEdit && (
                <BaseModal.Footer className="flex justify-end">
                  <Button
                    title="保存"
                    className="primary-button px-8 py-1"
                    onClick={save}
                  />
                </BaseModal.Footer>
              )}
            </>
          )}
          {!isEditable && (
            <BaseModal.Footer className="flex justify-end">
              <Button
                title="閉じる"
                className="sub-button px-8 py-1"
                onClick={onClose}
              />
            </BaseModal.Footer>
          )}
        </BaseForm>
      </BaseModal>
    </>
  );
}
