import React, { ReactElement, useState } from "react";
import BaseModal from "../shared/BaseModal";
import Button from "../shared/Button";
import BaseForm, { ajaxSubmit } from "../shared/BaseForm";
import TextForm from "../shared/forms/TextForm";
import { Props as FlushMessageProps } from "../shared/FlushMessage";
import { UserResponse } from "../../models/User";
import { getMilliseconds } from "date-fns";

interface Props {
  user: UserResponse;
}

export default function UserEditForm(props: Props): ReactElement {
  const { user: propUser } = props;
  const [user, setUser] = useState(propUser);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [flushMessage, setFlushMessage] = useState<FlushMessageProps>();

  const save = async (): Promise<void> => {
    const form = document.forms["UserInfoEditForm"];
    if (form.checkValidity()) {
      setLoading(true);
      const { result, error } = await ajaxSubmit<UserResponse>(form);
      if (result && result.status === 200) {
        const editedUser = result.data;
        setUser(editedUser);
        setLoading(false);
        setShowModal(false);
      } else {
        setLoading(false);
        if (error) {
          setFlushMessage({
            title: "エラーが発生しました",
            messages: error.data.messages,
            milliseconds: getMilliseconds(new Date()),
          });
        }
      }
    } else {
      const submit = document.getElementById("UserInfoEditFormSubmit");
      if (submit) {
        submit.click();
      }
    }
  };

  return (
    <>
      {showModal && (
        <BaseModal
          contentLabel="Payment"
          show={true}
          title="アカウント情報編集"
          loading={loading}
          onCloseModal={() => {
            setShowModal(false);
          }}
        >
          <BaseForm action="/user" name="UserInfoEditForm" method="put">
            <BaseModal.Body flushMessage={flushMessage}>
              <div>
                <TextForm
                  scope="user"
                  model={user}
                  columnName="name"
                  placeholder="名前"
                />
              </div>
              <button
                type="submit"
                id="UserInfoEditFormSubmit"
                className="p-0 border-none"
              />
            </BaseModal.Body>
            <BaseModal.Footer className="flex justify-end items-center">
              <div>
                <Button
                  onClick={() => {
                    setShowModal(false);
                  }}
                  title="閉じる"
                  className="sub-button px-2 py-1"
                />
              </div>
              <div>
                <Button
                  title="保存"
                  className="primary-button px-3 py-1 ml-3"
                  onClick={save}
                />
              </div>
            </BaseModal.Footer>
          </BaseForm>
        </BaseModal>
      )}
      <div>
        <div className="px-3 py-2 -mx-3 bg-secondary text-over-secondary flex justify-between">
          <div>基本情報</div>
          <Button
            onClick={() => setShowModal(true)}
            title="編集"
            className="sub-button px-4 py-1 text-sm -my-1"
          />
        </div>
        <div className="p-2">
          <div className="text-sm opacity-50">名前</div>
          {user.name || "名前未登録"}
        </div>
      </div>
    </>
  );
}
