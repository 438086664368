import React, { ReactElement, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ShareIcon from "./images/ShareIcon.png";
import BaseModal from "./BaseModal";
import Button from "./Button";

interface Props {
  url: string;
}

export default function PageShareLink(props: Props): ReactElement {
  const { url } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {showModal && (
        <BaseModal
          show={showModal}
          onCloseModal={() => setShowModal(false)}
          contentLabel="ShareLink"
          title="ページ共有"
        >
          <BaseModal.Body>
            <div>以下URLで診断結果を共有できます</div>
            <div>
              <div className="flex-grow flex items-center text-center my-5">
                <input
                  id="company-overview-url-box"
                  type="text"
                  disabled={false}
                  defaultValue={url}
                  className="w-full rounded border border-quaternary px-2 py-1"
                />
              </div>
              <div className="flex justify-center">
                <CopyToClipboard
                  text={url}
                  onCopy={() => {
                    window.alert("コピーしました");
                  }}
                >
                  <button className="primary-button py-1 px-3">
                    URLをコピー
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </BaseModal.Body>
          <BaseModal.Footer className="flex justify-end">
            <Button
              title="閉じる"
              className="sub-button px-3 py-1"
              onClick={() => setShowModal(false)}
            />
          </BaseModal.Footer>
        </BaseModal>
      )}
      <div
        role="button"
        className="flex cursor-pointer"
        onClick={() => setShowModal(true)}
      >
        <div className="flex items-center">
          <img src={ShareIcon} alt="share icon" className="h-4" />
        </div>
        <div className="text-sm ml-1">共有</div>
      </div>
    </>
  );
}
