import React, { ReactElement } from "react";
import ReportFactorScore from "./ReportFactorScore";
import ReportFactorScoreDistribution from "./ReportFactorScoreDistribution";
import { QuestionnaireSummaryResponse } from "../../models/QuestionnaireSummary";
import { MeasurementPeriodSummaryResponse } from "../../models/MeasurementPeriodSummary";
import { CategoryDistributionResponse } from "../../models/CategoryDistribution";
import ReportRespondentDistribution from "./ReportRespondentDistribution";
import ReportItemSpecificFactorScores from "./ReportItemSpecificFactorScores";
import { ManagerialPositionResponse } from "../../models/ManagerialPosition";

interface Props {
  allQuestionnaireSummary: QuestionnaireSummaryResponse;
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
  managerialPositions: ManagerialPositionResponse[];
  salariedStaffOptions: Array<{ key: string; value: string }>;
  averageActivityTimeOptions: Array<{ key: string; value: string }>;
  activityHistoryOptions: Array<{ key: string; value: string }>;
}

export default function ReportPage2(props: Props): ReactElement {
  const {
    allQuestionnaireSummary,
    measurementPeriodSummary,
    managerialPositions,
    salariedStaffOptions,
    averageActivityTimeOptions,
    activityHistoryOptions,
  } = props;

  return (
    <>
      <div>
        <ReportRespondentDistribution
          measurementPeriodSummary={measurementPeriodSummary}
          managerialPositions={managerialPositions}
          salariedStaffOptions={salariedStaffOptions}
          averageActivityTimeOptions={averageActivityTimeOptions}
          activityHistoryOptions={activityHistoryOptions}
        />
      </div>
      <div>
        <ReportItemSpecificFactorScores
          allQuestionnaireSummary={allQuestionnaireSummary}
          measurementPeriodSummary={measurementPeriodSummary}
          managerialPositions={managerialPositions}
          salariedStaffOptions={salariedStaffOptions}
          averageActivityTimeOptions={averageActivityTimeOptions}
          activityHistoryOptions={activityHistoryOptions}
        />
      </div>
    </>
  );
}
