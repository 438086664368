import React, { ReactElement } from "react";
import { QuestionnaireSummaryResponse } from "../../models/QuestionnaireSummary";
import { CategoryDistributionResponse } from "../../models/CategoryDistribution";
import { MeasurementPeriodSummaryResponse } from "../../models/MeasurementPeriodSummary";
import MindStateReportFactorTable from "./shared/MindStateReportFactorTable";
import CategoryDistributionItem from "../category_distributions/CategoryDistributionItem";

interface Props {
  allQuestionnaireSummary: QuestionnaireSummaryResponse;
  measurementPeriodSummary: MeasurementPeriodSummaryResponse;
  categoryDistribution: CategoryDistributionResponse;
}

export default function HealthStateReportFactorScore(
  props: Props
): ReactElement {
  const {
    allQuestionnaireSummary,
    measurementPeriodSummary,
    categoryDistribution,
  } = props;
  return (
    <>
      <div className="mt-2 ml-2 p-2 font-bold text-lg">●心の健康状態</div>
      <div className="flex flex-row justify-center my-3">
        <div className="w-1/2 px-3">
          <div className="p-3 border border-black">
            ・コミュニティキャピタル診断では３因子が高い人ほど主観的健康感、主観的幸福感がともに高くなる傾向が観察されています。
          </div>

          <div className="flex justify-center mt-10">
            <MindStateReportFactorTable
              data={[
                "health_perception",
                "well_being",
                "depressive_tendency",
                "loneliness",
                "self_affirmation",
              ].map(
                (
                  k
                ): {
                  factorName: string;
                  point: number | null;
                  answeredCount: number;
                  averagePoint: number;
                  deviation: number | null;
                } => ({
                  factorName: window.I18n.t(
                    `enums.community_capital_question.category.${k}`
                  ),
                  point: measurementPeriodSummary[`${k}_point`],
                  answeredCount:
                    measurementPeriodSummary[`${k}_answered_count`],
                  averagePoint: allQuestionnaireSummary[`${k}_point`],
                  deviation: measurementPeriodSummary[`${k}_deviation_value`],
                })
              )}
            />
          </div>
          <div className="flex justify-center mt-5">
            <CategoryDistributionItem
              title="主観的健康感"
              allDistribution={
                allQuestionnaireSummary.health_perception_distribution
              }
              distribution={categoryDistribution.health_perception_distribution}
            />
            <CategoryDistributionItem
              title="主観的幸福感"
              allDistribution={allQuestionnaireSummary.well_being_distribution}
              distribution={categoryDistribution.well_being_distribution}
            />
          </div>
        </div>

        <div className="w-1/2 px-5">
          <div className="flex justify-center flex-wrap">
            <CategoryDistributionItem
              title="抑うつ傾向の低さ"
              allDistribution={
                allQuestionnaireSummary.depressive_tendency_distribution
              }
              distribution={
                categoryDistribution.depressive_tendency_distribution
              }
            />
            <CategoryDistributionItem
              title="孤独感の低さ"
              allDistribution={allQuestionnaireSummary.loneliness_distribution}
              distribution={categoryDistribution.loneliness_distribution}
            />
            <CategoryDistributionItem
              title="自己肯定感"
              allDistribution={
                allQuestionnaireSummary.self_affirmation_distribution
              }
              distribution={categoryDistribution.self_affirmation_distribution}
            />
          </div>
        </div>
      </div>
    </>
  );
}
