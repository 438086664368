import React, { ReactElement } from "react";
import { QuestionIndexResponse } from "../../models/QuestionApi";
import MultiLineText from "../shared/MultiLineText";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  question: QuestionIndexResponse;
}

function viewAnswerFormat(q: QuestionIndexResponse) {
  switch (q.answer_format) {
    case "title_format":
      return "タイトル";
    case "description_format":
      return "説明";
    case "text_format":
      return "テキストボックス";
    case "select_format":
      return "セレクトボックス";
    case "checkbox_format":
      return "チェックボックス";
  }
}

export default function SortableQuestionItem(props: Props): ReactElement {
  const { question } = props;
  return (
    <div className="cursor-move shadow border border-quaternary rounded bg-white p-2 mt-3 flex justify-between items-center">
      <div>
        <div className="text-xs opacity-50 flex">
          <div>{viewAnswerFormat(question)}</div>
          {question.required_flg && <div className="ml-3">必須</div>}
        </div>
        <div className="p-1">
          <MultiLineText text={question.body} />
        </div>
      </div>
      <div className="p-2">
        <FontAwesomeIcon icon={faSort} className="opacity-50" />
      </div>
    </div>
  );
}
