import React, { ReactElement, useState } from "react";
import BaseModal from "../BaseModal";
import EmailForm from "../forms/EmailForm";
import TextForm from "../forms/TextForm";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import BaseForm, { ajaxSubmit } from "../BaseForm";
import { Props as FlushMessageProps } from "../FlushMessage";
import {
  TempEmailRegistUserResponse,
  UserResponse,
} from "../../../models/User";
import { getMilliseconds } from "date-fns";

interface Props {
  onChangeMode: (mode: "regist" | "integrate") => void;
  user: TempEmailRegistUserResponse;
  errorMessages: string[];
}

export default function IntegrateModalContent(props: Props): ReactElement {
  const { onChangeMode, user, errorMessages } = props;
  const [flushMessage, setFlushMessage] = useState<
    FlushMessageProps | undefined
  >(
    errorMessages.length > 0
      ? {
          title: "エラーが発生しました",
          messages: errorMessages,
          milliseconds: getMilliseconds(new Date()),
        }
      : undefined
  );

  return (
    <BaseForm
      action="/user/integrate_account"
      name="IntegrateAccountForm"
      method="put"
      type="normal"
    >
      <BaseModal.Body flushMessage={flushMessage}>
        <div className="bg-quinary p-3 mt-2">
          <p className="font-bold text-sm">
            今回測定していただいた結果を
            <br />
            すでに登録済のアカウントに統合できます。
          </p>
          <p className="text-xs mt-2">
            以下フォームに登録済のメールアドレスを入力してください。
            <br />
            件名「コミュニティキャピタル診断結果統合の確認」のメールが届きますので、
            <br />
            メール内のリンクをクリックして統合を完了させてください。
          </p>
          <p className="text-xs mt-2">
            統合完了後は登録済のアカウントでログインすると、
            <br />
            今回の診断結果も参照することができるようになります。
          </p>
        </div>
        <EmailForm
          model={user}
          scope="user"
          columnName="integrate_email"
          label="メールアドレス"
          placeholder="メールアドレス"
          required
        />
        <div className="mb-5" />
        <button
          type="submit"
          id="IntegrateAccountFormSubmit"
          className="p-0 border-none"
        />
      </BaseModal.Body>
      <BaseModal.Footer className="flex justify-between items-center">
        <Button
          title="< 戻る"
          className="opacity-75 px-3 py-1"
          onClick={() => onChangeMode("regist")}
        />
        <input
          type="submit"
          value="保存"
          className="primary-button ml-4 px-6 py-1 cursor-pointer"
          data-disable-with="保存中.."
          // disabled
        />
      </BaseModal.Footer>
    </BaseForm>
  );
}
