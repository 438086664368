// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import "core-js/stable";
import "regenerator-runtime/runtime";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "../css/tailswind.css";

Rails.start();
Turbolinks.start();

ActiveStorage.start();
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");

const axios = require("axios");

ReactRailsUJS.useContext(componentRequireContext);

const csrfToken = Array.prototype.slice
  .call(document.head.children)
  .filter((headerTag) => headerTag.getAttribute("name") === "csrf-token")[0]
  .getAttribute("content");
axios.defaults.headers["X-CSRF-TOKEN"] = csrfToken;
axios.defaults.timeout = 30000;
