export function errorMessages(
  model: { errors?: Array<{ [key: string]: string }> },
  columnName: string,
  viewLabelName: string
): string[] {
  if (model.errors == undefined) {
    return [];
  }
  const errors = model.errors[columnName];
  if (!errors) {
    return [];
  }
  return errors.map((m) => viewLabelName + m);
}

export default errorMessages;
