import React, { ReactElement } from "react";
import PrescriptionSample from "../shared/images/PrescriptionSample.jpeg";
import SubHeader from "../shared/SubHeader";

export default function Prescription(): ReactElement {
  return <div />;
  // return (
  //   <>
  //     <SubHeader title="コミュニティキャピタル向上のためのアドバイス" />
  //     <div className="mt-3 flex justify-around flex-wrap">
  //       <div className="w-64 h-40 relative cursor-pointer rounded inline-block my-2">
  //         <img
  //           className="object-cover w-full h-full rounded"
  //           src={PrescriptionSample}
  //           alt="prescription image"
  //         />
  //         <div className="absolute inset-0 bg-black opacity-50 rounded" />
  //         <div className="absolute bottom-0 inset-x-0 text-white">
  //           <div className="text-center text-white mb-4">
  //             第１因子
  //             <br />
  //             理念共感と貢献意欲を
  //             <br />
  //             向上させる方法
  //           </div>
  //         </div>
  //       </div>
  //       <div className="w-64 h-40 relative cursor-pointer rounded inline-block my-2">
  //         <img
  //           className="object-cover w-full h-full rounded"
  //           src={PrescriptionSample}
  //           alt="prescription image"
  //         />
  //         <div className="absolute inset-0 bg-black opacity-50 rounded" />
  //         <div className="absolute bottom-0 inset-x-0 text-white">
  //           <div className="text-center text-white mb-4">
  //             第２因子
  //             <br />
  //             自己有用感を
  //             <br />
  //             向上させる方法
  //           </div>
  //         </div>
  //       </div>
  //       <div className="w-64 h-40 relative cursor-pointer rounded inline-block my-2">
  //         <img
  //           className="object-cover w-full h-full rounded"
  //           src={PrescriptionSample}
  //           alt="prescription image"
  //         />
  //         <div className="absolute inset-0 bg-black opacity-50 rounded" />
  //         <div className="absolute bottom-0 inset-x-0 text-white">
  //           <div className="text-center text-white mb-4">
  //             第３因子
  //             <br />
  //             居心地の良さを
  //             <br />
  //             向上させる方法
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
}
