import React, { ReactElement, useState } from "react";
import SelectForm from "../../shared/forms/SelectForm";
import HiddenForm from "../../shared/forms/HiddenForm";
import { QuestionnaireEditType } from "../../../models/Questionnaire";

interface Props {
  questionnaire: QuestionnaireEditType;
  options: Array<{ key: string; value: string }>;
  showForm: boolean;
  columnName: string;
}

export default function QuestionnairePropertySelectForm(
  props: Props
): ReactElement {
  const { showForm, questionnaire, options, columnName } = props;
  const [value, setValue] = useState(questionnaire[columnName] || "");
  return (
    <>
      {!showForm && value && (
        <HiddenForm
          scope="questionnaire"
          columnName={columnName}
          value={value}
        />
      )}
      {showForm && (
        <SelectForm
          model={questionnaire}
          scope="questionnaire"
          columnName={columnName}
          option={{ include_blank: "-- 選択してください --" }}
          options={options}
          hideLabel
          defaultKey=""
          selectedKey={value}
          onChange={(e) => setValue(e.target.value)}
        />
      )}
    </>
  );
}
