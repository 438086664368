import React, { ReactElement, useState, useEffect } from "react";
import Button from "./Button";

export interface Props {
  messageType?: "success" | "error" | "info";
  title: string;
  messages?: string[];
  milliseconds: number;
}

// export function showMessage(data: FlushMessageProps): void {}

const successContainerClass = "bg-teal-100 border-teal-500 text-teal-900";
const successIconClass = "text-teal-500";
const errorContainerClass = "bg-red-100 border-red-500 text-red-900";
const errorIconClass = "text-red-500";
const infoContainerClass = "bg-blue-100 border-blue-500 text-blue-900";
const infoIconClass = "text-blue-500";

export default function FlushMessage(props: Props): ReactElement | null {
  const { messageType, title, messages, milliseconds } = props;
  const [showFlush, setShowFlush] = useState(false);

  useEffect(() => {
    setShowFlush(true);
  }, [milliseconds]);

  if (!showFlush) {
    return null;
  }

  let containerClass = "";
  let iconClass = "";
  switch (messageType) {
    case "success":
      containerClass = successContainerClass;
      iconClass = successIconClass;
      break;
    case "error":
      containerClass = errorContainerClass;
      iconClass = errorIconClass;
      break;
    case "info":
      containerClass = infoContainerClass;
      iconClass = infoIconClass;
      break;
    default:
      containerClass = errorContainerClass;
      iconClass = errorIconClass;
  }

  return (
    <div
      className={`${containerClass} w-full border-t-4 rounded-b  px-4 py-3 mb-4 shadow-md z-10`}
      role="alert"
    >
      <div className="flex justify-between">
        <div className="flex">
          <div className="py-1">
            <svg
              className={`${iconClass} fill-current h-6 w-6 mr-4`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
          </div>
          <div>
            <p className="font-bold">{title}</p>
            {messages &&
              messages.map((m, i) => (
                <p key={i} className="text-sm">
                  {m}
                </p>
              ))}
          </div>
        </div>
        <div>
          <Button
            className="alert-box-close-btn cursor-pointer p-2"
            onClick={() => setShowFlush(false)}
          >
            <span aria-hidden="true">&times;</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
